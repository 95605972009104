/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-news {
  position: relative;
}
.widget-news__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: -24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.widget-news__item {
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 24px;
  padding-left: 24px;
}
.widget-news__item__link {
  text-decoration: none;
  display: block;
  height: 100%;
  background-color: #fff;
  border: 1px solid var(--color-blue-light);
  transition: background-color 300ms;
}
.widget-news__item__link:hover {
  background-color: var(--color-blue-lighter);
}
.widget-news__item img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.widget-news__item__title {
  font-size: 18px;
  font-weight: 600;
  padding: 24px;
  max-height: 96px;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.widget-news__moreresults {
  margin-top: 32px;
  text-align: right;
}
@media (max-width: 700px) {
  .widget-news__item {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}