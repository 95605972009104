/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-numberedblocks__tiocircle {
  position: absolute;
  left: 0;
  width: 437px;
  height: auto;
  z-index: -1;
}
.widget-numberedblocks ol {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  counter-reset: li;
  justify-content: space-between;
}
.widget-numberedblocks ol > li {
  flex: none;
  width: calc(50% - 24px);
  border-bottom: 1px solid var(--color-blue-dark);
  display: flex;
  padding: var(--spacing-xs);
  margin-top: 17px;
}
@media (max-width: 1000px) {
  .widget-numberedblocks ol > li {
    width: 47.5%;
  }
}
.widget-numberedblocks ol > li::before {
  content: counter(li);
  counter-increment: li;
  color: #fff;
  height: clamp(28px, 4dvw, 40px);
  min-width: clamp(28px, 4dvw, 40px);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blue);
  font-weight: 700;
  line-height: 150%;
  font-size: clamp(16px, 1.8dvw, 18px);
  padding: 5px;
  text-align: center;
  position: relative;
  transform: translateY(-15%);
  margin-right: var(--spacing-xs);
}
@media (max-width: 700px) {
  .widget-numberedblocks ol {
    display: block;
  }
  .widget-numberedblocks ol > li {
    width: 100%;
  }
}