/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.groupedslides {
  outline: none;
  position: relative;
  cursor: default;
  -webkit-user-select: none; /* Safari */
  user-select: none;
}
.groupedslides__itemlist {
  display: flex;
  position: relative;
}
.groupedslides--loaded .groupedslides__itemlist, .groupedslides--loaded .groupedslides__item {
  transition: transform 0.5s ease-out;
}
.groupedslides__item {
  flex: none;
  width: 50%;
  position: relative;
  transform: translateX(-50vw);
  padding-right: 32px;
}
.groupedslides__item.active {
  transform: translateX(0);
}
.groupedslides__item.active ~ .active {
  padding-left: 32px;
  padding-right: 0;
}
.groupedslides__item.active ~ .groupedslides__item:not(.active) {
  transform: translateX(50vw);
}
.groupedslides__previous, .groupedslides__next {
  position: absolute;
  font-size: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  transition: all 0.3s;
  opacity: 1;
  outline: none;
  padding: 0;
  border: 0 none;
  background-color: transparent;
}
.groupedslides__previous {
  left: -32px;
}
.groupedslides__previous:hover {
  left: -34px;
}
.groupedslides__next {
  right: -32px;
}
.groupedslides__next:hover {
  right: -34px;
}
.groupedslides.busy .groupedslides__previous, .groupedslides.busy .groupedslides__next, .groupedslides__previous.disabled, .groupedslides__next.disabled {
  opacity: 0;
  cursor: default;
}
.groupedslides__jumpbuttons {
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: none;
}
.groupedslides__jumpbuttons button {
  border: 2px solid #fff;
  background-color: transparent;
  display: inline-block;
  width: 10px;
  height: 10px;
  padding: 0;
  transition: background-color 300ms;
  cursor: pointer;
  border-radius: 50%;
  margin: 0 5px;
}
.groupedslides__jumpbuttons button.active, .groupedslides__jumpbuttons button:hover, .groupedslides__jumpbuttons button:focus {
  background-color: #fff;
}
.groupedslides--singleslide .groupedslides__previous, .groupedslides--singleslide .groupedslides__next, .groupedslides--singleslide .groupedslides__jumpbuttons {
  display: none;
}
@media (max-width: 950px) {
  .groupedslides__item {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .groupedslides__previous, .groupedslides__next {
    display: none;
  }
  .groupedslides__jumpbuttons {
    display: flex;
  }
}