/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.header-pagenav--fixed + main {
  margin-top: 105px;
}
@media (max-width: 1300px) {
  .header-pagenav--fixed + main {
    margin-top: calc(5vw + 40px);
  }
}
@media (max-width: 640px) {
  .header-pagenav--fixed + main {
    margin-top: 72px;
  }
}

.header-pagenav {
  position: relative;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: var(--color-blue-lighter);
  padding: 0 clamp(24px, 5dvw, 42px);
  font-size: 16px;
}
.header-pagenav--scrollable {
  padding: 0;
}
.header-pagenav--scrollable .header-pagenav__inner {
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.header-pagenav--scrollable .header-pagenav__inner.dragging {
  scroll-behavior: auto;
  cursor: grab;
}
.header-pagenav--scrollable .header-pagenav__inner .pagenav {
  padding: 0 clamp(24px, 5dvw, 42px);
  display: inline-flex;
  width: auto;
  flex: none;
  min-width: 100%;
}
.header-pagenav--scrollable .header-pagenav__inner .pagenav .button, .header-pagenav--scrollable .header-pagenav__inner .pagenav button {
  white-space: nowrap;
}
.header-pagenav--scrollable .header-pagenav__inner::-webkit-scrollbar {
  display: none;
}
.header-pagenav__scrollwrapper .header-pagenav__inner {
  display: inline-flex;
}
.header-pagenav--fixed {
  position: fixed;
  top: 80px;
  transition: top 300ms;
}
@media (max-width: 1024px) {
  .header-pagenav--fixed {
    top: 70px;
  }
}
html.scrolldown .header-pagenav--fixed {
  top: 0;
}
.header-pagenav--fixed .pagenav__buttonsright {
  opacity: 1;
  pointer-events: initial;
  transform: translateY(0);
}
.header-pagenav__inner {
  position: relative;
}
@media (min-width: 701px) {
  .header-pagenav__inner {
    padding-left: 59px;
  }
}
.header-pagenav__ctabar {
  display: flex;
  align-items: center;
}
.header-pagenav__ctabar .button + .button {
  margin-left: var(--spacing-xs);
}

.pagenav {
  position: relative;
  width: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  height: 56px;
  justify-content: flex-end;
}
.pagenav--hassubpages {
  justify-content: space-between;
}
.pagenav--hassubpages .pagenav__sitectas__closebtn {
  display: none;
}
.pagenav__buttonsright {
  display: flex;
  align-items: center;
  flex: none;
  position: relative;
  pointer-events: none;
  opacity: 0;
  transform: translateY(-70px);
  transition: transform 300ms, opacity 300ms;
  max-width: 100%;
}
.pagenav__buttonsright .button, .pagenav__buttonsright button {
  flex: none;
}
.pagenav__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
}
.pagenav__foldout {
  display: inline-flex;
}
.pagenav__item {
  margin-right: 40px;
  line-height: 42px;
  background-color: var(--color-blue-lighter);
}
@media (max-width: 1024px) {
  .pagenav__item {
    line-height: 37px;
  }
}
@media (max-width: 1176px) {
  .pagenav__item {
    margin-right: 3.3vw;
  }
}
@media (max-width: 970px) {
  .pagenav__item {
    margin-right: 32px;
  }
}
.pagenav__item__icon {
  padding-left: 7px;
}
.pagenav__link {
  color: var(--color-black);
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
  border-bottom: 3px solid transparent;
  font-weight: 600;
  transition: color 300ms, border-color 300ms;
}
.pagenav__link:hover, .pagenav__link--active, .pagenav__link:focus {
  color: var(--color-blue);
  border-color: var(--color-blue);
  outline: none;
}

.pagenav__foldout-toggle,
.pagenav__sitectas-toggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: clamp(13px, 1.5dvw, 16px);
  color: var(--color-black);
  font-weight: 600;
  flex: none;
  align-items: center;
  height: 100%;
}
.pagenav__foldout-toggle__overview,
.pagenav__sitectas-toggle__overview {
  display: inline-block;
  padding-right: 16px;
  flex: 1;
}
.pagenav__foldout-toggle__activepage,
.pagenav__sitectas-toggle__activepage {
  white-space: initial;
  display: none;
  text-align: left;
}
.pagenav__foldout-toggle > i,
.pagenav__sitectas-toggle > i {
  padding-left: 5px;
  flex: none;
}

@media (max-width: 750px) {
  .pagenav__foldout-toggle {
    max-width: calc(100% - 165px);
  }
  .pagenav__foldout-toggle > i {
    color: var(--color-blue);
  }
  .pagenav__foldout-toggle__overview {
    display: none;
  }
  .pagenav__foldout-toggle__activepage {
    display: inline-block;
  }
}
.pagenav__sitectas-toggle {
  max-width: calc(100% - 150px);
  overflow: hidden;
}

@media (max-width: 700px) {
  .pagenav__foldout-toggle, .pagenav--hassubpages .pagenav__sitectas-toggle {
    display: none;
  }
}
html.outfillmain main {
  min-height: calc(100dvh - 126px);
}

html.outfillmain.scrolldown main {
  height: calc(100dvh - 56px);
}

.header-pagenav > .pagenav__sitectas {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  max-height: 0;
  opacity: 0;
  transition: max-height 300ms, opacity 200ms;
  overflow: hidden;
  height: calc(100dvh - 126px);
}
.header-pagenav > .pagenav__sitectas > .centercontent-wide {
  padding-top: clamp(4px, 2.5dvw, 25px);
}
html.scrolldown .header-pagenav > .pagenav__sitectas {
  height: calc(100dvh - 56px);
}

.pagenav__foldout > .pagenav__sitectas {
  padding-top: 16px;
  flex: 1;
}

html.showsitectas .header-pagenav > .pagenav__sitectas {
  max-height: 100dvh;
  opacity: 1;
  overflow-y: auto;
}

.pagenav__foldout-toggle {
  display: none;
}

.pagenav__foldout-toggle:has(~ .pagenav__foldout:focus-within) > i,
.pagenav__foldout-toggle:focus > i,
html.showsitectas .pagenav__sitectas-toggle > i {
  transform: scale(1, -1);
}

.header-pagenav--foldnav .pagenav__foldout-toggle {
  display: inline-flex;
}
.header-pagenav--foldnav .pagenav__foldout {
  position: absolute;
  left: clamp(8px, 1.3dvw, 13px);
  top: 4px;
  max-width: 100dvw;
  margin-left: clamp(-48px, -4.8dvw, -28px);
  overflow: hidden;
  max-height: 0;
  flex: none;
  display: flex;
  flex-flow: column;
  transition: max-height 200ms;
  overflow-y: auto;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1490196078);
  border-radius: var(--radius-sm);
}
@media (max-width: 700px) {
  .header-pagenav--foldnav .pagenav__foldout {
    width: 100dvw;
    height: calc(100dvh - 126px);
  }
  html.scrolldown .header-pagenav--foldnav .pagenav__foldout {
    height: calc(100dvh - 56px);
  }
}
.header-pagenav--foldnav .pagenav__list {
  padding-top: 52px;
  flex-flow: column;
  align-items: stretch;
}
.header-pagenav--foldnav .pagenav__foldout:focus-within,
.header-pagenav--foldnav .pagenav__foldout-toggle:focus ~ .pagenav__foldout {
  max-height: 100dvh;
}
.header-pagenav--foldnav .pagenav__item {
  margin-right: 0;
  flex: none;
  padding: 0 clamp(20px, 35px, 3.5dvw);
}
.header-pagenav--foldnav .pagenav__item:last-child {
  padding-bottom: clamp(10px, 1.5dvw, 15px);
}
.header-pagenav--foldnav .pagenav__link {
  line-height: 150%;
  white-space: initial;
}

.pagenav__sitectas {
  background-color: var(--color-blue-lighter-dark);
  color: var(--color-black);
}
.pagenav__sitectas__closebtn {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  position: absolute;
  top: clamp(15px, 4.5dvw, 45px);
  right: clamp(15px, 4.5dvw, 45px);
  width: 32px;
  height: 32px;
  font-size: 18px;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
  transition: opacity 400ms;
}
html.showsitectas .pagenav__sitectas__closebtn {
  opacity: 1;
}
.pagenav__sitectas-toggle {
  background-color: var(--color-blue-lighter-dark);
  height: 40px;
  border-radius: 8px;
  padding: 0 12px;
  margin-left: var(--spacing-xs);
}
.pagenav__sitectas__content {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -12px;
  padding-bottom: var(--spacing-sm);
}
.pagenav__sitectas__content > li {
  flex: none;
  width: 50%;
  padding: 0 0 var(--spacing-xxs) var(--spacing-xxs);
}
@media (max-width: 750px) {
  .pagenav__sitectas__content {
    display: block;
  }
  .pagenav__sitectas__content > li {
    width: 100%;
  }
}
.pagenav__sitectas__link {
  display: flex;
  height: 100%;
  padding: 10px 0;
  align-items: center;
  color: #fff;
  background-color: var(--color-blue-dark);
  padding: clamp(12px, 3.2dvw, 32px);
}
.pagenav__sitectas__link--green {
  color: #000;
  background-color: var(--color-green);
}
.pagenav__sitectas__link__text {
  flex: 1 1 auto;
  padding-right: 24px;
}
.pagenav__sitectas__link__title {
  font-weight: 900;
  font-size: clamp(14px, 2.4dvw, 24px);
  line-height: 166%;
  max-width: 320px;
  overflow: hidden;
  text-overflow: ellipsis;
  hyphens: auto;
}
.pagenav__sitectas__link__description {
  font-size: clamp(12px, 1.6dvw, 16px);
  line-height: 150%;
  max-width: 320px;
}
.pagenav__sitectas__link__icon {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(32px, 6dvw, 64px);
  height: clamp(32px, 6dvw, 64px);
  flex: none;
}
.pagenav__sitectas__link__icon svg {
  width: 100%;
  height: auto;
  fill: #fff;
}
.pagenav__sitectas__link--green .pagenav__sitectas__link__icon svg {
  fill: #000;
}
.pagenav__sitectas__link__arrow {
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(24px, 4.5dvw, 48px);
  height: clamp(24px, 4.5dvw, 48px);
  flex: none;
}
@media (max-width: 565px) {
  .pagenav__sitectas__link__arrow {
    width: 8.5vw;
    height: 8.5vw;
  }
}
@media (max-width: 375px) {
  .pagenav__sitectas__link__arrow {
    width: 32px;
    height: 32px;
  }
}
.pagenav__sitectas__link--green .pagenav__sitectas__link__arrow {
  border-color: #000;
}
@media (max-width: 400px) {
  .pagenav__sitectas__link__arrow, .pagenav__sitectas__link__icon {
    display: none;
  }
}