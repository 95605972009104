/* css styled pulldown */
.wh-form__pulldown-styled {
  position: relative;
  flex: 1;
}
.wh-form__pulldown-styled > select {
  padding-right: 40px !important;
  width: 100%;
  min-width: 140px;
  cursor: pointer;
}
.wh-form__pulldown-styled > select:disabled {
  opacity: 0.4;
}
.wh-form__pulldown-styled > select:disabled + .arrow {
  opacity: 0.4;
}
.wh-form__pulldown-styled option[disabled]:not(.wh-form__pulldownplaceholder) {
  display: none;
}
.wh-form__pulldown-styled select::-ms-expand {
  display: none; /* hide select arrow in IE */
}
.wh-form__pulldown-styled .arrow {
  font-size: 20px;
  position: absolute;
  right: 1px;
  top: 2px;
  bottom: 1px;
  width: 40px;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-blue);
}
.wh-form__pulldown-styled select[disabled] + .arrow {
  opacity: 0.2;
}

/* */
/* non mobile replacement pulldown styling for styled wh-form */
.wh-form-pulldown__items--required .wh-form__pulldownplaceholder {
  display: none; /* hide placeholder only if required */
}

.wh-form-pulldown {
  display: flex;
  flex: 1;
  cursor: pointer;
  vertical-align: middle;
  -webkit-appearance: none;
  width: 100%;
  height: 44px;
  border-radius: 3px;
  border: 1px solid var(--color-blue);
  background-color: #fff;
  color: var(--color-black);
  padding: 5px 10px;
  font-size: 18px;
  line-height: 23px;
  outline: none;
  transition: all 0.2s;
}
.wh-form-pulldown--replaced {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-form-pulldown__item[data-country] {
  display: flex;
  flex-flow: row;
  max-width: 100%;
  align-items: center;
}
.wh-form-pulldown__items .countryflag {
  width: 27px;
  height: 27px;
}
.wh-form-pulldown__items .countryname {
  display: inline-block;
  line-height: 24px;
  padding-left: 10px;
}
.wh-form-pulldown__current .countryflag {
  margin-top: 6px;
  width: 27px;
  height: 27px;
}
.wh-form-pulldown__current .countryphone {
  position: absolute;
  top: 6px;
  left: 30px;
  font-size: 16px;
}
.wh-form-pulldown__current .countryphone::before {
  content: "+";
}
.wh-form-pulldown:focus, .wh-form-pulldown:hover {
  border-width: 2px;
}
.wh-form-pulldown__control {
  position: relative;
  padding-right: 20px;
  display: flex;
  align-items: center;
}
.wh-form-pulldown__arrow {
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  color: var(--color-blue);
}
.wh-form-pulldown__arrow::after {
  display: inline-block;
  content: "\f078";
  font-family: "Font Awesome 5 Pro";
}
.wh-form-pulldown__current {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wh-form-pulldown__current[data-option-wh-placeholder] {
  color: var(--color-blue);
}
.wh-form-pulldown__area {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wh-form-pulldown__control {
  height: 100%;
  position: relative;
}
.wh-form-pulldown__items--open {
  z-index: 1000;
  position: fixed;
  cursor: default;
  overflow: auto;
  background-color: #fff;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  padding: 20px 0;
  border-radius: 4px;
  color: var(--color-black);
}
.wh-form-pulldown__item--disabled:first-child, .wh-form-pulldown__items--open .wh-form-pulldown--hideoption {
  display: none;
}
.wh-form-pulldown__item--disabled {
  cursor: default !important;
  color: #C5C5C5;
}
.wh-form-pulldown__item--disabled[data-wh-form-visible-if] {
  display: none;
}
.wh-form-pulldown__items--open .wh-form-pulldown__item {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100%;
  padding: 3px 20px;
  font-weight: normal;
  font-size: 18px;
}
.wh-form-pulldown__items--open .wh-form-pulldown__item:hover {
  background-color: #e6f5fa;
}
.wh-form-pulldown__items--open .wh-form-pulldown__item--disabled:hover {
  background-color: transparent;
}

select[disabled] + .wh-form-pulldown {
  border-color: #C5C5C5;
  cursor: default;
  color: #C5C5C5;
}
select[disabled] + .wh-form-pulldown:focus, select[disabled] + .wh-form-pulldown:hover {
  border-width: 1px;
}
select[disabled] + .wh-form-pulldown .wh-form-pulldown__arrow {
  color: #C5C5C5;
}

.wh-form__fieldgroup--error .wh-form-pulldown {
  border-color: #CC2500;
}

/* replacement pulldown styling (also for filters like in news folder)*/
.wh-pulldown,
.wh-pulldown-filter,
.wh-pulldown-filter-wide {
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  -webkit-appearance: none;
  width: 174px;
  height: 35px;
  border-radius: 18px;
  border: 1px solid #fff;
  background-color: #0088D4;
  color: #fff;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 23px;
  outline: none;
  margin-left: 40px;
  transition: all 0.3s;
}
.wh-pulldown__control,
.wh-pulldown-filter__control,
.wh-pulldown-filter-wide__control {
  position: relative;
  padding-right: 20px;
}
.wh-pulldown__arrow,
.wh-pulldown-filter__arrow,
.wh-pulldown-filter-wide__arrow {
  display: inline-block;
  position: absolute;
  top: 3px;
  right: 5px;
}
.wh-pulldown--open,
.wh-pulldown-filter--open,
.wh-pulldown-filter-wide--open {
  background-color: #fff;
  color: #0088D4;
}
.wh-pulldown__current,
.wh-pulldown-filter__current,
.wh-pulldown-filter-wide__current {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wh-pulldown--replaced,
.wh-pulldown-filter--replaced,
.wh-pulldown-filter-wide--replaced {
  display: none;
}
.wh-pulldown__area,
.wh-pulldown-filter__area,
.wh-pulldown-filter-wide__area {
  height: 100%; /* This area */
  overflow: hidden;
}
.wh-pulldown__control,
.wh-pulldown-filter__control,
.wh-pulldown-filter-wide__control {
  height: 100%;
  position: relative;
}
.wh-pulldown__items,
.wh-pulldown-filter__items,
.wh-pulldown-filter-wide__items {
  width: 174px !important;
}
.wh-pulldown__items--open,
.wh-pulldown-filter__items--open,
.wh-pulldown-filter-wide__items--open {
  z-index: 1000;
  position: fixed;
  cursor: default;
  overflow: auto;
  background-color: #fff;
  border: 1px solid #0088D4;
  padding: 20px 0;
  border-radius: 16px;
  color: #0088D4;
}
.wh-pulldown__item--disabled:first-child, .wh-pulldown__items--open .wh-pulldown--hideoption,
.wh-pulldown-filter__item--disabled:first-child,
.wh-pulldown__items--open .wh-pulldown-filter--hideoption,
.wh-pulldown-filter-wide__item--disabled:first-child,
.wh-pulldown__items--open .wh-pulldown-filter-wide--hideoption,
.wh-pulldown-filter__items--open .wh-pulldown--hideoption,
.wh-pulldown-filter__items--open .wh-pulldown-filter--hideoption,
.wh-pulldown-filter__items--open .wh-pulldown-filter-wide--hideoption,
.wh-pulldown-filter-wide__items--open .wh-pulldown--hideoption,
.wh-pulldown-filter-wide__items--open .wh-pulldown-filter--hideoption,
.wh-pulldown-filter-wide__items--open .wh-pulldown-filter-wide--hideoption {
  display: none;
}
.wh-pulldown__items--open .wh-pulldown__item,
.wh-pulldown__items--open .wh-pulldown-filter__item,
.wh-pulldown__items--open .wh-pulldown-filter-wide__item,
.wh-pulldown-filter__items--open .wh-pulldown__item,
.wh-pulldown-filter__items--open .wh-pulldown-filter__item,
.wh-pulldown-filter__items--open .wh-pulldown-filter-wide__item,
.wh-pulldown-filter-wide__items--open .wh-pulldown__item,
.wh-pulldown-filter-wide__items--open .wh-pulldown-filter__item,
.wh-pulldown-filter-wide__items--open .wh-pulldown-filter-wide__item {
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100%;
  padding: 3px 20px;
  font-weight: bold;
  font-size: 14px;
}
.wh-pulldown__items--open .wh-pulldown__item:hover,
.wh-pulldown__items--open .wh-pulldown-filter__item:hover,
.wh-pulldown__items--open .wh-pulldown-filter-wide__item:hover,
.wh-pulldown-filter__items--open .wh-pulldown__item:hover,
.wh-pulldown-filter__items--open .wh-pulldown-filter__item:hover,
.wh-pulldown-filter__items--open .wh-pulldown-filter-wide__item:hover,
.wh-pulldown-filter-wide__items--open .wh-pulldown__item:hover,
.wh-pulldown-filter-wide__items--open .wh-pulldown-filter__item:hover,
.wh-pulldown-filter-wide__items--open .wh-pulldown-filter-wide__item:hover {
  background-color: #e5f3fa;
}

.wh-pulldown-filter-wide__items {
  width: 388px !important;
}

.wh-form:not(.wh-form--allowsubmit) .tio--form-footertext {
  display: none;
}
.tio--form-footertext p.normal {
  font-size: 16px;
}