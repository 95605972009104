/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-twocols__columns {
  display: flex;
  margin-left: -24px;
}
.widget-twocols__columns__col {
  flex: 0 1 50%;
  max-width: 50%;
  padding-left: 24px;
}
.widget-twocols__columns__col > * {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget-twocols__columns__col > *:last-child {
  margin-bottom: 0;
}
@media (max-width: 600px) {
  .widget-twocols__columns {
    display: block;
    margin-left: 0;
  }
  .widget-twocols__columns__col {
    max-width: none;
    padding-left: 0;
  }
  .widget-twocols__columns__col + .widget-twocols__columns__col {
    padding-top: 24px;
  }
}