/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
:root {
  --color-black: #121321;
  --color-blue: #1345E7;
  --color-blue-disabled: #89A2F3;
  --color-blue-dark: #0F37B9;
  --color-blue-light: #2256FF;
  --color-blue-lighter-dark: #DCE3FB;
  --color-blue-lighter: #F3F6FE;
  --color-cyan: #1FE5FF;
  --color-green: #0DE972;
  --color-green-lighter-dark: #C3F5D9;
  --color-green-lighter: #E1FAEC;
  --color-orange: #FF5E1F;
  --color-grey-lines: #D1D5DB;
  /*
  Tio 2024 color palet:

  Tio blauw dark:      #0F37B9;
  Tio blauw            #1345E7
  Tio blauw light:     #2256FF
  Tio blauw tint dark: #DCE3FB
  Tio blauw grijs:     #F3F6FE

  Tio groen:           #0DE972
  Tio groen tint dark: #C3F5D9
  Tio groen tint:      #E1FAEC

  Tio oranje:          #FF5E1F

  Tio cyan:            #1FE5FF
  */
  --font-family-default: "Inter", Arial, Helvetica, sans-serif;
  --font-family-heading: raptor, "arial black", arial, helvetica, sans-serif;
  --spacing-xl: clamp(56px, 10dvw, 104px);
  --spacing-lg2:clamp(72px, 12.8dvw, 128px);
  --spacing-lg: clamp(36px, 6.4dvw, 64px);
  --spacing-md: clamp(28px, 4.8dvw, 48px);
  --spacing-sm: clamp(20px, 3.2dvw, 32px);
  --spacing-xs: 16px;
  --spacing-xxs: 12px;
  --spacing-xxxs: 8px;
  --spacing-xxxxs: 4px;
  --radius-lg: clamp(16px, 2.4dvw, 24px);
  --radius-md: clamp(8px, 1.6dvw, 16px);
  --radius-sm: 8px;
}

.br-rounded {
  border-radius: clamp(16px, 2.4dvw, 24px);
}

.br-rounded--small, .wh-video img, .wh-video iframe {
  border-radius: clamp(8px, 1.6dvw, 16px);
}

/* color classes */
.fg-blue {
  color: var(--color-blue);
}

.fg-green {
  color: var(--color-green);
}

.fg-orange {
  color: var(--color-orange);
}

.bg-blue {
  background-color: var(--color-blue);
}

.bg-blue-lighter {
  background-color: var(--color-blue-lighter);
}

.bg-green {
  background-color: var(--color-green);
}

.bg-green-lighter {
  background-color: var(--color-green-lighter);
}

.bg-orange {
  background-color: var(--color-orange);
}

.svgfill--blue svg > *, .svgfill--blue svg > g > * {
  fill: var(--color-blue) !important;
}

.svgstroke--blue svg > *, .svgfill--blue svg > g > * {
  stroke: var(--color-blue);
}

.svgfill--white svg > *, .svgfill--white svg > g > * {
  fill: #fff !important;
}

.svgstroke--white svg > *, .svgfill--white svg > g > * {
  stroke: #fff;
}

/* responsive font sizes */
.font-h1, h1 {
  font-size: clamp(40px, 5.6dvw, 56px);
}

.font-h2, h2 {
  font-size: clamp(32px, 4dvw, 40px);
}

.font-h3, h3 {
  font-size: clamp(24px, 2.8dvw, 28px);
}

.font-h4, h4 {
  font-size: clamp(16px, 1.8dvw, 18px);
}

.font-intro, p.intro {
  font-size: clamp(20px, 2.4dvw, 24px);
}

.font-widget-item {
  line-height: 140%;
  font-weight: 800;
  font-size: 28px;
  font-size: clamp(20px, 2.8dvw, 28px);
}

/* center classes */
.centercontent-wide {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1176px;
  padding-left: 32px;
  padding-right: 32px;
}

.centercontent-rtd {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1000px;
  padding-left: 32px;
  padding-right: 32px;
}

.widget--withoverlap.centercontent-wide {
  max-width: 1176px;
  padding-left: clamp(12px, 2dvw, 32px);
  padding-right: clamp(12px, 2dvw, 32px);
}

.widget--withoverlap .centercontent-rtd {
  max-width: 960px;
  padding-left: 20px;
  padding-right: 20px;
}

.styledscrollbar-x,
.styledscrollbar-y {
  overflow: hidden;
  scroll-behavior: smooth;
  /* firefox */
  scrollbar-width: thin;
  scrollbar-color: var(--color-blue) var(--color-blue-lighter);
  /* webkit */
  /*
  &::-webkit-scrollbar-track //the track (progress bar) of the scrollbar
  {
   //Disable else we have grey shadow on white background (Safari: even when no scrolling is needed): box-shadow: inset 0 0 7px 7px var(--color-blue-lighter);
  }
  */
}
.styledscrollbar-x::-webkit-scrollbar,
.styledscrollbar-y::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}
.styledscrollbar-x::-webkit-scrollbar-track-piece,
.styledscrollbar-y::-webkit-scrollbar-track-piece {
  background-color: transparent;
}
.styledscrollbar-x::-webkit-scrollbar-button, .styledscrollbar-x::-webkit-scrollbar-corner, .styledscrollbar-x::-webkit-resizer,
.styledscrollbar-y::-webkit-scrollbar-button,
.styledscrollbar-y::-webkit-scrollbar-corner,
.styledscrollbar-y::-webkit-resizer {
  display: none;
}
.styledscrollbar-x::-webkit-scrollbar-thumb,
.styledscrollbar-y::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 7px 7px var(--color-blue);
}

.styledscrollbar-x {
  overflow-x: auto;
}

.styledscrollbar-y {
  overflow-y: auto;
}

/* prevent occacional double click from selecting text */
.preventselection {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
/*
  styling for contentlists with small tiles 3 in a row
*/
.tilescontent {
  position: relative;
}
.tilescontent__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
}
.tilescontent__item {
  flex: 1 1 33.3333%;
  max-width: 33.3333%;
  padding-left: 24px;
  margin-bottom: 24px;
}
.tilescontent__item[hidden] {
  display: none;
}
.tilescontent__item__link {
  text-decoration: none;
  height: 100%;
  display: block;
  border: 1px solid var(--color-blue-light);
  background-color: #fff;
  transition: background-color 300ms;
}
.tilescontent__item__link:hover {
  background-color: var(--color-blue-lighter);
}
.tilescontent__item__header {
  position: relative;
  overflow: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.tilescontent__item__playbutton {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65px;
  height: 65px;
  background-color: var(--color-blue);
  border-radius: 50%;
}
.tilescontent__item__playbutton::after {
  position: absolute;
  top: 21px;
  left: 25px;
  content: "";
  display: block;
  border-top: 12px solid transparent;
  border-left: 16px solid #fff;
  border-bottom: 12px solid transparent;
}
.tilescontent__item__image {
  position: relative;
}
.tilescontent__item__image::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.tilescontent__item__image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tilescontent__item__text {
  padding: 24px;
}
.tilescontent__item__title {
  font-size: 18px;
  font-weight: 600;
  /*
  max-height: 96px;
  line-clamp: 2;
  display: -webkit-box; // ONLY with -webkit-box
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  */
}
.tilescontent__item__title--withtag > span {
  display: inline-block;
  margin-right: 10px;
}
.tilescontent__item__tag {
  vertical-align: middle;
  display: inline-block;
  color: inherit;
  font-size: 16px;
  line-height: 20px;
  height: 32px;
  border-radius: 16px;
  text-transform: uppercase;
  border: 2px solid var(--color-blue);
  padding: 4px 15px;
  margin: 5px 0;
}
.tilescontent__more[hidden], .tilescontent__noresults[hidden] {
  display: none;
}
.tilescontent--more .tilescontent__more, .tilescontent--noresults .tilescontent__noresults {
  display: block;
}
.tilescontent__more {
  text-align: center;
}
@media (max-width: 900px) {
  .tilescontent__item {
    flex-basis: 50%;
    max-width: 50%;
  }
}
@media (max-width: 600px) {
  .tilescontent__list {
    display: block;
  }
  .tilescontent__item {
    max-width: 100%;
  }
}

/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
html {
  background-color: #fff;
  min-height: 100%;
  display: flex;
  flex-direction: row;
}

body {
  -webkit-font-smoothing: antialiased;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  min-width: 320px;
  position: relative;
  -webkit-overflow-scrolling: auto;
  overscroll-behavior-y: none;
}
html.mobile-navigation.showmobilemenu body, html.hidescroll body, html.showsitectas body {
  overflow: hidden;
}
@media (max-width: 700px) {
  html.showpagenav body {
    overflow: hidden;
  }
}

h1 .titletag {
  vertical-align: middle;
  display: inline-block;
  color: inherit;
  font-size: 16px;
  line-height: 20px;
  height: 32px;
  border-radius: 16px;
  text-transform: uppercase;
  border: 2px solid #fff;
  padding: 4px 15px;
  margin: 10px 0;
}
@media (max-width: 750px) {
  h1 .titletag {
    border-color: var(--color-blue);
  }
}

.header-titlebar .titletag {
  border-color: #fff;
}

main h1 .titletag {
  border-color: var(--color-blue);
}

header, footer {
  flex: none;
  background-color: var(--color-blue);
  color: #fff;
}
header ul, footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
header a, footer a {
  color: inherit;
  text-decoration: none;
}

header {
  order: -1;
}

footer {
  order: 2;
}

.wh-anchor {
  position: absolute;
  scroll-margin-top: 280px;
}

main {
  order: 1;
  background-color: #ffffff;
  flex: 1 1 auto;
  margin-top: var(--spacing-lg);
  position: relative;
  z-index: 0;
}
html.corporatehome main {
  background: transparent url("./web/img/home-bottomcircle.svg") no-repeat 100% 100%;
  background-size: 977px;
}
@media (max-width: 720px) {
  html.corporatehome main {
    background-size: 140%;
  }
}
main > *:not(.fullwidth):not(.wh-anchor):not(.centercontent-wide), main > .centercontent-rtd, main .centercontent-rtd, main > .wh-video {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 992px;
  padding-left: 32px;
  padding-right: 32px;
}
main > *.widget:not(.fullwidth):not(.centercontent-rtd):not(.centercontent-wide), main > *.centercontent-wide {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1176px;
  padding-left: 32px;
  padding-right: 32px;
}
html.corporatehome main > *:not(.fullwidth):not(.wh-anchor):not(.centercontent-wide), html.widecontent main > *:not(.fullwidth):not(.wh-anchor):not(.centercontent-wide) {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1176px;
  padding-left: 32px;
  padding-right: 32px;
}
main .widget, main .wh-video {
  clear: both;
  margin-bottom: clamp(4px, 10dvw, 104px);
}
main > .widget:not(.centercontent-rtd), main > .wh-video {
  margin-top: var(--spacing-lg2);
  margin-bottom: var(--spacing-lg2);
}
main > .wh-anchor:first-child + .widget:not(.centercontent-rtd), main > .wh-anchor:first-child + .wh-video, main > .widget:not(.centercontent-rtd) + .wh-anchor + .widget:not(.centercontent-rtd), main > .wh-video + .wh-anchor + .widget:not(.centercontent-rtd), main > .widget:not(.centercontent-rtd) + .wh-anchor + .wh-video, main > .wh-video + .wh-anchor + .wh-video {
  margin-top: 0;
}

/* no paddings l/r if widget in widget (inside in twocol or faq inside faq) or form */
.widget .widget .centercontent-rtd,
.widget .widget.centercontent-rtd,
.widget .widget .centercontent-wide,
.widget .widget.centercontent-wide,
.wh-form .widget.centercontent-rtd,
.wh-form .widget .centercontent-wide {
  padding-left: 0;
  padding-right: 0;
}

.pagetags__wrapper {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.pagetags__wrapper .pagetags {
  padding-top: 5px;
  color: var(--color-blue);
}
.pagetags__wrapper .pagetags a {
  color: inherit;
  text-underline-position: under;
}
@media (max-width: 600px) {
  .pagetags__wrapper {
    display: block;
  }
}

html.overlay--matchmaker #Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window,
html.overlay--matchmaker .bcToaster,
html.overlay--matchmaker .bcpNotificationBar,
html.overlay--studiezoeker #Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window,
html.overlay--studiezoeker .bcToaster,
html.overlay--studiezoeker .bcpNotificationBar,
html.showsitectas #Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window,
html.showsitectas .bcToaster,
html.showsitectas .bcpNotificationBar {
  display: none !important;
}
html.overlay--matchmaker .fancybox-overlay,
html.overlay--studiezoeker .fancybox-overlay,
html.showsitectas .fancybox-overlay {
  opacity: 0 !important;
  pointer-events: none;
}