
.button, .wh-form__button
{
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  border-radius: 24px;
  height: 48px;
  min-width: 100px;
  color: #fff;
  font-size: clamp(14px, 1.6dvw, 16px);
  font-weight: 600;
  line-height: 24px;
  background-color: var(--color-blue);
  border:  2px solid var(--color-blue);
  position: relative;
  white-space: nowrap;
  padding: 12px 30px;
  max-width: 100%;
  cursor: pointer;
  transition: color 300ms, background-color 300ms, border 300ms;
}

.button--radius-sm
{
  border-radius: var(--radius-sm);
  padding: 12px 16px;
}

.button--small
{
  height: 40px;
  padding: 12px;
}

.button:disabled, .wh-form__button:disabled, .wh-form button:disabled
{
  cursor: default;
  opacity: 0.2;
}

.button__title
{
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: inherit !important;
}
  .button__title + .button__icon
, .button__icon + .button__title
{
  margin-left: 10px;
}

svg.button__icon
{
  width: 28px;
  height: auto;
  vertical-align: bottom;
}
.button--small svg.button__icon
{
  width: 22px;
}

.button:hover, .wh-form__button:hover, .button--active
{
  color: var(--color-blue);
  background-color: #fff;
}

.button--orange
{
  border-color: var(--color-orange);
  background-color: var(--color-orange);
}
.button--orange:hover, .button--orange.button--active
{
  color: var(--color-orange);
}

.button--green
{
  color: var(--color-black);
  border-color: var(--color-green);
  background-color: var(--color-green);
}
.button--green:hover, .button--green.button--active
{
  color: var(--color-black);
  border-color: var(--color-green-lighter-dark);
  background-color: var(--color-green-lighter-dark);
}

.button--blue-dark
{
  color: #fff;
  border-color: var(--color-blue-dark);
  background-color: var(--color-blue-dark);
}
.button--blue-dark:hover, .button--blue-dark.button--active
{
  color: var(--color-blue);
  border-color: #fff;
  background-color: #fff;
}

.button--outline
{
  background-color:  transparent;
  color: var(--color-blue);
}
.button--outline:hover, .button--inverted:hover, .button--outline.button--active, .button--inverted.button--active
{
  color: #fff;
  background-color: var(--color-blue);
}

.button--outline.button--orange
{
  color: var(--color-orange);
}
.button--orange.button--outline:hover, .button--orange.button--inverted:hover
{
  color: #fff;
  background-color: var(--color-orange);
}

.button--outline.button--green
{
  border-color: var(--color-green);
  color: var(--color-black);
}
.button--green.button--outline:hover, .button--green.button--inverted:hover, .button--green.button--outline.button--active, .button--green.button--inverted.button--active
{
  background-color: var(--color-green);
}

.button--white
{
  color: #FFF;
  border-color: #FFF;
}

.button--inverted:not(.button--outline)
{
  background-color: #fff;
  color: var(--color-blue);
  border-color: #fff;
}
.button--inverted:not(.button--outline):hover, .button--inverted.button--active:not(.button--outline):hover
{
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.button--inverted.button--orange
{
  color: var(--color-orange);
}
.button--inverted.button--green
{
  color: var(--color-black);
}
.button--inverted.button--white
{
  color: var(--color-black);
}

.wh-form__button
{
  min-width: 100px;
}
.wh-form__button + .wh-form__button
{
  margin-left: 15px;
}
