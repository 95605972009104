/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-frameoverlay {
  background-color: var(--color-blue);
}
.widget-frameoverlay h2 {
  color: #fff;
  margin-bottom: 24px;
}
.widget-frameoverlay__inner {
  padding-top: 50px;
  padding-bottom: 50px;
}
.widget-frameoverlay picture {
  cursor: pointer;
  outline: 0 none;
  width: 100%;
}
.widget-frameoverlay img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}