/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-embedform__inner {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
  background-color: var(--color-blue-lighter);
}
.widget-embedform__content > h2 {
  margin-bottom: var(--spacing-sm);
}
.widget-embedform__content > p {
  color: #000;
  margin-bottom: 0;
  margin-top: 10px;
}
.widget-embedform .wh-form.wh-styledinput {
  margin-top: clamp(8px, 2.8dvw, 28px);
  max-width: 100%;
  display: flex;
  flex-flow: column;
}
.widget-embedform .wh-form.wh-styledinput .wh-form__prefillarea {
  position: absolute;
  z-index: 1;
}
.widget-embedform .wh-form.wh-styledinput .tio--form-footertext {
  color: var(--color-blue);
  margin-top: var(--spacing-sm);
}
.widget-embedform .wh-form.wh-styledinput .wh-form__navbuttons {
  order: 100;
}
.widget-embedform .wh-form.wh-styledinput h2 {
  margin-bottom: 10px;
}
.widget-embedform .wh-form.wh-styledinput p {
  margin-top: 20px;
  margin-bottom: 0;
}
.widget-embedform .wh-form.wh-styledinput .wh-form__richtext p:first-child {
  margin-top: 0;
}
.widget-embedform .wh-form.wh-styledinput .wh-form__buttongroup .wh-form__button {
  margin-bottom: 0;
}
.widget-embedform .wh-form.wh-styledinput .wh-form__fieldgroup {
  background-color: var(--color-blue-lighter);
  margin-bottom: 0;
  padding-bottom: 3px;
}
.widget-embedform .wh-form.wh-styledinput .wh-form__fieldgroup--richtext {
  padding-bottom: 0;
}
@media (min-width: 601px) {
  .widget-embedform .wh-form.wh-styledinput .wh-form__page:not(.wh-form__page--hidden) {
    margin-left: -10px;
    margin-right: -10px;
    display: flex;
    flex-wrap: wrap;
  }
  .widget-embedform .wh-form.wh-styledinput .wh-form__page > div {
    margin-right: 0;
    margin-left: 0;
  }
  .widget-embedform .wh-form.wh-styledinput .wh-form__page > div:not(.tio-embed-fullwidth) {
    flex: 1 1 50%;
    max-width: 50%;
  }
}