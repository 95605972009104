/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-fullwidthimage picture {
  width: 100%;
}
.widget-fullwidthimage img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
@media (max-width: 750px) {
  .widget-fullwidthimage {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .widget-fullwidthimage img {
    border-radius: 0;
  }
}