/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.studiezoeker {
  position: absolute;
  right: 0;
  top: 0;
  height: 80px;
  width: 100%;
  overflow: hidden;
  background-color: var(--color-blue);
  color: #fff;
  font-size: 16px;
}
.studiezoeker h1 {
  margin-top: 100px;
  color: inherit;
}
@media (max-width: 1000px) {
  .studiezoeker h1 {
    margin-top: 10vw;
  }
}
@media (max-width: 700px) {
  .studiezoeker h1 {
    margin-top: 70px;
  }
}
@media (max-width: 1024px) {
  .studiezoeker {
    height: 70px;
  }
}
html.studiezoekerpage .studiezoeker {
  position: relative;
  max-height: 480px;
  height: 56.25vw;
  min-height: 380px;
}
.studiezoeker__bgcircle {
  position: absolute;
  width: 1000px;
  top: -590px;
  right: -260px;
  transform: translateX(50%);
  z-index: 0;
  opacity: 0;
}
.studiezoeker__bgcircle svg {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
@media (max-width: 600px) {
  .studiezoeker__bgcircle {
    right: -400px;
  }
}
html:not(.studiezoekerpage) .studiezoeker__inner {
  transform: translateX(300px);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  transition: transform 300ms 500ms;
}
html:not(.studiezoekerpage) .studiezoeker--ready .studiezoeker__inner {
  transform: translateX(0);
}
.studiezoeker--allowtransition {
  transition: height 400ms;
}
html.studiezoekerpage .studiezoeker--allowtransition {
  transition: max-height 300ms;
}
.studiezoeker--allowtransition .studiezoeker__bgcircle {
  transition: all 400ms;
}
.studiezoeker--active {
  height: 100vh;
  z-index: 2;
  overflow-y: auto;
  padding-bottom: 60px;
}
.studiezoeker--active .studiezoeker__bgcircle {
  width: 567px;
  top: -215px;
  right: 50%;
  opacity: 1;
}
html.studiezoekerpage .studiezoeker__bgcircle {
  width: 567px;
  top: 245px;
  right: 50%;
  opacity: 1;
}
@media (max-width: 850px) {
  html.studiezoekerpage .studiezoeker__bgcircle {
    top: 28.8vw;
    z-index: 0;
  }
}
@media (max-width: 550px) {
  html.studiezoekerpage .studiezoeker__bgcircle {
    top: 158px;
  }
}
.studiezoeker__show {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  position: absolute;
  right: 32px;
  top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font: inherit;
  font-weight: 600;
  width: 195px;
  height: 48px;
  border-radius: 24px;
  z-index: 1;
  overflow: hidden;
  opacity: 1;
  transition: opacity 400ms 400ms;
  background-color: var(--color-green);
}
@media (max-width: 1024px) {
  .studiezoeker__show {
    top: 11px;
  }
}
.studiezoeker__show span {
  color: #000;
  flex: none;
  display: inline-block;
  width: 135px;
  padding-left: 5px;
  padding-left: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.studiezoeker__show span:first-child {
  padding-left: 15px;
  position: relative;
  transition: margin 500ms 2s;
}
.studiezoeker__show svg {
  margin-left: 15px;
  flex: none;
  width: 32px;
  height: 32px;
}
@media (max-width: 600px) {
  .studiezoeker__show {
    width: 48px;
  }
  .studiezoeker__show span {
    display: none;
  }
  .studiezoeker__show svg {
    margin-left: 8px;
  }
}
.studiezoeker--ready .studiezoeker__show > span:first-child {
  margin-left: -130px;
}
.studiezoeker--animatebutton .studiezoeker__show > span:first-child {
  margin-left: 0;
}
.studiezoeker--active .studiezoeker__show {
  display: none;
  opacity: 0;
}
.studiezoeker--beforehide .studiezoeker__show {
  display: block;
  opacity: 0;
}
.studiezoeker__hide {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  z-index: 1;
}
@media (max-width: 600px) {
  .studiezoeker__hide {
    color: var(--color-black);
  }
}
.studiezoeker--active .studiezoeker__hide {
  display: flex;
}
.studiezoeker__container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-top: 32px;
  opacity: 0;
  z-index: -1;
  transform: translateY(-50%);
}
.studiezoeker--allowtransition .studiezoeker__container {
  transition: transform 400ms, opacity 600ms;
}
.studiezoeker--active .studiezoeker__container {
  pointer-events: initial;
  opacity: 1;
  z-index: 0;
  transform: translate3d(0, 0, 0);
}
html.studiezoekerpage .studiezoeker--active {
  max-height: 100vh;
  padding-bottom: 32px;
  position: fixed;
  top: 80px;
  height: calc(100% - 80px);
}
@media (max-width: 1024px) {
  html.studiezoekerpage .studiezoeker--active {
    top: 70px;
    height: calc(100% - 70px);
  }
}
html.studiezoekerpage .studiezoeker--active h1 {
  margin-top: 20px;
}
html.studiezoekerpage .studiezoeker--active .studiezoeker__bgcircle {
  z-index: -1;
  top: -200px;
  opacity: 1;
}
html.studiezoekerpage .studiezoeker--active .studiezoeker__container {
  transform: translate3d(0, 0, 0);
}
html.studiezoekerpage .studiezoeker__container {
  pointer-events: initial;
  opacity: 1;
  z-index: 0;
  transform: translate3d(0, 110px, 0);
}
html.studiezoekerpage .studiezoeker--allowtransition .studiezoeker__container {
  transition: transform 300ms;
}
.studiezoeker__wizard {
  position: relative;
}
.studiezoeker__wizard h4 {
  color: #fff;
}
.studiezoeker__wizard__heading {
  position: relative;
  z-index: 1;
  text-align: center;
  margin-bottom: 20px;
}
.studiezoeker__wizard__tabs {
  display: flex;
  height: 72px;
  border-radius: 36px;
  background-color: #F3F4F6;
  color: var(--color-black);
}
.studiezoeker__wizard__tab {
  position: relative;
  outline: none;
}
.studiezoeker__wizard__tab__button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  padding: 15px 0;
  border-radius: 36px;
  width: 213px;
  flex: none;
  text-align: left;
  color: #6B7280;
  height: 100%;
  transition: width 300ms;
  overflow: hidden;
  text-overflow: ellipsis;
}
.studiezoeker__wizard__tab__title {
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 5px;
  color: var(--color-black);
}
.studiezoeker__wizard__tab__value {
  white-space: nowrap;
  width: 385px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  max-width: 100%;
}
.studiezoeker__wizard__tab__label {
  margin-left: 10px;
  padding: 0 32px 0 22px;
}
@media (min-width: 1024px) {
  .studiezoeker__wizard__tab + .studiezoeker__wizard__tab .studiezoeker__wizard__tab__label {
    border-left: 1px solid #D1D5DB;
    border-radius: 0;
  }
}
.studiezoeker__wizard__tab--active .studiezoeker__wizard__tab__label, .studiezoeker__wizard__tab--active + .studiezoeker__wizard__tab .studiezoeker__wizard__tab__label {
  border: 1px transparent !important;
}
.studiezoeker__wizard__tab--active .studiezoeker__wizard__tab__button {
  width: 350px;
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24);
}
.studiezoeker__wizard__tab__options {
  position: absolute;
  top: 88px;
  left: 0;
  right: 0;
  overflow: hidden;
  max-height: 0;
  opacity: 0;
  transition: max-height 400ms, opacity 500ms;
  background-color: #fff;
  border-radius: 32px;
  color: var(--color-black);
}
.studiezoeker__wizard__tab__options h4 {
  color: inherit;
  font-weight: 600;
}
.studiezoeker__wizard__tab__options__inner {
  padding: 24px;
}
.studiezoeker__wizard__tab__options ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.studiezoeker__wizard__tab__option {
  margin-top: 20px;
}
.studiezoeker__wizard__tab__option input {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.studiezoeker__wizard__tab__option__label {
  cursor: pointer;
  display: flex;
  align-items: baseline;
}
.studiezoeker__wizard__tab__option__label::before {
  flex: none;
  font-weight: 700;
  color: var(--color-blue);
  content: "\f054";
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  padding-right: 20px;
}
input:checked + .studiezoeker__wizard__tab__option__label {
  text-shadow: 0 0 1px #000;
}
.studiezoeker--active .studiezoeker__wizard__tab:focus-within:not(.studiezoeker__wizard__tab--disabled) .studiezoeker__wizard__tab__options {
  max-height: 100vh;
  opacity: 1;
}
.studiezoeker__mobileplaceholder {
  display: none;
}
@media (max-width: 1023px) {
  .studiezoeker__mobileplaceholder {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    position: relative;
  }
  .studiezoeker__mobileplaceholder__button {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    border: 0 none;
    padding: 0;
    background-color: transparent;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    display: flex;
    align-items: center;
    padding: 12px 25px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 28px;
    background-color: #fff;
    color: var(--color-black);
  }
  .studiezoeker__mobileplaceholder__icon svg {
    width: 32px;
    height: auto;
    vertical-align: bottom;
  }
  .studiezoeker__mobileplaceholder__tab {
    padding: 0 16px;
  }
  .studiezoeker__mobileplaceholder__tab + .studiezoeker__mobileplaceholder__tab {
    border-left: 1px solid #D1D5DB;
  }
  .studiezoeker--active .studiezoeker__mobileplaceholder {
    display: none;
  }
  html.studiezoekerpage .studiezoeker__container {
    overflow: hidden;
    max-height: 0;
  }
  html.studiezoekerpage .studiezoeker--active .studiezoeker__container {
    max-height: none;
  }
  .studiezoeker__wizard {
    max-width: calc(100% - 32px);
  }
  .studiezoeker__wizard__tabs {
    flex-flow: column;
    height: auto;
    background-color: transparent;
  }
  .studiezoeker__wizard__tab {
    margin-bottom: 8px;
    width: 350px;
    max-width: 100%;
  }
  .studiezoeker__wizard__tab__label {
    display: flex;
    align-items: center;
    min-height: 42px;
  }
  .studiezoeker__wizard__tab__title {
    font-size: 18px;
    font-weight: 600;
    flex: 1 1 auto;
    min-width: 50%;
    margin-bottom: 0;
  }
  .studiezoeker__wizard__tab__value {
    flex: none;
    width: auto;
    white-space: nowrap;
    max-width: 50%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .studiezoeker__wizard__tab__button {
    border-radius: 32px;
    width: 350px;
    max-width: 100%;
    background-color: #fff;
    z-index: 1;
    position: relative;
  }
  .studiezoeker__wizard__tab--active .studiezoeker__wizard__tab__button {
    box-shadow: none;
  }
  .studiezoeker__wizard__tab__options {
    position: relative;
    top: 0;
    margin-top: 0;
    border-radius: 0 0 32px 32px;
  }
  .studiezoeker__wizard__tab__options h4 {
    display: none;
  }
  .studiezoeker__wizard__tab:focus-within:not(.studiezoeker__wizard__tab--disabled) .studiezoeker__wizard__tab__options {
    margin-top: -32px;
  }
}