/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.googlesearch {
  line-height: 100%;
}
.googlesearch .gsc-control-cse {
  padding: 0 0 15px;
}

.searchpage__header {
  margin-top: -50px;
  position: relative;
}
@media (max-width: 750px) {
  .searchpage__header {
    margin-top: 0;
  }
}
.searchpage__header__inner {
  background-color: var(--color-green);
  padding: 50px 24px;
}
@media (max-width: 500px) {
  .searchpage__header {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .searchpage__header > .searchpage__header__inner {
    border-radius: 0;
  }
}

html.searchpage main {
  margin-top: 0;
}
html.searchpage main h1 {
  margin-top: 20px;
}

.searchpage__form {
  max-width: 936px;
  margin: 0 auto;
  display: flex;
  height: 48px;
  position: relative;
}
.searchpage__form .wh-autocomplete-values {
  margin-top: 5px;
  left: 0;
  right: 202px;
}
@media (max-width: 600px) {
  .searchpage__form .wh-autocomplete-values {
    right: 60px;
  }
}
.searchpage__form .wh-autocomplete-values .suggestionx {
  padding-left: 10px;
}
.searchpage__form input, .searchpage__form button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  height: 100%;
  display: block;
  padding: 0 24px;
  flex: 1 1 auto;
}
.searchpage__form input {
  min-width: 100px;
  color: inherit;
  background-color: #fff;
}
.searchpage__form button {
  font-weight: 600;
  min-width: 190px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex: none;
  margin-left: 12px;
  cursor: pointer;
  color: #fff;
  background-color: var(--color-blue);
}
@media (max-width: 600px) {
  .searchpage__form button {
    min-width: 48px;
    justify-content: center;
  }
  .searchpage__form button.button {
    padding: 0;
  }
  .searchpage__form button .button__title {
    display: none;
  }
  .searchpage__form button .button__icon {
    margin-left: 0;
  }
}

.searchpage__pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}
.searchpage__pagination a {
  text-decoration: none;
}
.searchpage__pagination__previous, .searchpage__pagination__next {
  flex: none;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: var(--color-blue);
}
.searchpage__pagination__previous--disabled, .searchpage__pagination__next--disabled {
  background-color: var(--color-blue-light);
}
@media (max-width: 565px) {
  .searchpage__pagination__previous, .searchpage__pagination__next {
    width: 8.5vw;
    height: 8.5vw;
  }
}
@media (max-width: 375px) {
  .searchpage__pagination__previous, .searchpage__pagination__next {
    width: 32px;
    height: 32px;
  }
}
.searchpage__pagination ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchpage__pagination ul li {
  display: inline-block;
  padding: 0 5px;
}
.searchpage__pagination__page {
  font-size: 18px;
}
.searchpage__pagination__page--active {
  font-weight: 800;
}

.wh-searchresults {
  margin-bottom: 70px;
}
.wh-searchresults__feedback {
  padding: 32px 0;
  font-weight: 700;
}
.wh-searchresults .consilio--highlight {
  font-weight: 700;
}
.wh-searchresults__list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.wh-searchresults__listitem {
  padding: 0 0 24px;
}
.wh-searchresults__listitem__text {
  flex: 1 1 auto;
  max-width: 100%;
}
.wh-searchresults__listitem--hasthumb .wh-searchresults__listitem__text {
  max-width: calc(100% - 191px);
}
.wh-searchresults__listitem img {
  flex: none;
  width: 167px;
  height: auto;
  align-self: flex-start;
  margin-left: 24px;
}
@media (max-width: 600px) {
  .wh-searchresults__listitem img {
    display: none;
  }
}
.wh-searchresults__listitem__link {
  color: inherit;
  text-decoration: none;
  display: flex;
}
.wh-searchresults__listitem__title {
  font-weight: 700;
  color: var(--color-blue);
  font-size: 120%;
  display: block;
}
.wh-searchresults__listitem__url {
  color: var(--color-blue);
  display: block;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-underline-position: under;
}
a:hover .wh-searchresults__listitem__url {
  text-decoration: underline;
}