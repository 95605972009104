/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
/* responsive layout overlay */
/*
New grid 2024

1440px
Spacer 24px
12 col, margins 156px
(max col width 72px)

1440px - 2*156px = 1128px

Tio blauw dark:      #0F37B9;
Tio blauw            #1345E7
Tio blauw light:     #2256FF
Tio blauw tint dark: #DCE3FB
Tio blauw grijs:     #F3F6FE

Tio groen:           #0DE972
Tio groen tint dark: #C3F5D9
Tio groen tint:      #E1FAEC

Tio oranje:          #FF5E1F

Tio cyan:            #1FE5FF

*/
.layoutoverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  pointer-events: none;
}
.layoutoverlay__columns {
  max-width: 1176px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;
  outline: 1px dotted rgba(255, 0, 0, 0.3);
  white-space: nowrap;
  position: relative;
  height: 100dvh;
  text-align: center;
  display: flex;
}
.layoutoverlay__columns .col {
  background-color: rgba(0, 0, 0, 0.05);
  outline: 1px solid rgba(0, 0, 0, 0.1);
  display: inline-block;
  height: 100dvh;
  flex-grow: 1;
}
.layoutoverlay__columns .gap {
  flex: none;
  display: inline-block;
  width: 24px;
}