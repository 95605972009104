.widget-contextualcontent-preview.wh-styledinput {
  --height-collapsed: 30px;
  --height-expanded: 50vh;
  --width: 40vw;
  --padding-horizontal: 10px;
  --padding-vertical: 8px;
  --line-height: 20px;
  --hit-target-margin: 100px;
  --transition-time: .1s;
  --checkbox-size: 14px;
  background: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: inline-block;
  font-size: 12px;
  height: var(--height-collapsed);
  left: calc((100vw - var(--width)) / 2);
  line-height: var(--line-height);
  opacity: 0.7;
  overflow: hidden auto;
  padding: calc((var(--height-collapsed) - var(--line-height)) / 2) var(--padding-horizontal) var(--padding-vertical);
  position: fixed;
  top: 0;
  transition: all var(--transition-time);
  width: var(--width);
  z-index: 10000;
}
.widget-contextualcontent-preview.wh-styledinput::after {
  background: #ffffff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  content: attr(data-preview-label);
  display: block;
  height: var(--height-collapsed);
  left: calc((100vw - var(--width)) / 2);
  padding: calc((var(--height-collapsed) - var(--line-height)) / 2) var(--padding-horizontal) var(--padding-vertical);
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: all var(--transition-time);
  width: var(--width);
}
.widget-contextualcontent-preview.wh-styledinput::before {
  content: "";
  display: block;
  height: var(--height-collapsed);
  left: calc((100vw - var(--width)) / 2);
  position: fixed;
  top: 0;
  transition: all var(--transition-time);
  width: var(--width);
  z-index: -1;
}
.widget-contextualcontent-preview.wh-styledinput label {
  cursor: pointer;
  display: block;
  margin-bottom: 3px;
  margin-top: 3px;
  max-width: 100%;
  opacity: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all var(--transition-time);
  white-space: nowrap;
}
.widget-contextualcontent-preview.wh-styledinput input[type=checkbox],
.widget-contextualcontent-preview.wh-styledinput input[type=text],
.widget-contextualcontent-preview.wh-styledinput input[type=date],
.widget-contextualcontent-preview.wh-styledinput input[type=time] {
  display: inline-block;
  font: inherit;
  left: initial;
  margin: 1px 8px 1px 1px;
  min-height: 0;
  padding: 0;
  position: relative;
  vertical-align: top;
}
.widget-contextualcontent-preview.wh-styledinput input[type=checkbox] {
  height: var(--checkbox-size);
  width: var(--checkbox-size);
  top: calc((var(--line-height) - var(--checkbox-size) - 2px) / 2);
}
.widget-contextualcontent-preview.wh-styledinput input[type=checkbox]:checked::before {
  background: var(--color-blue);
  border-radius: 2px;
  bottom: 2px;
  content: "";
  left: 2px;
  position: absolute;
  right: 2px;
  top: 2px;
}
.widget-contextualcontent-preview.wh-styledinput input[type=text],
.widget-contextualcontent-preview.wh-styledinput input[type=date],
.widget-contextualcontent-preview.wh-styledinput input[type=time] {
  height: var(--line-height);
}
.widget-contextualcontent-preview.wh-styledinput:hover {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  height: var(--height-expanded);
  opacity: 1;
}
.widget-contextualcontent-preview.wh-styledinput:hover::after {
  opacity: 0;
}
.widget-contextualcontent-preview.wh-styledinput:hover::before {
  height: calc(var(--height-expanded) + var(--hit-target-margin));
  left: calc((100vw - var(--width)) / 2 - var(--hit-target-margin));
  width: calc(var(--width) + 2 * var(--hit-target-margin));
}
.widget-contextualcontent-preview.wh-styledinput:hover label {
  opacity: 0.5;
}
.widget-contextualcontent-preview.wh-styledinput:hover label:hover {
  opacity: 1;
}

.widget-contextualcontent-debug {
  background: #e6f6fc;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  color: #333333;
  font: 10px/150% "Inter", Arial, Helvetica, sans-serif;
  line-height: 12px !important;
  padding: 4px !important;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-shadow: none;
  width: 250px !important;
  z-index: 10000;
}
.widget-programmes .widget-contextualcontent-debug {
  border-top-left-radius: 0;
  top: 0;
}