/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
html.vacanciespage .contents--grid4, .ubeeo-vacancies, .ubeeo-vacancy,
.ubeeo-profile, .ubeeo-thankyoupage, .ubeeo-applypage, .ubeeo-jobalertpage {
  width: 1140px;
  margin: 0 auto 50px;
  max-width: 100%;
}

.hs_platform_area .button, .ubeeo-jobalertpage .button {
  position: relative;
  margin-top: 15px;
  padding-right: 40px;
}
.hs_platform_area .button + .button, .ubeeo-jobalertpage .button + .button {
  margin-left: 15px;
}
.hs_platform_area .button::before, .ubeeo-jobalertpage .button::before {
  position: absolute;
  right: 20px;
  top: 50%;
  font-weight: 700;
  transform: translateY(-50%);
  text-align: center;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f054";
  display: inline-block;
}
@media (max-width: 500px) {
  .hs_platform_area .button.portal_page_button, .ubeeo-jobalertpage .button.portal_page_button {
    display: block;
    margin-left: 0;
  }
}

.ubeeo-vacancies-intro {
  margin-bottom: 32px;
}
.ubeeo-vacancies-intro > *:last-child {
  margin-bottom: 0;
}

.ubeeo-vacancies {
  display: flex;
}
.ubeeo-vacancies__filterstoggle {
  margin-top: 15px;
  display: none;
  padding: 3px 15px 3px 3px;
  background-color: var(--color-blue);
  cursor: pointer;
  color: #fff;
  height: 40px;
  border-radius: 20px;
  align-items: center;
  font-size: 16px;
  position: relative;
  top: -20px;
}
.ubeeo-vacancies__filterstoggle--inoverlay {
  margin-top: 0;
  height: 44px;
  padding-left: 10px;
  border-radius: 3px;
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  z-index: 1;
  top: auto;
}
.ubeeo-vacancies__filterstoggle__icon {
  display: inline-block;
  flex: none;
  width: 34px;
  height: 34px;
  line-height: 34px;
  background-color: #fff;
  border-radius: 50%;
  color: var(--color-blue);
  text-align: center;
}
.ubeeo-vacancies__filterstoggle--inoverlay .ubeeo-vacancies__filterstoggle__icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.ubeeo-vacancies__filterstoggle__title {
  padding: 0 12px;
  font-weight: 700;
  flex: 1 1 auto;
  max-width: calc(100% - 100px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ubeeo-vacancies__filterstoggle__action {
  flex: none;
  font-size: 14px;
  text-decoration: underline;
}
.ubeeo-vacancies__filterscontainer {
  flex: none;
  width: 256px;
  font-size: 15px;
  margin-right: 30px;
  background-color: #fff;
}
.ubeeo-vacancies__form:focus {
  outline: none;
}
.ubeeo-vacancies__form__filter__showmore {
  display: none;
  margin-top: 7px;
  color: var(--color-blue);
  cursor: pointer;
}
.ubeeo-vacancies__form h2 {
  font-size: 28px;
  font-size: clamp(22px, 3.2dvw, 32px);
}
.ubeeo-vacancies__form.wh-form.wh-styledinput h3 {
  font-size: 18px;
  padding-bottom: 7px;
  border-bottom: 1px solid var(--color-blue);
}
.ubeeo-vacancies__form.wh-form.wh-styledinput .wh-form__fieldline {
  margin-top: 5px;
}
.ubeeo-vacancies__form.wh-form.wh-styledinput .wh-form__fieldline[hidden] {
  display: none;
}
.ubeeo-vacancies__form.wh-form.wh-styledinput .wh-form__fieldline[hidden] ~ .ubeeo-vacancies__form__filter__showmore {
  display: block;
}
.ubeeo-vacancies__form.wh-form.wh-styledinput .wh-form__optiondata > * {
  padding: 0 0 0 10px;
  line-height: 135%;
}
.ubeeo-vacancies__form.wh-form.wh-styledinput input[type=checkbox] + label {
  top: 1px;
  border-radius: 3px;
  width: 16px;
  max-width: 16px;
  flex: 0 0 16px;
  height: 16px;
}
.ubeeo-vacancies__form.wh-form.wh-styledinput input[type=checkbox] + label::after {
  padding: 0 0 0 1px;
  font-size: 14px;
}
.ubeeo-vacancies__form__filter__title {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ubeeo-vacancies__form__filter__title span {
  flex: 1 1 auto;
}
.ubeeo-vacancies__form__filter__title .icon {
  flex: none;
  position: relative;
  top: 3px;
}
.ubeeo-vacancies__form__filter--compact .icon {
  transform: scaleY(-1);
}
.ubeeo-vacancies__form__filter__foldcontainer {
  transition: max-height 200ms;
}
.ubeeo-vacancies__form__filter--compact .ubeeo-vacancies__form__filter__foldcontainer {
  max-height: 0;
  overflow: hidden;
}
.ubeeo-vacancies__form__resetfilter {
  text-underline-position: under;
  margin-top: 15px;
  cursor: pointer;
  color: var(--color-blue);
  font-size: 15px;
  text-decoration: underline;
}
.ubeeo-vacancies__form__filter--words {
  display: flex;
  height: 40px;
  border-radius: 20px;
  border: 1px solid #b3b3b3;
  overflow: hidden;
}
.ubeeo-vacancies__form__filter--words button, .ubeeo-vacancies__form__filter--words input {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 0;
  border: 0 none;
  background-color: transparent;
  line-height: 20px;
  height: 40px;
  font-size: 16px;
}
.ubeeo-vacancies__form__filter--words input {
  flex: 1 1 auto;
  padding: 0 10px 0 0;
  min-height: 100%;
}
.ubeeo-vacancies__form__filter--words input:focus, .ubeeo-vacancies__form__filter--words input:hover {
  box-shadow: none !important;
}
.ubeeo-vacancies__form__filter--words input:-webkit-autofill, .ubeeo-vacancies__form__filter--words input:-webkit-autofill:hover, .ubeeo-vacancies__form__filter--words input:-webkit-autofill:focus, .ubeeo-vacancies__form__filter--words input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; /* hide chrome autofill background color */
}
.ubeeo-vacancies__form__filter--words button {
  cursor: pointer;
  flex: none;
  color: var(--color-blue);
  text-align: center;
  padding: 10px;
}
.ubeeo-vacancies__form__filter + .ubeeo-vacancies__form__filter {
  margin-top: 20px;
}
.ubeeo-vacancies__ctas {
  margin-top: 25px;
  display: flex;
}
.ubeeo-vacancies__ctas:empty {
  display: none;
}
.ubeeo-vacancies__ctas h2 {
  margin-bottom: 5px;
  color: inherit;
}
.ubeeo-vacancies__ctas p {
  line-height: 135%;
}
.ubeeo-vacancies__ctas__jobalert, .ubeeo-vacancies__ctas__openapplication {
  text-decoration: none;
  min-height: 100%;
  margin-top: 0;
  color: #fff;
  padding: 20px 25px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 0 !important;
}
.ubeeo-vacancies__ctas__jobalert[hidden], .ubeeo-vacancies__ctas__openapplication[hidden] {
  display: none;
}
.ubeeo-vacancies__ctas__jobalert__button, .ubeeo-vacancies__ctas__openapplication__button {
  flex: none;
  align-self: flex-end;
}
.ubeeo-vacancies__ctas__openapplication {
  flex: 1 1 50%;
  background-color: var(--color-blue);
  margin-right: 15px;
}
.ubeeo-vacancies__ctas__openapplication__text {
  flex: 1 1 auto;
  margin-bottom: 20px;
}
.ubeeo-vacancies__ctas__jobalert {
  background-color: var(--color-green);
  flex: 1 1 50%;
  margin-left: 15px;
  color: var(--color-black);
}
.ubeeo-vacancies__ctas__jobalert__text {
  flex: 1 1 auto;
}
.ubeeo-vacancies__ctas__jobalert__button {
  padding-top: 15px;
}
.ubeeo-vacancies__ctas__openapplication[hidden] + .ubeeo-vacancies__ctas__jobalert {
  flex: 1 1 auto;
  margin-left: 0;
  flex-flow: row;
  align-items: center;
}
.ubeeo-vacancies__ctas__openapplication[hidden] + .ubeeo-vacancies__ctas__jobalert .ubeeo-vacancies__ctas__jobalert__button {
  padding-left: 15px;
  padding-top: 0;
}
.ubeeo-vacancies__results {
  margin-top: 5px;
  flex: 1 1 auto;
  max-width: calc(100% - 286px);
}
.ubeeo-vacancies__results h2 {
  font-size: 28px;
  font-size: clamp(22px, 3.2dvw, 32px);
}
.ubeeo-vacancies__results__noresults {
  margin-top: 25px;
}
.ubeeo-vacancies__results__noresults--feedback {
  background-color: var(--color-blue-lighter);
  padding: 20px 25px;
  border-radius: 3px;
}
.ubeeo-vacancies__feedback {
  font-size: clamp(15px, 2dvw, 20px);
}
.ubeeo-vacancies__feedback > span {
  display: block;
  white-space: nowrap;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
}
.ubeeo-vacancies__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: column;
}
.ubeeo-vacancies__list__mobilecta {
  display: none;
  order: 1;
  margin-top: 20px;
}
.ubeeo-vacancies__list__mobilecta > .ubeeo-vacancies__ctas__jobalert {
  margin-left: 0;
}
.ubeeo-vacancies__list__mobilecta h2 {
  color: inherit;
  margin-bottom: 5px;
}
.ubeeo-vacancies__list__item {
  display: block;
  padding-top: 20px;
  order: 2;
  transform: scale(1);
  opacity: 1;
  transition: opacity 150ms, transform 150ms;
}
.ubeeo-vacancies__list__item[hidden] {
  display: none;
}
.ubeeo-vacancies__list__item--hiding, .ubeeo-vacancies__list__item--showing {
  display: block !important;
  opacity: 0;
  transform: scale(0.001, 0.001);
}
.ubeeo-vacancies__vacancy {
  display: flex;
  flex-flow: column;
  text-decoration: none;
  color: inherit;
  transition: background-color 200ms;
  font-size: 15px;
  color: #575757;
  border-radius: 3px;
  border: 1px solid var(--color-blue);
  padding: 20px 25px;
  min-height: 195px;
}
.ubeeo-vacancies__vacancy:hover {
  background-color: #f9f9f9;
}
.ubeeo-vacancies__vacancy > * {
  display: block;
}
.ubeeo-vacancies__vacancy__title {
  font-size: 28px;
  color: var(--color-blue);
  margin-bottom: 10px;
  flex: none;
}
.ubeeo-vacancies__vacancy__description {
  line-height: 185%;
  flex: 1 1 auto;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ubeeo-vacancies__vacancy__meta {
  margin-top: 15px;
  display: flex;
  align-items: flex-end;
  flex: none;
}
.ubeeo-vacancies__vacancy__meta__spacer {
  flex: 1 1 auto;
}
.ubeeo-vacancies__vacancy__meta__item {
  flex: 1 1 auto;
  flex-basis: calc(33.333% - 58px);
  margin-right: 15px;
  white-space: nowrap;
  color: #696969;
  margin-bottom: -5px;
}
.ubeeo-vacancies__vacancy__meta .button {
  flex: none;
}
.ubeeo-vacancies__vacancy__meta__item i {
  display: inline-block;
  padding-right: 8px;
  color: var(--color-blue);
}

@media (max-width: 950px) {
  html.showvacancyfilters .header__titlebar, html.showvacancyfilters .header__page-nav, html.showvacancyfilters footer {
    display: none;
  }
  html.showvacancyfilters body {
    overflow: hidden;
  }
  .ubeeo-vacancies__ctas > .ubeeo-vacancies__ctas__jobalert {
    display: none;
  }
  .ubeeo-vacancies__ctas__openapplication {
    margin-right: 0;
  }
  .ubeeo-vacancies__list__mobilecta {
    display: block;
  }
  .ubeeo-vacancies__list__item--first {
    order: 0;
  }
  .ubeeo-vacancies__feedbackcontainer {
    background-color: var(--color-blue-lighter);
    margin: 0 -32px 50px;
    padding: 0 20px 20px;
    position: relative;
  }
  .ubeeo-vacancies__results__noresults--feedback {
    border: 1px solid var(--color-blue);
    background-color: #fff;
  }
  .ubeeo-vacancies__results__noresults--feedback h2 {
    margin-bottom: 5px;
  }
  .ubeeo-vacancies__filterscontainer {
    display: none;
    position: fixed;
    margin: 0;
    width: 100%;
    top: 40px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    transition: transform 200ms, opacity 200ms;
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  html.showvacancyfilters .ubeeo-vacancies__filterscontainer {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    display: block;
  }
  .ubeeo-vacancies__filterstoggle {
    display: flex;
  }
  .ubeeo-vacancies__form {
    margin: 0 auto;
    max-height: 100%;
    overflow-y: auto;
    padding: 20px 20px 80px;
  }
  .ubeeo-vacancies__form h2 {
    text-align: center;
  }
  .ubeeo-vacancies__results {
    max-width: 100%;
  }
}
@media (max-width: 700px) {
  .ubeeo-vacancies__vacancy__meta__item--hours {
    display: none;
  }
  .ubeeo-vacancies__vacancy__title {
    font-size: 24px;
  }
  .ubeeo-vacancies__vacancy__description {
    -webkit-line-clamp: 3;
  }
}
@media (max-width: 650px) {
  .ubeeo-vacancies__results__noresults--feedback {
    padding: 15px;
  }
  .ubeeo-vacancies__results__noresults:not([hidden]) ~ .ubeeo-vacancies__list > .ubeeo-vacancies__list__mobilecta {
    margin-top: 0;
  }
  .ubeeo-vacancies__ctas {
    display: block;
  }
  .ubeeo-vacancies__ctas:empty {
    display: none;
  }
  .ubeeo-vacancies__ctas__openapplication {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .ubeeo-vacancies__ctas__jobalert, .ubeeo-vacancies__ctas__openapplication {
    padding: 15px !important;
  }
  .ubeeo-vacancies__ctas__jobalert__button, .ubeeo-vacancies__ctas__openapplication__button {
    margin-left: 15px;
    margin-bottom: 5px;
  }
  .ubeeo-vacancies__ctas__jobalert {
    margin-left: 0;
  }
}
@media (max-width: 550px) {
  .ubeeo-vacancies__vacancy__meta__item--location {
    display: none;
  }
  .ubeeo-vacancies__vacancy__title {
    font-size: 21px;
  }
  .ubeeo-vacancies__vacancy__description {
    font-size: 14px;
    line-height: 165%;
    -webkit-line-clamp: 4;
  }
}
@media (max-width: 450px) {
  .ubeeo-vacancies__vacancy__meta__item--deadline {
    display: none;
  }
  .ubeeo-vacancies__vacancy__title {
    font-size: 18px;
  }
  .ubeeo-vacancies__vacancy__description {
    -webkit-line-clamp: 5;
  }
}
.ubeeo-vacancy {
  display: flex;
  align-items: flex-start;
}
.ubeeo-vacancy__backlink {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
}
.ubeeo-vacancy__backlink i {
  font-size: 14px;
  vertical-align: top;
  padding-right: 6px;
}
.ubeeo-vacancy__backlink:hover span {
  text-decoration: underline;
}
.ubeeo-vacancy__mobilectas {
  display: none;
  margin-bottom: 25px;
  color: #fff;
  margin-top: 40px;
}
.ubeeo-vacancy__mobilectas h2, .ubeeo-vacancy__mobilectas p, .ubeeo-vacancy__mobilectas a {
  color: inherit;
}
.ubeeo-vacancy__mobilectas h2 {
  margin-bottom: 8px;
}
.ubeeo-vacancy__content {
  flex: 1 1 auto;
  margin-right: 30px;
}
.ubeeo-vacancy__ctas {
  flex: none;
  width: 350px;
  color: #fff;
}
.ubeeo-vacancy__ctas h3, .ubeeo-vacancy__ctas p, .ubeeo-vacancy__ctas a {
  color: inherit;
}
.ubeeo-vacancy__ctas h2 {
  margin-bottom: 8px;
}
.ubeeo-vacancy__ctas__applynow {
  background-color: var(--color-blue);
  text-decoration: none;
  display: block;
  padding: 15px 25px 25px;
}
.ubeeo-vacancy__ctas__applynow .button {
  margin-top: 20px;
}
.ubeeo-vacancy__ctas__contact {
  background-color: var(--color-green);
  color: var(--color-black);
  padding: 15px 25px 25px;
  margin-top: 32px;
  position: relative;
}
html.mobile-navigation .ubeeo-vacancy__ctas {
  margin-top: 5px;
}
html.mobile-navigation .ubeeo-vacancy__ctas__contact {
  margin-top: 25px;
}
.ubeeo-vacancy__meta {
  background-color: var(--color-blue-lighter);
  padding: 20px 0 0 25px;
  line-height: 140%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  border-radius: 3px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.ubeeo-vacancy__meta__value {
  flex: 1 1 50%;
  max-width: 50%;
  position: relative;
  padding: 0 30px;
  margin-bottom: 15px;
}
.ubeeo-vacancy__meta__value__icon {
  position: absolute;
  font-size: 15px;
  top: 5px;
  left: 0;
  color: var(--color-blue);
}
@media (max-width: 812px) {
  .ubeeo-vacancy__meta {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .ubeeo-vacancy__meta__value {
    margin-bottom: 10px;
    padding: 0 0 5px 25px;
  }
  .ubeeo-vacancy__meta__value__icon {
    top: 4px;
  }
}
.ubeeo-vacancy__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 950px) {
  .ubeeo-vacancy {
    display: block;
  }
  .ubeeo-vacancy__content {
    margin-right: 0;
  }
  .ubeeo-vacancy__mobilectas {
    display: block;
  }
  .ubeeo-vacancy__meta {
    border-radius: 0;
    padding-left: 32px;
    padding-right: 32px;
    margin-left: -32px;
    margin-right: -32px;
  }
  .ubeeo-vacancy__ctas {
    width: 100%;
  }
  .ubeeo-vacancy__ctas > .ubeeo-vacancy__ctas__applynow {
    display: none;
  }
}
@media (max-width: 500px) {
  .ubeeo-vacancy__footer {
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
}