/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-faq__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-faq__item {
  border-bottom: 1px solid var(--color-blue-lighter-dark);
}
.widget-faq .widget-faq {
  margin-left: 24px;
  max-width: calc(100% - 24px);
}
.widget-faq .wh-details__summary {
  position: relative;
  padding: 15px 45px 12px 0;
}
.widget-faq .wh-details__summary h3 {
  margin-bottom: 0;
  color: #000;
  font-family: var(--font-family-default);
  font-size: clamp(20px, 2.4dvw, 24px);
  font-weight: 600;
}
.widget-faq .wh-details__summary::before {
  position: absolute;
  display: block !important;
  right: 0;
  top: 50%;
  margin-top: -9px;
  font-size: 16px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  color: var(--color-blue);
  content: "\f078";
}
.widget-faq .wh-details--open > .wh-details__summary::before {
  content: "\f077";
}