/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
/* always hide basic radio/checbox input */
.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}

/* radio / checkbox styling */
.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-styledinput input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  position: relative;
  top: 4px;
  width: 20px;
  max-width: 20px;
  flex: 0 0 20px;
  height: 20px;
  border-radius: 3px;
  color: #fff;
  border: 1px solid var(--color-blue);
  background-color: #fff;
}
.wh-styledinput input[type=radio] + label::after,
.wh-styledinput input[type=checkbox] + label::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  color: inherit;
  font-size: 16px;
  display: block;
  padding: 1px 0 0 2px;
  width: 100%;
  height: 100%;
  line-height: 16px;
  transition: all 0.3s;
}
.wh-styledinput input[type=radio] + label::before,
.wh-styledinput input[type=checkbox] + label::before {
  content: "";
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-blue);
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: transform 0.2s, opacity 0.2s;
}
.wh-styledinput input[type=radio]:not([disabled]):focus + label, .wh-styledinput input[type=radio]:not([disabled]):hover + label,
.wh-styledinput input[type=checkbox]:not([disabled]):focus + label,
.wh-styledinput input[type=checkbox]:not([disabled]):hover + label {
  box-shadow: 0 0 0 1px var(--color-blue);
}
.wh-styledinput input[type=radio]:not([disabled]):hover + label::before,
.wh-styledinput input[type=checkbox]:not([disabled]):hover + label::before {
  opacity: 0.15;
  transform: scale(2.5);
}
.wh-styledinput input[type=radio][disabled] + label,
.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: #C5C5C5;
  cursor: default;
}
.wh-styledinput input[type=radio][disabled] + label + *,
.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: inherit;
}

.wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}

.wh-form__optiondata > * {
  padding-left: 15px;
  line-height: 135%;
}

.wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}

.wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}

.wh-styledinput input[type=radio] + label {
  border-radius: 50%;
}
.wh-styledinput input[type=radio] + label::after {
  border: 3px solid transparent;
  border-radius: 50%;
}
.wh-styledinput input[type=radio]:focus + label::after, .wh-styledinput input[type=radio]:hover + label::after {
  border-color: #fff;
}
.wh-styledinput input[type=radio]:checked + label::after {
  border-color: #fff;
  background-color: var(--color-blue);
}
.wh-styledinput input[type=radio]:checked + label {
  border-color: var(--color-blue);
  background-color: var(--color-blue);
}
.wh-styledinput input[type=radio][disabled] + label::after {
  background-color: #F2F2F2;
  border-color: #F2F2F2;
}
.wh-styledinput input[type=radio][disabled]:checked + label::after {
  background-color: #C5C5C5;
}

.wh-styledinput input[type=checkbox]:checked + label::after {
  content: "\f00c";
  background-color: var(--color-blue);
}
.wh-styledinput input[type=checkbox][disabled] + label::after {
  content: "";
}
.wh-styledinput input[type=checkbox][disabled] + label {
  background-color: #F2F2F2;
}
.wh-styledinput input[type=checkbox][disabled]:checked + label::after {
  content: "\f00c";
  color: #C5C5C5;
}

.wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: #CC2500;
}

.wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}