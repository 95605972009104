/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
@media (min-width: 601px) {
  html.site--hasbottomrightbanner iframe#wm-livechat {
    bottom: 110px;
    max-height: calc(100% - 110px) !important;
  }
}
@media (min-width: 1200px) {
  html.site--hasbottomrightbanner iframe#wm-livechat {
    bottom: 60px;
    max-height: calc(100% - 60px) !important;
  }
}