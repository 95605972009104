/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
wh-fileedit {
  background-color: #fff;
  border: 1px solid var(--color-blue);
  border-radius: 3px;
}
wh-fileedit::part(filename) {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 0 none;
  font-family: inherit;
  font-size: 18px;
  height: 44px;
  outline: none;
  padding: 11px 15px;
}
wh-fileedit::part(button selectbutton) {
  background-color: #fff;
  border: 0 none;
  color: transparant;
  cursor: pointer;
  flex: none;
  font-size: 0;
  height: 44px;
  margin-left: 5px !important;
  min-height: 44px;
  min-width: 20px;
  padding: 0 5px;
}
wh-fileedit::part(button selectbutton)::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-blue);
  content: "\f382";
  font-size: 24px;
  font-weight: 800;
}
wh-fileedit::part(button deletebutton) {
  align-items: center;
  background-color: transparent;
  color: var(--color-blue);
  cursor: pointer;
  height: 44px;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
  width: 44px;
}
wh-fileedit::part(button deletebutton)::before {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: inherit;
  content: "\f057";
  display: inline-block;
  font-size: 16px;
  height: 20px;
  line-height: 19px;
  position: relative;
  text-align: center;
  top: 2px;
}

.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__uploadfield input {
  background-color: #fff;
  border: 0 none;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  margin-left: 5px !important;
  font-weight: 400;
  flex: none;
  min-width: 20px;
  font-size: 24px;
  min-height: 44px;
  padding: 0 5px;
  border: 0 none;
  background-color: #fff;
  cursor: pointer;
  color: var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__button::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f382";
  font-weight: 800;
}
.wh-form__fieldgroup--upload .wh-form__button .wh-form__buttonlabel {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  color: var(--color-blue);
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  color: inherit;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: -1px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(220, 227, 251, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(220, 227, 251, 0.8) 50%, rgba(220, 227, 251, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__uploadfield input {
  background-color: #fff;
  border: 0 none;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  margin-left: 5px !important;
  font-weight: 400;
  flex: none;
  min-width: 20px;
  font-size: 24px;
  min-height: 44px;
  padding: 0 5px;
  border: 0 none;
  background-color: #fff;
  cursor: pointer;
  color: var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__button::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f382";
  font-weight: 800;
}
.wh-form__fieldgroup--upload .wh-form__button .wh-form__buttonlabel {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  color: var(--color-blue);
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  color: inherit;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: -1px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(220, 227, 251, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(220, 227, 251, 0.8) 50%, rgba(220, 227, 251, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__uploadfield input {
  background-color: #fff;
  border: 0 none;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  margin-left: 5px !important;
  font-weight: 400;
  flex: none;
  min-width: 20px;
  font-size: 24px;
  min-height: 44px;
  padding: 0 5px;
  border: 0 none;
  background-color: #fff;
  cursor: pointer;
  color: var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__button::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f382";
  font-weight: 800;
}
.wh-form__fieldgroup--upload .wh-form__button .wh-form__buttonlabel {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  color: var(--color-blue);
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  color: inherit;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: -1px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(220, 227, 251, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(220, 227, 251, 0.8) 50%, rgba(220, 227, 251, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__uploadfield input {
  background-color: #fff;
  border: 0 none;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  margin-left: 5px !important;
  font-weight: 400;
  flex: none;
  min-width: 20px;
  font-size: 24px;
  min-height: 44px;
  padding: 0 5px;
  border: 0 none;
  background-color: #fff;
  cursor: pointer;
  color: var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__button::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f382";
  font-weight: 800;
}
.wh-form__fieldgroup--upload .wh-form__button .wh-form__buttonlabel {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  color: var(--color-blue);
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  color: inherit;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: -1px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(220, 227, 251, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(220, 227, 251, 0.8) 50%, rgba(220, 227, 251, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__uploadfield input {
  background-color: #fff;
  border: 0 none;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  margin-left: 5px !important;
  font-weight: 400;
  flex: none;
  min-width: 20px;
  font-size: 24px;
  min-height: 44px;
  padding: 0 5px;
  border: 0 none;
  background-color: #fff;
  cursor: pointer;
  color: var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__button::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f382";
  font-weight: 800;
}
.wh-form__fieldgroup--upload .wh-form__button .wh-form__buttonlabel {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  color: var(--color-blue);
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  color: inherit;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: -1px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(220, 227, 251, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(220, 227, 251, 0.8) 50%, rgba(220, 227, 251, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__uploadfield input {
  background-color: #fff;
  border: 0 none;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  margin-left: 5px !important;
  font-weight: 400;
  flex: none;
  min-width: 20px;
  font-size: 24px;
  min-height: 44px;
  padding: 0 5px;
  border: 0 none;
  background-color: #fff;
  cursor: pointer;
  color: var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__button::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f382";
  font-weight: 800;
}
.wh-form__fieldgroup--upload .wh-form__button .wh-form__buttonlabel {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  color: var(--color-blue);
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  color: inherit;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: -1px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(220, 227, 251, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(220, 227, 251, 0.8) 50%, rgba(220, 227, 251, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__uploadfield input {
  background-color: #fff;
  border: 0 none;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  margin-left: 5px !important;
  font-weight: 400;
  flex: none;
  min-width: 20px;
  font-size: 24px;
  min-height: 44px;
  padding: 0 5px;
  border: 0 none;
  background-color: #fff;
  cursor: pointer;
  color: var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__button::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f382";
  font-weight: 800;
}
.wh-form__fieldgroup--upload .wh-form__button .wh-form__buttonlabel {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  color: var(--color-blue);
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  color: inherit;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: -1px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(220, 227, 251, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(220, 227, 251, 0.8) 50%, rgba(220, 227, 251, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__uploadfield input {
  background-color: #fff;
  border: 0 none;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  margin-left: 5px !important;
  font-weight: 400;
  flex: none;
  min-width: 20px;
  font-size: 24px;
  min-height: 44px;
  padding: 0 5px;
  border: 0 none;
  background-color: #fff;
  cursor: pointer;
  color: var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__button::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f382";
  font-weight: 800;
}
.wh-form__fieldgroup--upload .wh-form__button .wh-form__buttonlabel {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  color: var(--color-blue);
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  color: inherit;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: -1px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(220, 227, 251, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(220, 227, 251, 0.8) 50%, rgba(220, 227, 251, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__uploadfield input {
  background-color: #fff;
  border: 0 none;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  margin-left: 5px !important;
  font-weight: 400;
  flex: none;
  min-width: 20px;
  font-size: 24px;
  min-height: 44px;
  padding: 0 5px;
  border: 0 none;
  background-color: #fff;
  cursor: pointer;
  color: var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__button::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f382";
  font-weight: 800;
}
.wh-form__fieldgroup--upload .wh-form__button .wh-form__buttonlabel {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  color: var(--color-blue);
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  color: inherit;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: -1px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(220, 227, 251, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(220, 227, 251, 0.8) 50%, rgba(220, 227, 251, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}
.wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
  background-color: #fff;
  border: 1px solid var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__uploadfield input {
  background-color: #fff;
  border: 0 none;
}
.wh-form__fieldgroup--upload .wh-form__button {
  height: 44px;
  margin-left: 5px !important;
  font-weight: 400;
  flex: none;
  min-width: 20px;
  font-size: 24px;
  min-height: 44px;
  padding: 0 5px;
  border: 0 none;
  background-color: #fff;
  cursor: pointer;
  color: var(--color-blue);
}
.wh-form__fieldgroup--upload .wh-form__button::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f382";
  font-weight: 800;
}
.wh-form__fieldgroup--upload .wh-form__button .wh-form__buttonlabel {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  color: var(--color-blue);
  top: 0;
  right: 0;
  background-color: transparent;
  height: 100%;
  align-items: center;
  padding-right: 5px;
  cursor: pointer;
}
.wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  line-height: 19px;
  color: inherit;
  text-align: center;
  font-size: 16px;
  position: relative;
  top: 2px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f057";
}
.wh-form__fieldgroup--upload .wh-form__uploadfield:not(.wh-form__uploadfield--hasfile) .wh-form__uploadfielddelete {
  display: none;
}
.wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex: 1 1 auto;
  position: relative;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: -1px;
}

.wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(220, 227, 251, 0.8) 16.67%, transparent 16.67%, transparent 50%, rgba(220, 227, 251, 0.8) 50%, rgba(220, 227, 251, 0.8) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}