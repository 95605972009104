/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
html.tio__registrationform .wh-form-pulldown__item--disabled {
  display: none;
}
html.tio__registrationform .wh-form__fieldgroup[data-wh-form-group-for=photo] .wh-form__fieldline--hasinfotext {
  flex-direction: row;
  align-items: flex-start;
}
html.tio__registrationform .wh-form__fieldgroup[data-wh-form-group-for=photo] .wh-form__infotextwrapper {
  flex: 1 0;
}
html.tio__registrationform .wh-form__fieldgroup[data-wh-form-group-for=photo] .wh-form__infotext {
  width: 200px;
}
html.tio__registrationform .wh-form__fieldgroup.electivesvalue,
html.tio__registrationform .wh-form__fieldgroup.electivesrequired {
  margin-bottom: 0;
}
html.tio__registrationform .wh-form__fieldgroup.electivesvalue + .wh-form__fieldgroup,
html.tio__registrationform .wh-form__fieldgroup.electivesrequired + .wh-form__fieldgroup {
  padding-bottom: 10px;
  padding-top: 0;
}
html.tio__registrationform .wh-form__fieldgroup.electivesvalue + .wh-form__fieldgroup .wh-form__fields,
html.tio__registrationform .wh-form__fieldgroup.electivesrequired + .wh-form__fieldgroup .wh-form__fields {
  margin-top: 0;
}
html.tio__registrationform .wh-form__fieldgroup.yesnocheckbox .wh-form__optiondata {
  flex: none;
  margin-right: 0;
  padding-right: 20px;
  width: auto;
}
html.tio__registrationform .wh-form__fieldgroup.yesnocheckbox .wh-form__fieldline > .wh-form__optiondata:first-of-type .wh-form__optionlabel {
  padding-left: 0;
}
html.tio__registrationform .wh-form__fieldgroup.yesnocheckbox .yesnooptions {
  align-items: baseline;
  display: flex;
  flex: none;
  flex-wrap: nowrap;
  margin-top: 5px;
}
html.tio__registrationform .tio__registration-costsoverview .wh-form__richtext {
  margin-bottom: 20px;
  width: 100%;
}
html.tio__registrationform .tio__registration-costsoverview .costsoverview {
  width: 100%;
}
html.tio__registrationform .tio__registration-costsoverview .costsoverview td + td,
html.tio__registrationform .tio__registration-costsoverview .costsoverview th + td {
  text-align: right;
}
html.tio__registrationform .tio__registration-costsoverview .costsoverview th {
  font-weight: normal;
  text-align: left;
}
html.tio__registrationform .tio__registration-costsoverview .costsoverview--totalfee td {
  font-weight: bold;
}
html.tio__registrationform .tio--externalregistration .tio__registration-costsoverview .wh-form__richtext,
html.tio__registrationform .tio--internalregistration .tio__registration-costsoverview .wh-form__richtext {
  margin-bottom: 0;
}
html.tio__registrationform .tio__registration-formresults-fieldsoverview {
  border-spacing: 0;
}
html.tio__registrationform .tio__registration-formresults-fieldsoverview th, html.tio__registrationform .tio__registration-formresults-fieldsoverview td {
  padding: 0;
  vertical-align: top;
}
html.tio__registrationform .tio__registration-formresults-fieldsoverview th {
  font-weight: normal;
  padding-right: 10px;
  text-align: left;
  white-space: nowrap;
}
html.tio__registrationform .tio__registration-formresults-fieldsoverview .tio__registration-formresults-fieldsoverview--space-above th, html.tio__registrationform .tio__registration-formresults-fieldsoverview .tio__registration-formresults-fieldsoverview--space-above td {
  padding-top: 15px;
}
html.tio__registrationform .tio__registration-formresults-fieldsoverview .tio__registration-foldablecontent__toggle {
  color: var(--color-blue);
  cursor: pointer;
  font-weight: 700;
  transition: all 0.3s;
}
html.tio__registrationform .tio__registration-formresults-fieldsoverview .tio__registration-foldablecontent__toggle .fa-plus,
html.tio__registrationform .tio__registration-formresults-fieldsoverview .tio__registration-foldablecontent__toggle .fa-minus {
  display: none;
  padding-right: 10px;
}
html.tio__registrationform .tio__registration-formresults-fieldsoverview .tio__registration-foldablecontent:not(.open) .fa-plus, html.tio__registrationform .tio__registration-formresults-fieldsoverview .tio__registration-foldablecontent.open .fa-minus {
  display: inline-block;
}
html.tio__registrationform .tio__registration-formresults-fieldsoverview .tio__registration-foldablecontent:not(.open) + .tio__registration-foldablecontent__foldcontainer {
  display: none;
}
html.tio__registrationform .tio__registration-formresults-costsoverview--intro {
  font-weight: bold;
  margin-top: 30px;
}
html.tio__registrationform .tio__registration-formresults-costsoverview {
  border-spacing: 0;
}
html.tio__registrationform .tio__registration-formresults-costsoverview th, html.tio__registrationform .tio__registration-formresults-costsoverview td {
  vertical-align: top;
}
html.tio__registrationform .tio__registration-formresults-costsoverview th {
  font-weight: normal;
  padding-right: 10px;
  text-align: left;
}
html.tio__registrationform .tio__registration-formresults-costsoverview td {
  text-align: right;
}
html.tio__registrationform .tio__registration-formresults-costsoverview .tio__registration-formresults-costsoverview--totalfee th, html.tio__registrationform .tio__registration-formresults-costsoverview .tio__registration-formresults-costsoverview--totalfee td {
  font-weight: bold;
}
html.tio__registrationform .tio__registration-formresults-costsoverview .tio__registration-formresults-costsoverview--footnote {
  font-size: 80%;
  top: -5px;
  position: relative;
}
html.tio__registrationform .tio__registration-formresults-costsoverview .fieldline--tip-inline {
  position: relative;
}
html.tio__registrationform .tio__registration-formresults-costsoverview .fieldline--tip-inline .wh-form__infotextwrapper {
  left: auto;
  right: -40px;
  text-align: left;
  top: 0;
}
html.tio__registrationform .tio__registration-formresults-footnotes {
  border-spacing: 0;
  margin-top: 10px;
}
html.tio__registrationform .tio__registration-formresults-footnotes th, html.tio__registrationform .tio__registration-formresults-footnotes td {
  vertical-align: top;
}
html.tio__registrationform .tio__registration-formresults-footnotes th {
  font-weight: normal;
  padding-right: 10px;
  text-align: left;
}
html.tio__registrationform .tio__registration-formresults-footnotes td {
  font-style: italic;
}
html.tio__registrationform .tio--externalregistration .tio__registration-formresults-footnotes th, html.tio__registrationform .tio--externalregistration .tio__registration-formresults-footnotes td,
html.tio__registrationform .tio--internalregistration .tio__registration-formresults-footnotes th,
html.tio__registrationform .tio--internalregistration .tio__registration-formresults-footnotes td {
  font-size: 14px;
}
html.tio__registrationform .tio--externalregistration .tio__registration-formresults-footnotes td,
html.tio__registrationform .tio--internalregistration .tio__registration-formresults-footnotes td {
  font-style: normal;
}
html.tio__registrationform .tio--externalregistration .tio__registration-formresults-footnotes p,
html.tio__registrationform .tio--internalregistration .tio__registration-formresults-footnotes p {
  margin: 0;
  padding: 0;
}
html.tio__registrationform .tio__registration-footnote.wh-form__fieldgroup {
  font-size: 14px;
  margin: 0 0 0 0.5em;
  padding-bottom: 0;
  position: relative;
}
html.tio__registrationform .tio__registration-footnote.wh-form__fieldgroup::before {
  display: block;
  left: -0.5em;
  position: absolute;
}
html.tio__registrationform .tio__registration-footnote1::before {
  content: "1. ";
}
html.tio__registrationform .tio__registration-footnote2::before {
  content: "2. ";
}
html.tio__registrationform .tio__registration-footnote3::before {
  content: "3. ";
}
html.tio__registrationform .tio__registration-footnote4::before {
  content: "4. ";
}
html.tio__registrationform .tio__registration-footnote5::before {
  content: "5. ";
}
html.tio__registrationform .tio__registration-footnote + .wh-form__fieldgroup:not(.tio__registration-footnote) {
  margin-top: 20px;
}
html.tio__registrationform .tio__registrationform-wrapper {
  display: flex;
}
html.tio__registrationform .tio__registrationform-wrapper > .wh-form {
  flex: 1 1 auto;
}
html.tio__registrationform .tio__registration-summarycontainer {
  margin-left: 24px;
  flex: none;
  width: calc(33.333% - 16px);
  position: relative;
}
html.tio__registrationform .tio__registration-summary {
  background: var(--color-blue-lighter);
  padding: 24px;
  pointer-events: auto;
  position: sticky;
  top: 150px;
}
@media (max-width: 1000px) {
  html.tio__registrationform .tio__registrationform-wrapper {
    display: block;
  }
  html.tio__registrationform .tio__registration-summarycontainer {
    width: 100%;
    margin-left: 0;
    margin-bottom: 50px;
  }
  html.tio__registrationform .tio__registration-summary {
    position: static;
  }
}
html.tio__registrationform .tio__registration-summary-course {
  font-weight: bold;
  margin-bottom: 10px;
}
html.tio__registrationform .tio__registration-summary-field {
  margin-left: -10px;
  display: flex;
  flex-wrap: wrap;
}
html.tio__registrationform .tio__registration-summary-label, html.tio__registrationform .tio__registration-summary-value {
  flex: 1 0 50%;
  padding-left: 10px;
}
html.tio__registrationform .tio__registration-summary-label::after {
  content: ":";
}
html.tio__registrationform sup {
  font-size: 0.6em;
}

html.tio__authenticationform .wh-form__pagination {
  display: none;
}