/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-actioncards__inner {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
  background-color: var(--color-green-lighter);
}
.widget-actioncards__cards {
  display: flex;
  margin-left: -24px;
}
.widget-actioncards__card {
  flex: 1 1 33.333%;
  max-width: 33.333%;
  padding-left: 24px;
}
.widget-actioncards__card__inner {
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-flow: column;
  text-decoration: none;
  color: var(--color-black);
  padding: var(--spacing-sm);
}
.widget-actioncards__card__icon {
  flex: none;
  height: 100px;
}
.widget-actioncards__card__icon > svg {
  width: auto;
  height: 100%;
}
.widget-actioncards__card__title {
  flex: none;
  margin-bottom: 10px;
}
.widget-actioncards__card__description {
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 150%;
}
.widget-actioncards__card__linktitle {
  text-align: right;
  display: flex;
  margin-top: 40px;
  font-weight: 600;
  align-items: center;
}
.widget-actioncards__card__linktitle > * {
  display: inline-block;
}
.widget-actioncards__card__linktitle i {
  padding-left: 10px;
  font-size: 90%;
  flex: none;
}
a:hover .widget-actioncards__card__linktitle span {
  text-underline-position: under;
  text-decoration: underline;
}
@media (max-width: 750px) {
  .widget-actioncards__cards {
    margin-left: 0;
    display: block;
  }
  .widget-actioncards__card {
    padding-left: 0;
    max-width: none;
  }
  .widget-actioncards__card + .widget-actioncards__card {
    margin-top: 12px;
  }
}