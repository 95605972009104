/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.newsoverview {
  margin-bottom: 50px;
}

.relatednews {
  clear: both;
  background-color: var(--color-blue-lighter);
  padding-top: 50px;
  padding-bottom: 50px;
}