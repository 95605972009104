/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.footer-nav {
  padding: 80px 0 80px;
}
.footer__logo {
  display: inline-block;
  margin-left: -8px;
}
.footer__logo img {
  height: 55px;
}
.footer__cols {
  display: flex;
  align-items: flex-start;
}
.footer__col {
  flex: 1 1 auto;
}
.footer__col--left {
  flex: none;
  width: calc(33.3333% + 8px);
}
.footer__col--nav {
  display: flex;
  align-items: flex-start;
}
.footer__nav {
  font-size: 12px;
  line-height: 165%;
  align-items: flex-start;
  flex: none;
  margin-right: 24px;
  width: calc(25% - 18px);
}
.footer__nav li {
  padding-bottom: 16px;
}
.footer__nav__title {
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
}
.footer__nav__title > span {
  flex: 1;
}
.footer__nav__title > i {
  margin-left: 15px;
  flex: none;
  transition: transform 300ms;
}
.footer__nav.wh-details--open .footer__nav__title > i {
  transform: scale(1, -1);
}
.footer__nav a {
  text-decoration: none;
  opacity: 0.8;
  font-size: 14px;
}
.footer__nav a:hover {
  opacity: 1;
}
.footer__award {
  margin-top: 25px;
}
.footer__award img {
  width: 100px;
  height: auto;
  vertical-align: bottom;
}
.footer__socialmedia {
  margin-top: clamp(30px, 5dvw, 50px);
  display: flex;
}
.footer__award + .footer__socialmedia {
  margin-top: 25px;
}
.footer__socialmedia a {
  width: clamp(24px, 3.6dvw, 36px);
  height: clamp(24px, 3.6dvw, 36px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: clamp(16px, 2.1dvw, 21px);
  flex: none;
  border-radius: 50%;
  background-color: #fff;
  color: var(--color-blue);
}
.footer__socialmedia a + a {
  margin-left: 12px;
}
@media (min-width: 901px) {
  .footer__nav > .wh-details__container {
    height: auto;
  }
  .footer__nav__title.wh-details__summary {
    cursor: default;
    pointer-events: none;
  }
  .footer__nav__title > i {
    display: none;
  }
}
@media (max-width: 900px) {
  .footer-nav {
    padding-top: 40px;
  }
  .footer__col--left {
    width: 286px;
  }
  .footer__col--nav {
    flex-wrap: wrap;
    margin-top: 0;
  }
  .footer__col--nav .footer__nav {
    width: calc(50% - 36px);
  }
}
@media (max-width: 700px) {
  .footer__col--nav {
    display: block;
  }
  .footer__col--nav .footer__nav {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .footer__cols {
    display: block;
  }
  .footer__col--left {
    display: block;
    flex-wrap: wrap;
    margin-bottom: 32px;
    width: auto;
  }
  .footer__col--left .footer__logo, .footer__col--left .footer__award {
    width: auto;
  }
  .footer__award--top {
    display: none;
  }
  .footer__award--bottom {
    display: block;
  }
}