/* infotext below input */
.wh-form__infotextwrapper {
  width: 100%;
  outline: none;
}
.wh-form__infotextwrapper .wh-form__infotext {
  color: #666;
  font-size: 14px;
  background-color: #F5F5F5;
  border-radius: 0 0 3px 3px;
  padding: 10px 15px;
  width: 100%;
}
.wh-form__infotextwrapper .wh-form__infotext > *:last-child {
  margin-bottom: 0;
}
.wh-form__fieldgroup.tio--hideinfotext .wh-form__infotextwrapper {
  display: none;
}

.wh-form__fieldgroup--imgedit .wh-form__infotext {
  max-width: 200px;
}

.wh-form__fieldline--nowrap.wh-form__fieldline--hasinfotext {
  flex-wrap: wrap;
}

.wh-form__optiondata + .wh-form__infotextwrapper {
  flex: 0 0 100%;
}

/* infotext right of input on hover */
html.webform--tip-right .wh-form .wh-form__infotextwrapper,
.wh-form .fieldline--tip-inline .wh-form__infotextwrapper {
  position: absolute;
  z-index: 10;
  left: 100%;
  top: 10px;
  margin-left: 15px;
  width: auto;
}
@media (max-width: 700px) {
  html.webform--tip-right .wh-form .wh-form__infotextwrapper,
  .wh-form .fieldline--tip-inline .wh-form__infotextwrapper {
    right: 10px;
    left: auto;
    margin-left: 0;
  }
}
html.webform--tip-right .wh-form .wh-form__infotextwrapper::after,
.wh-form .fieldline--tip-inline .wh-form__infotextwrapper::after {
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  content: "i";
  font-size: 16px;
  text-align: center;
  line-height: 21px;
  font-weight: 700;
  border: 2px solid var(--color-blue);
  color: var(--color-blue);
  cursor: pointer;
}
html.webform--tip-right .wh-form .wh-form__infotextwrapper::before,
.wh-form .fieldline--tip-inline .wh-form__infotextwrapper::before {
  content: ""; /* larger hitarea */
  display: block;
  width: 34px;
  height: 34px;
  position: absolute;
  top: -5px;
  left: -5px;
}
html.webform--tip-right .wh-form .wh-form__infotextwrapper .wh-form__infotext,
.wh-form .fieldline--tip-inline .wh-form__infotextwrapper .wh-form__infotext {
  position: absolute;
  top: 0;
  right: 30px;
  padding: 15px;
  background-color: #fff;
  width: 260px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.22);
  transform: translateY(-50%);
  margin-top: 12px;
  display: none;
}
html.webform--tip-right .wh-form .wh-form__infotextwrapper .wh-form__infotext::before,
.wh-form .fieldline--tip-inline .wh-form__infotextwrapper .wh-form__infotext::before {
  content: "";
  border-left: 14px solid #fff;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  position: absolute;
  left: 100%;
  top: 50%;
  margin-top: -9px;
}
html.webform--tip-right .wh-form .wh-form__infotextwrapper:hover .wh-form__infotext, html.webform--tip-right .wh-form .wh-form__infotextwrapper:focus .wh-form__infotext,
.wh-form .fieldline--tip-inline .wh-form__infotextwrapper:hover .wh-form__infotext,
.wh-form .fieldline--tip-inline .wh-form__infotextwrapper:focus .wh-form__infotext {
  display: block;
}
html.webform--tip-right .wh-form .wh-rtd__editor + .wh-form__infotextwrapper,
.wh-form .fieldline--tip-inline .wh-rtd__editor + .wh-form__infotextwrapper {
  top: 6px;
}
html.webform--tip-right .wh-form .wh-form__optiondata + .wh-form__infotextwrapper,
.wh-form .fieldline--tip-inline .wh-form__optiondata + .wh-form__infotextwrapper {
  top: 2px;
}
html.webform--tip-right .wh-form .wh-form__fieldgroup--imgedit .wh-form__infotextwrapper,
.wh-form .fieldline--tip-inline .wh-form__fieldgroup--imgedit .wh-form__infotextwrapper {
  left: 215px;
}
@media (max-width: 700px) {
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext .wh-form__suffix, html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext .wh-form__optiondata,
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext .wh-form__suffix,
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext .wh-form__optiondata {
    margin-right: 45px;
  }
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext .wh-form__uploadfieldselect.wh-form__button,
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext .wh-form-pulldown__arrow,
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext .wh-form__uploadfieldselect.wh-form__button,
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext .wh-form-pulldown__arrow {
    right: 35px;
  }
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext .wh-form__pulldown-styled .arrow,
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext .wh-form__pulldown-styled .arrow {
    right: 40px;
  }
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext input[type=text],
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext input[type=email],
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext input[type=password],
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext input[type=date],
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext input[type=number],
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext input[type=url],
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext input[type=tel],
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext input[type=time],
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext textarea,
  html.webform--tip-right .wh-form .wh-form__fieldline--hasinfotext .wh-rtd-toolbar,
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext input[type=text],
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext input[type=email],
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext input[type=password],
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext input[type=date],
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext input[type=number],
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext input[type=url],
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext input[type=tel],
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext input[type=time],
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext textarea,
  .wh-form .fieldline--tip-inline .wh-form__fieldline--hasinfotext .wh-rtd-toolbar {
    padding-right: 40px;
  }
}

.wh-form.wh-styledinput .fieldline--tip-inline .wh-form__optiondata {
  margin-right: 54px;
}
.wh-form.wh-styledinput .fieldline--tip-inline .wh-form__infotextwrapper {
  left: calc(100% - 54px);
  margin-left: 0;
  z-index: auto;
}
@media (max-width: 700px) {
  .wh-form.wh-styledinput .fieldline--tip-inline .wh-form__infotextwrapper {
    left: auto;
  }
}
.wh-form.wh-styledinput .fieldline--tip-inline .wh-form__infotextwrapper .wh-form__infotext {
  left: 30px;
  z-index: 1;
}
.wh-form.wh-styledinput .fieldline--tip-inline .wh-form__infotextwrapper .wh-form__infotext::before {
  border-left: none;
  border-right: 14px solid #fff;
  left: auto;
  right: 100%;
}
@media (max-width: 1000px) {
  .wh-form.wh-styledinput .fieldline--tip-inline .wh-form__infotextwrapper .wh-form__infotext {
    left: auto;
    right: 30px;
  }
  .wh-form.wh-styledinput .fieldline--tip-inline .wh-form__infotextwrapper .wh-form__infotext::before {
    border-left: 14px solid #fff;
    border-right: none;
    left: 100%;
    right: auto;
  }
}