/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  opacity: 1;
  color: #B3B3B3;
}

::-moz-placeholder {
  opacity: 1;
  color: #B3B3B3;
}

:-ms-input-placeholder {
  opacity: 1;
  color: #B3B3B3;
}

input:-moz-placeholder {
  opacity: 1;
  color: #B3B3B3;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0 none;
  -webkit-text-fill-color: var(--color-blue);
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

main > .wh-form.wh-styledinput {
  margin-bottom: 50px;
}

/* general form layout */
.wh-styledinput {
  font-size: 18px;
  /* groupedaddressfields */
  /* split programme options */
  /* guest list array field */
  /* open day choices array field */
}
.wh-styledinput hr {
  display: block;
  border: 0 none;
  border-top: 1px solid var(--color-blue-lighter);
  margin: 30px 0 25px;
}
.wh-styledinput .wh-form__fieldline {
  position: relative; /* needed for positioning info text right */
}
.wh-styledinput .wh-form__button--pagetitlebelow {
  margin-bottom: 30px; /* space for corresponding pagetitle below button */
}
.wh-styledinput .wh-form__button--pagetitlebelow:has(.wh-form__button__pagetitle:empty) {
  margin-bottom: 0; /* hide the space if the pagetitle is empty */
}
.wh-styledinput .wh-form__button--previous {
  float: left;
}
.wh-styledinput .wh-form__button__pagetitle {
  max-width: none;
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: 10px;
  color: var(--color-black);
  font-size: 16px;
  font-weight: 300;
  opacity: 0.5;
}
.wh-styledinput .wh-form__button--next > .wh-form__button__pagetitle {
  left: auto;
  right: 0;
}
.wh-styledinput .wh-form__fieldgroup {
  margin: 0 -10px;
  position: relative;
  z-index: 0;
  border-radius: 3px;
  padding: 20px 10px 10px;
}
.wh-styledinput .wh-form__fieldgroup:hover, .wh-styledinput .wh-form__fieldgroup:focus-within {
  z-index: 1;
}
.wh-styledinput .wh-form__fieldgroup--textarea > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--custom > .wh-form__label {
  align-self: flex-start;
  margin-top: 4px;
}
.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-styledinput .wh-form__fieldgroup--required.wh-form__fieldgroup--checkbox .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
  font-size: 18px;
}
.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
  font-size: 18px;
}
.wh-styledinput .wh-form__optionlabel, .wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
  font-size: 18px;
}
.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: none;
  max-width: calc(100% - 20px);
  font-size: 15px;
  padding-right: 10px;
  font-weight: 700;
  color: var(--color-blue);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 4px;
  left: 20px;
  z-index: 1;
  padding: 0 10px;
  height: 24px;
  transition: all 200ms;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  right: 0;
  height: 4px;
  display: block;
  background-color: #fff;
  z-index: -1;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label:empty {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup--label-in-field > .wh-form__label {
  top: 31px;
  font-weight: normal;
  font-size: 18px;
  color: #B3B3B3;
}
.wh-styledinput .wh-form__fieldgroup--label-in-field > .wh-form__label::before {
  display: none;
}
.wh-styledinput .groupedphonefields > .wh-form__fieldgroup .wh-form__label {
  top: -6px;
}
.wh-styledinput .groupedphonefields > .wh-form__fieldgroup {
  padding-bottom: 0;
}
.wh-styledinput .wh-form__fieldgroup--textarea > .wh-form__label {
  top: 0px;
}
.wh-styledinput .wh-form__fieldgroup--imgedit, .wh-styledinput .wh-form__fieldgroup--radiogroup,
.wh-styledinput .wh-form__fieldgroup--checkboxgroup,
.wh-styledinput .wh-form__fieldgroup--checkbox {
  padding-top: 30px;
}
.wh-styledinput .wh-form__fieldgroup--imgedit.wh-form__fieldgroup--nolabel, .wh-styledinput .wh-form__fieldgroup--radiogroup.wh-form__fieldgroup--nolabel,
.wh-styledinput .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--nolabel,
.wh-styledinput .wh-form__fieldgroup--checkbox.wh-form__fieldgroup--nolabel {
  padding-top: 15px;
}
.wh-styledinput .wh-form__fieldgroup--imgedit > .wh-form__label, .wh-styledinput .wh-form__fieldgroup--radiogroup > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--checkboxgroup > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--checkbox > .wh-form__label {
  left: 0;
}
.wh-styledinput .wh-form__fieldgroup--imgedit > .wh-form__label::before, .wh-styledinput .wh-form__fieldgroup--radiogroup > .wh-form__label::before,
.wh-styledinput .wh-form__fieldgroup--checkboxgroup > .wh-form__label::before,
.wh-styledinput .wh-form__fieldgroup--checkbox > .wh-form__label::before {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup--imgedit .wh-form__error, .wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__error,
.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__error,
.wh-styledinput .wh-form__fieldgroup--checkbox .wh-form__error {
  margin-left: 20px;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  max-width: 100%;
}
.wh-styledinput .wh-form__richtext > *:last-child {
  margin-bottom: 0;
}
.wh-styledinput .wh-form__fieldgroup--richtext {
  padding-top: 0;
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields,
.wh-styledinput .wh-form__fieldgroup--group > .wh-form__fields {
  max-width: 100%;
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields .wh-form__fieldline,
.wh-styledinput .wh-form__fieldgroup--group > .wh-form__fields .wh-form__fieldline {
  display: block;
}
.wh-styledinput .wh-form__fieldgroup--checkbox > .wh-form__fields {
  max-width: 100%;
}
.wh-styledinput select + .wh-form__subfield > label,
.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea {
  min-height: 44px;
  padding: 11px 15px;
  flex: 1;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea,
.wh-styledinput .wh-form__imgedit,
.wh-styledinput .datetime {
  border: 1px solid var(--color-blue);
  font-family: inherit;
  font-size: 18px;
  line-height: 20px;
  max-width: 100%;
  color: inherit;
  border-radius: 3px;
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
}
.wh-styledinput input:disabled, .wh-styledinput input[data-wh-form-disabled], .wh-styledinput select:not(.wh-rtd__toolbarstyle):disabled, .wh-styledinput select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-styledinput textarea:disabled,
.wh-styledinput textarea[data-wh-form-disabled],
.wh-styledinput .wh-form__imgedit:disabled,
.wh-styledinput .wh-form__imgedit[data-wh-form-disabled],
.wh-styledinput .datetime:disabled,
.wh-styledinput .datetime[data-wh-form-disabled] {
  color: #C5C5C5;
  border-color: #C5C5C5;
  background-color: #F2F2F2;
  box-shadow: none;
}
.wh-styledinput input:focus-within, .wh-styledinput select:not(.wh-rtd__toolbarstyle):focus-within,
.wh-styledinput textarea:focus-within,
.wh-styledinput .wh-form__imgedit:focus-within,
.wh-styledinput .datetime:focus-within {
  border-color: var(--color-blue);
  box-shadow: 0 0 0 1px var(--color-blue);
}
.wh-styledinput input:focus, .wh-styledinput input:not(:disabled):hover, .wh-styledinput select:not(.wh-rtd__toolbarstyle):focus, .wh-styledinput select:not(.wh-rtd__toolbarstyle):not(:disabled):hover,
.wh-styledinput textarea:focus,
.wh-styledinput textarea:not(:disabled):hover,
.wh-styledinput .wh-form__imgedit:focus,
.wh-styledinput .wh-form__imgedit:not(:disabled):hover,
.wh-styledinput .datetime:focus,
.wh-styledinput .datetime:not(:disabled):hover {
  border-color: var(--color-blue);
  box-shadow: 0 0 0 1px var(--color-blue);
}
.wh-styledinput textarea {
  min-height: 140px;
}
.wh-styledinput .wh-form__fieldgroup--error,
.wh-styledinput .wh-form__errorgroup {
  background-color: rgba(204, 37, 0, 0.1);
}
.wh-styledinput .wh-form__fieldgroup--error input, .wh-styledinput .wh-form__fieldgroup--error select,
.wh-styledinput .wh-form__fieldgroup--error textarea,
.wh-styledinput .wh-form__fieldgroup--error .wh-form__imgedit,
.wh-styledinput .wh-form__fieldgroup--error .datetime__date,
.wh-styledinput .wh-form__fieldgroup--error .datetime__time,
.wh-styledinput .wh-form__errorgroup input,
.wh-styledinput .wh-form__errorgroup select,
.wh-styledinput .wh-form__errorgroup textarea,
.wh-styledinput .wh-form__errorgroup .wh-form__imgedit,
.wh-styledinput .wh-form__errorgroup .datetime__date,
.wh-styledinput .wh-form__errorgroup .datetime__time {
  border-color: #CC2500;
  color: #CC2500;
}
.wh-styledinput .wh-form__error,
.wh-styledinput .wh-form__errorgroup {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: #CC2500;
  padding: 15px 0 0;
  font-size: 16px;
}
.wh-styledinput .wh-form__error::before,
.wh-styledinput .wh-form__errorgroup::before {
  content: "\f071";
  display: inline-block;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 7px;
}
.wh-styledinput .wh-form__fieldgroup--error > .wh-form__label {
  color: #CC2500;
}
.wh-styledinput .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}
.wh-styledinput .wh-form__errorgroup {
  padding: 15px;
  position: relative;
}
.wh-styledinput .wh-form__errorgroup::before {
  flex: none;
  padding-top: 4px;
}
.wh-styledinput .wh-form__errorgroup .wh-form__errorgroup-container {
  display: inline-block;
}
.wh-styledinput .wh-form__errorgroup .wh-form__errorgroup-container *:last-child {
  margin-bottom: 0;
}
.wh-styledinput .wh-form__errorgroup.wh-form__errorgroup--error {
  display: flex;
}
.wh-styledinput .wh-form__buttongroup {
  text-align: right;
  padding: 0;
  margin-top: var(--spacing-sm);
}
.wh-styledinput .wh-form__buttongroup + .wh-form__fieldgroup {
  margin-top: 15px;
}
.wh-styledinput .wh-form__buttongroup::after {
  content: "";
  display: block;
  clear: both;
}
.wh-styledinput .groupedaddressfields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -10px 8px;
}
.wh-styledinput .groupedaddressfields > .wh-form__fieldgroup {
  flex: 1 0 50%;
  max-width: 50%;
  margin: 0 0 0;
  padding-top: 13px;
  margin-top: 5px;
}
.wh-styledinput .groupedaddressfields > .wh-form__fieldgroup .wh-form__label {
  margin-top: -7px;
}
@media (max-width: 550px) {
  .wh-styledinput .groupedaddressfields {
    display: block;
  }
  .wh-styledinput .groupedaddressfields > .wh-form__fieldgroup {
    max-width: none;
  }
}
.wh-styledinput .groupedphonefields {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 15px -10px 8px;
}
.wh-styledinput .groupedphonefields > .wh-form__fieldgroup {
  flex: 1 0 auto;
  max-width: 100%;
  margin: 0 0 0;
  padding-top: 3px;
}
.wh-styledinput .groupedphonefields > .wh-form__fieldgroup.tio--phonefield {
  flex: 0 0 140px;
  max-width: 140px;
  min-width: 140px;
}
.wh-styledinput .groupedphonefields > .wh-form__fieldgroup .tio--phonefield__flag {
  margin-top: 6px;
}
.wh-styledinput .groupedphonefields > .wh-form__fieldgroup .wh-form-pulldown {
  max-width: 120px; /* fix for firefox (110px-20px) */
}
.wh-styledinput .groupedphonefields > .wh-form__fieldgroup .wh-form__label {
  margin-top: -7px;
}
@media (max-width: 405px) {
  .wh-styledinput .groupedphonefields > .wh-form__fieldgroup {
    max-width: calc(100% - 90px);
  }
  .wh-styledinput .groupedphonefields > .wh-form__fieldgroup.tio--phonefield {
    flex: 0 0 90px;
    padding-right: 0;
    max-width: 90px;
    min-width: 90px;
  }
  .wh-styledinput .groupedphonefields > .wh-form__fieldgroup.tio--phonefield .wh-form-pulldown__arrow {
    right: -3px;
    top: 10px;
  }
  .wh-styledinput .groupedphonefields > .wh-form__fieldgroup.tio--phonefield .countryphone {
    z-index: 2;
    margin-top: -9px;
    text-align: right;
    font-size: 12px;
    width: 27px;
  }
}
.wh-styledinput .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--programme .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-styledinput .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--programme .wh-form__fields__label {
  padding-top: 20px;
  font-weight: bold;
  font-size: 16px;
  flex: 0 0 25%;
}
.wh-styledinput .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--programme .wh-form__fields__label-hidden {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--programme .wh-form__subfieldgroup {
  padding-top: 20px;
  padding-left: 10px;
  flex: 0 0 75%;
}
.wh-styledinput .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--programme .wh-form__subfieldgroup--hidden {
  display: none;
}
@media (max-width: 600px) {
  .wh-styledinput .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--programme .wh-form__fields {
    display: block;
  }
  .wh-styledinput .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--programme .wh-form__fields__label {
    padding-top: 10px;
    width: 100%;
  }
  .wh-styledinput .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--programme .wh-form__subfieldgroup {
    padding-top: 5px;
    padding-left: 0;
    width: 100%;
  }
}
.wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield {
  --tio--guestsfield-fieldcount: 4;
  padding-top: 0;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arrayrow {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arrayrow .wh-form__fieldgroup {
  flex: none;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  width: calc((100% - 10px * var(--tio--guestsfield-fieldcount) - 16px) / var(--tio--guestsfield-fieldcount));
}
@media (max-width: 550px) {
  .wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arrayrow {
    flex-direction: column;
  }
  .wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arrayrow .wh-form__fieldgroup {
    width: 100%;
  }
}
.wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arraydelete {
  color: var(--color-blue);
  cursor: pointer;
  display: block;
  flex: none;
  margin: 0 -10px;
  padding: 0 10px;
  font-size: 0.9em;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arraydelete::before {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f056";
  display: inline-block;
  font-size: 16px;
  font-weight: 900;
  position: relative;
  top: 34px;
}
@media (max-width: 550px) {
  .wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arraydelete {
    margin-bottom: 10px;
  }
  .wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arraydelete::before {
    padding-right: 7px;
    top: 0;
  }
  .wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arraydelete::after {
    content: var(--tio--guestsfield-delete);
  }
}
.wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arrayadd {
  color: var(--color-blue);
  cursor: pointer;
  display: block;
  font-size: 0.9em;
  margin-bottom: -10px;
  padding: 10px 0;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arrayadd::before {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f055";
  display: inline-block;
  font-size: 16px;
  font-weight: 900;
  padding-right: 7px;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arrayadd::after {
  content: var(--tio--guestsfield-add);
}
.wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield.wh-form__array--maxrows .wh-form__arrayadd {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--guestsfield .wh-form__arrayrow + .wh-form__arrayadd {
  margin-top: -10px;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices {
  padding-top: 0;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices .wh-form__arrayrow {
  position: relative;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices .wh-form__arrayrow .wh-form__fieldgroup:first-child {
  margin-right: 16px;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices .wh-form__arraydelete {
  color: var(--color-blue);
  cursor: pointer;
  display: block;
  flex: none;
  height: 44px;
  padding: 0 10px;
  position: absolute;
  right: -10px;
  top: 20px;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices .wh-form__arraydelete::after {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f056";
  display: inline-block;
  font-size: 16px;
  font-weight: 900;
  position: relative;
  top: 14px;
  vertical-align: top;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices .wh-form__arrayadd {
  color: var(--color-blue);
  cursor: pointer;
  display: block;
  font-size: 0.9em;
  margin-bottom: -10px;
  padding: 10px 0;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices .wh-form__arrayadd::before {
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f055";
  display: inline-block;
  font-size: 16px;
  font-weight: 900;
  padding-right: 7px;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices .wh-form__arrayadd::after {
  content: "Ik wil nog een voorlichting bijwonen";
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices .wh-form__arrayadd:lang(en)::after {
  content: "I would like to attend another information session";
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices.wh-form__array--maxrows .wh-form__arrayadd {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup--array.tio--openday-choices .wh-form__arrayrow + .wh-form__arrayadd {
  margin-top: -10px;
}
.wh-styledinput .tio--openday-rounds .wh-form__fieldline.tio--openday-rounds-hidden {
  display: none;
}
.wh-styledinput .tio--project-qr {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.wh-styledinput .tio--project-qr__img img {
  height: 70vh;
  width: 70vh;
}
.tio--project-qr-form .header-mobile__menutoggle,
.tio--project-qr-form .studiezoeker,
.tio--project-qr-form .header-breadcrumb,
.tio--project-qr-form .header-titlebar,
.tio--project-qr-form .wh-form__navbuttons,
.tio--project-qr-form .tio--form-footertext,
.tio--project-qr-form .footer-nav {
  display: none !important;
}
.tio--project-qr-form main {
  margin-top: 0;
}