/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-stories__story {
  display: flex;
  text-decoration: none;
  color: var(--color-black);
  font-size: 18px;
  line-height: 135%;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
}
.widget-stories__story__image {
  flex: none;
  width: calc(41.666% - 13px);
}
.widget-stories__story__image > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-stories__story__text {
  flex: 1 1 auto;
  padding: var(--spacing-md);
}
.widget-stories__story__title {
  color: #000;
  margin-bottom: var(--spacing-sm);
}
.widget-stories__story__personname {
  font-weight: 600;
}
.widget-stories__story__personrole {
  font-size: 16px;
  opacity: 0.5;
}
.widget-stories__story__buttonholder {
  margin-top: var(--spacing-md);
}
@media (max-width: 600px) {
  .widget-stories__story {
    display: block;
  }
  .widget-stories__story__image {
    width: 100%;
  }
  .widget-stories__story__text {
    padding: 24px 0;
  }
  .widget-stories__story__text .button {
    justify-content: center;
    width: 100%;
  }
}