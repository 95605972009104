/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.itemscontentlist {
  margin-top: 50px;
  margin-bottom: 50px;
}
.itemscontentlist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: -24px;
}
.itemscontentlist__item__link {
  display: flex;
  padding: 24px;
  text-decoration: none;
  transition: background-color 300ms;
  align-items: flex-start;
}
.itemscontentlist__item__link:hover {
  background-color: var(--color-blue-lighter);
}
.itemscontentlist__item__icon {
  display: block;
  flex: none;
  font-size: 24px;
  margin-right: 24px;
}
.itemscontentlist__item__title {
  font-weight: 600;
  font-size: clamp(18px, 2.4dvw, 24px);
}
.itemscontentlist__item__modificationdate {
  font-size: 14px;
}
.itemscontentlist__item__filesize {
  font-weight: 400;
  font-size: 14px;
  font-style: italic;
}
.itemscontentlist__item__download i {
  font-size: 14px;
}