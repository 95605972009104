/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-basicbanner {
  max-width: 100%;
  height: 100%;
}
html.wh-widgetpreview .widget-basicbanner {
  background-color: var(--color-blue);
  color: #fff;
  display: inline-block;
  margin: 0 auto;
  width: 400px;
  max-width: 100%;
  height: auto;
  padding: 32px;
}
html.wh-widgetpreview .widget-basicbanner svg {
  width: 160px;
  height: auto;
}
.widget-basicbanner__wrapper {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-flow: column;
  height: 100%;
}
.widget-basicbanner a:hover {
  text-shadow: 0 0 1px #fff;
}
.widget-basicbanner__svg {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  margin: 24px auto;
}
.widget-basicbanner__svg > svg {
  max-width: 100%;
  height: auto;
}
.widget-basicbanner__description {
  flex: none;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}