@import "/.publisher/sd/tio_base/tio2022/fonts/fonts.css?display=swap";
@import "/.publisher/sd/tio_base/tio2022/fonts/fontawesome/css/all.min.css";

*, *::before, *::after
{
  -moz-box-sizing: border-box; /* Firefox version 28- (including 24 ESR) */
  -webkit-box-sizing: border-box; /* Android Browser version 3- */
  box-sizing: border-box;
}

html
{
  color: #121321;
}

body, .wh-rtd-editor, .wh-rtd-embeddedobject
{
  font: 18px/130% 'Inter', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

a
{
  color: var(--color-blue);
}
/* In the RTE, spaces before and after hyperlinks (and other inline elements probably) are automatically converted to
   non-breaking spaces. This means that lines with sequences of hyperlinks (like the 'follow us' links in location signatures)
   won't wrap at all. By adding a zero-width space before and after link content, the line can break again at the start and
   end of links. */
.wh-form a::before,
.wh-form a::after
{
  content: "\200B";
}

.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}
.wh-rtd__img--floatleft
{
  margin: 0 15px 15px 0;
}
.wh-rtd__img--floatright
{
  margin: 0 0 15px 15px;
}

h1, h2, h3, h4, .font-h1, .font-h2, .font-h3, .font-h4
{
  font-weight: 800;
  line-height: 110%; /* 112% so it doesn't cut of bottom side of letters like 'g' */
  color: var(--color-blue);
}
h1, h2, h3, .font-h1, .font-h2, .font-h3, .headingfont
{
  font-family: 'Raptor', "Arial-black", Arial;
}

h1, .font-h1
{
  line-height: 100%;
  font-size: 56px;
  margin-bottom: 15px;
}

main > h1
{
  margin-bottom: clamp(4px, 4.5dvw, 45px);
}

h2, .font-h2
{
  font-size: 40px;
  margin-bottom: clamp(20px, 3.2dvw, 32px);
}
h3, .font-h3
{
  line-height: 120%;
  font-size: 32px;
  margin-bottom: clamp(4px, 2.5dvw, 25px);
}
h4, .font-h4
{
  font-size: 24px;
  margin-bottom: 5px;
}

p.intro, .font-intro
{
  font-weight: 600;
  font-size: 24px;
  line-height: 130%;
  margin-bottom: clamp(20px, 3dvw, 30px);
}
p.normal
{
  line-height: 150%;
  margin-bottom: clamp(20px, 3dvw, 30px);
}

p.normal a, p.intro a
{
  color: var(--color-blue);
  text-underline-position: under;
}

/* used in form signatures */
p.normalgreen,
p.normalblue,
p.heading
{
  margin: 0;
}
p.normalgreen,
p.normalblue
{
}
p.normalgreen,
p.normalgreen a
{
  color: var(--color-green);
}
p.normalblue,
p.normalblue a
{
  color: var(--color-blue);
}
p.heading
{
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}
p.heading,
p.heading a
{
  color: var(--color-blue);
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

ul.unordered, ul.unorderedcircles
{
  font-size: inherit;
  line-height: 150%;
  margin-top: 0;
  margin-bottom: clamp(20px, 3dvw, 30px);
  list-style-type: none;
}
p.normal + ul.unordered + ul.unorderedcircles
{
  margin-top: -20px;
}

ul.unordered > li, ul.unorderedcircles > li
{
  padding-left: 20px;
  position: relative;
  margin: 0;
}
ul.unordered > li:before
{
  content: '\2022';
  display: inline-block;
  position: absolute;
  color: #151515;
  top: -1px;
  left: 0;
}
ul.unorderedcircles > li:before
{
  content: '';
  display: inline-block;
  width: 14px;
  height:  14px;
  border-radius: 50%;
  border: 4px solid #2bb1ee;
  position: absolute;
  top: 5px;
  left: 0;
}

ol.ordered
{
  font-size: inherit;
  line-height: 150%;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
  counter-reset: li;
}
  .widget ul.unordered, .widget ul.unorderedcircles
, .widget ol.ordered
{
  padding: 0;
}
ol.ordered > li
{
  padding: 0 0 15px clamp(43px, 5.5dvw, 55px);
  min-height: 45px;
  display: block;
  color: var(--color-blue);
  position: relative;
}
ol.ordered > li:before
{
  content: counter(li);
  counter-increment: li;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: clamp(28px, 4dvw, 40px);
  min-width: clamp(28px, 4dvw, 40px);
  border-radius: 50%;
  background-color: var(--color-blue);
  font-weight: 700;
  line-height: 150%;
  padding: 5px;
  font-size: clamp(16px, 1.8dvw, 18px);
  text-align: center;
  position: absolute;
  top: 3px;
  left: 0;
  transform: translateY(-30%);
}

ol.ordered2col
{
  -webkit-column-count: 2; /* Chrome, Safari, Opera */
  -webkit-column-gap: 40px;

  -moz-column-count: 2; /* Firefox */
  -moz-column-gap: 40px;

  column-count: 2; /* Standard syntax */
  column-gap: 40px;
}
ol.ordered2col > li
{
  max-width: 50%;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
}

.wh-rtd__tablewrap + .orderednotes
{
  margin-top: 0px;
}

ol.orderednotes
{
  margin-top: 20px;
  margin-bottom: clamp(20px, 3dvw, 30px);
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;
  line-height: 22px;
  color: inherit;
  list-style: none;
  counter-reset: li;
}
ol.orderednotes > li
{
  display: block;
  padding-left: 25px;
  position: relative;
}
ol.orderednotes > li:before
{
  content: counter(li);
  counter-increment: li;
  display: inline-block;
  position: absolute;
  color: #000000;
  top: 0;
  left: 0;
}

/* rtd tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  max-width: 100%;
}
.wh-rtd__table
{
  font-size: inherit;
  margin-bottom: clamp(20px, 3dvw, 30px);
}
.wh-rtd__table p
{
  margin-bottom: 0;
}
.wh-rtd__table p + p
{
  margin-bottom: clamp(20px, 3dvw, 30px);
}
  .wh-rtd__table th
, .wh-rtd__table td
{
  padding: 6px 15px;
  font-size: inherit;
  color: var(--color-blue);
  border-bottom: 2px solid var(--color-blue);
}
.wh-rtd__table td sup
{
  font-size: 12px;
}
  .wh-rtd__table.tablemoney th:last-child
, .wh-rtd__table.tablemoney td:last-child
{
  text-align: right;
}
  .wh-rtd__table.tablemoney tr:last-child > *
, .wh-rtd__table.tablelastrowbold tr:last-child > *
{
  font-weight: bold;
}
