/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-imagetext {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.widget-imagetext__image, .widget-imagetext__text {
  flex: 1 1 50%;
  max-width: calc(50% - 12px);
}
.widget-imagetext__image {
  align-self: flex-start;
}
.widget-imagetext__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.widget-imagetext__text > *:last-child {
  margin-bottom: 0;
}
.widget-imagetext__text:first-child {
  padding-right: calc(8.333% + 2px);
}
.widget-imagetext__text:last-child {
  padding-left: calc(8.333% + 2px);
}
@media (max-width: 700px) {
  .widget-imagetext {
    flex-flow: column;
  }
  .widget-imagetext__image, .widget-imagetext__text {
    flex: none;
    width: 100%;
    max-width: 100%;
  }
  .widget-imagetext__text {
    order: 1;
  }
  .widget-imagetext__text:first-child {
    padding-right: 0;
  }
  .widget-imagetext__text:last-child {
    padding-left: 0;
  }
  .widget-imagetext__image {
    order: -1;
    margin-bottom: 20px;
  }
}