/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.header-breadcrumb {
  position: relative;
  background-color: var(--color-blue);
  padding: 0 clamp(24px, 5dvw, 42px);
  z-index: 7;
}

.structuredbreadcrumb__scrollleft,
.structuredbreadcrumb__scrollright {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
}

.structuredbreadcrumb__scrollleft {
  left: -15px;
  background: linear-gradient(90deg, #1345e7 0%, #1345e7 50%, rgba(19, 69, 231, 0) 100%);
}

.structuredbreadcrumb__scrollright {
  right: -5px;
  background: linear-gradient(90deg, rgba(19, 69, 231, 0) 0%, #1345e7 50%, #1345e7 100%);
}

.structuredbreadcrumb__wrapper {
  position: relative;
}
@media (min-width: 601px) {
  .structuredbreadcrumb__wrapper {
    padding-left: 59px;
  }
  .structuredbreadcrumb__wrapper .structuredbreadcrumb__scrollleft {
    left: 49px;
  }
}

.structuredbreadcrumb__scrollarea {
  width: 100%;
  overflow: auto;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.structuredbreadcrumb__scrollarea.dragging {
  scroll-behavior: auto;
  cursor: grab;
}
.structuredbreadcrumb__scrollarea.scrollx-at-start ~ .structuredbreadcrumb__scrollleft {
  display: none;
}
.structuredbreadcrumb__scrollarea.scrollx-at-end ~ .structuredbreadcrumb__scrollright {
  display: none;
}

.structuredbreadcrumb__scrollarea::-webkit-scrollbar {
  display: none;
}

.structuredbreadcrumb {
  display: inline-flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  color: inherit;
  white-space: nowrap;
  line-height: 45px;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .structuredbreadcrumb {
    line-height: 40px;
  }
}
.structuredbreadcrumb li {
  display: inline;
  padding: 0 8px 0 0;
  font-weight: 600;
}
.structuredbreadcrumb li + li::before {
  content: "/";
  padding-right: 8px;
  opacity: 0.6;
}
.structuredbreadcrumb a {
  text-decoration: none;
  color: inherit;
  opacity: 0.6;
  transition: opacity 0.3s;
}
.structuredbreadcrumb a:hover {
  text-decoration: underline;
  text-underline-position: under;
  opacity: 1;
}