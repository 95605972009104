/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
body {
  padding-top: 80px;
}
body::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 80px;
  background-color: var(--color-blue);
}
@media (max-width: 1024px) {
  body {
    padding-top: 70px;
  }
  body::before {
    height: 70px;
  }
}

html.wh-widgetpreview body {
  padding-top: 0;
}
html.wh-widgetpreview body::before {
  display: none;
}

.header-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  transition: transform 300ms;
}
html.scrolldown .header-mobile {
  transform: translateY(-80px);
}
html.scrollup .header-mobile {
  transform: translateY(0);
}
.header-mobile__header {
  background-color: var(--color-blue);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 clamp(24px, 5dvw, 42px);
  height: 80px;
}
@media (max-width: 1024px) {
  .header-mobile__header {
    height: 70px;
  }
}
.header-mobile__home {
  position: relative;
}
.header-mobile__menutoggle {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  color: inherit;
  cursor: pointer;
  width: 24px;
  text-align: center;
  margin-right: 35px;
  font-size: 9px;
  font-weight: 600;
  align-items: center;
  position: relative;
}
.header-mobile__menutoggle__label {
  padding-top: 2px;
  display: inline-block;
}
@media (max-width: 700px) {
  .header-mobile__menutoggle__label {
    display: none;
  }
}
@media (max-width: 700px) {
  .studiezoeker--active + .header-mobile__home {
    display: none;
  }
  .studiezoeker--active ~ .header-mobile__menutoggle {
    margin-left: 0;
    color: var(--color-black);
  }
}
.header-mobile__logo {
  height: 40px;
  width: auto;
}
@media (max-width: 1000px) {
  .header-mobile__logo {
    height: 4vw;
  }
}
@media (max-width: 750px) {
  .header-mobile__logo {
    height: 30px;
  }
}
.header-mobile__icon > svg {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  margin-bottom: 5px;
}
.header-mobile__icon--close {
  font-size: 30px;
  display: none;
}
html.showmobilemenu .header-mobile__icon--show {
  display: none;
}
html.showmobilemenu .header-mobile__icon--close {
  display: inline-block;
}
.header-mobile__foldout {
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  overflow: hidden;
  opacity: 0;
  z-index: 11;
  transition: width 200ms, opacity 300ms;
}
.header-mobile__foldout ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.header-mobile__foldout a {
  text-decoration: none;
  color: inherit;
}
html.showmobilemenu .header-mobile__foldout {
  opacity: 1;
  width: 100%;
}
.header-mobile::before {
  content: "";
  opacity: 0;
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  transition: opacity 300ms;
}
html.showmobilemenu .header-mobile::before {
  display: block;
  opacity: 1;
}

.mobilenav {
  overflow: hidden;
  overflow-y: auto;
  height: 100%;
  max-width: 100%;
  font-size: 18px;
}
@media (max-width: 1024px) {
  .mobilenav {
    font-size: 16px;
  }
}
.mobilenav__cta {
  display: flex;
  color: var(--color-black) !important;
  border-radius: 16px;
  padding: 10px 30px 10px 40px;
  text-decoration: none;
  background-color: var(--color-green);
  align-items: center;
  max-width: 100%;
  overflow: hidden;
  margin-top: 32px;
}
.mobilenav__cta__text {
  flex: 1 1 auto;
  padding: 32px 0;
  font-size: 16px;
}
.mobilenav__cta__title {
  font-family: var(--font-family-heading);
  font-weight: 900;
  font-size: 24px;
}
.mobilenav__cta__description {
  margin-top: 10px;
}
.mobilenav__cta__icon {
  flex: none;
  width: 80px;
  margin-left: 15px;
}
.mobilenav__cta__icon > svg {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
.mobilenav__panel {
  padding: 24px 56px;
  width: 480px;
  max-width: 33.3333%;
  background-color: var(--color-blue);
  min-height: 100%;
  position: relative;
}
@media (max-width: 1440px) {
  .mobilenav__panel {
    padding: 24px 3.9vw;
  }
}
.mobilenav__topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mobilenav__languages {
  flex: none;
  display: flex;
  justify-content: flex-start;
  font-weight: 600;
  margin-left: -10px;
}
.mobilenav__languages__link {
  flex: none;
  height: 48px;
  width: 48px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  line-height: 28px;
  transition: transform 200ms;
}
.mobilenav__languages__link > span {
  display: inline-block;
  border-bottom: 2px solid transparent;
}
.mobilenav__languages__link:not(.mobilenav__languages__link--active):hover, .mobilenav__languages__link:not(.mobilenav__languages__link--active):focus {
  text-shadow: 0 0 1px #fff;
  transform: scale(1.1);
}
.mobilenav__languages__link--active {
  font-family: var(--font-family-heading);
  font-weight: 900;
  font-size: 110%;
}
.mobilenav__languages__link--active > span {
  border-bottom: 2px solid var(--color-green);
}
.mobilenav__search {
  margin-top: 24px;
  display: block;
  padding-top: 3px;
  padding-bottom: 10px;
  position: relative;
}
.mobilenav__search .wh-autocomplete-values {
  left: 0;
  right: 0;
  margin-top: -9px;
}
.mobilenav__search__fields {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
}
.mobilenav__search input, .mobilenav__search button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  border-radius: 0;
  display: block;
  font-size: 16px;
  height: 48px;
  background-color: transparent;
}
.mobilenav__search input {
  min-width: 100px;
  padding: 3px 20px 0 0;
  flex: 1 1 auto;
  color: var(--color-black);
}
.mobilenav__search button {
  flex: none;
  cursor: pointer;
  padding: 3px 15px 0 20px;
}
.mobilenav__list {
  margin: 30px 0;
}
.mobilenav__list--metanav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.mobilenav__list--metanav li {
  flex: none;
  width: calc(50% - 12px);
  padding: 11px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.mobilenav__list--metanav li:first-child, .mobilenav__list--metanav li:nth-child(2) {
  border: 0 none;
}
.mobilenav__foldout {
  background-color: #FFF;
  color: var(--color-blue);
  position: absolute;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 0;
  opacity: 0;
  max-width: 33.3333vw;
  transition: width 400ms, opacity 400ms;
  overflow: hidden;
}
.mobilenav__foldout__inner {
  padding: 40px;
  width: 480px;
  max-width: 33.3333vw;
  overflow: hidden;
  overflow-y: auto;
  max-height: 100%;
}
@media (max-width: 1440px) {
  .mobilenav__foldout__inner {
    padding: 40px 2.8vw;
  }
}
.mobilenav__foldout__nav {
  display: none;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-bottom: 20px;
}
.mobilenav__foldout__nav::before {
  content: "";
  display: block;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  position: absolute;
  left: -24px;
  bottom: 0;
  right: -24px;
}
.mobilenav__foldout__back {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  border-radius: 0;
  color: inherit;
  flex: none;
  padding: 0;
  font-size: inherit;
  display: flex;
  align-items: center;
  background-color: transparent;
  margin-right: 15px;
  cursor: pointer;
}
.mobilenav__foldout__back i {
  margin-right: 15px;
  font-size: 22px;
}
.mobilenav__foldout__heading {
  font-family: var(--font-family-heading);
  font-weight: 900;
  font-size: 32px;
  line-height: 165%;
  margin-top: 0;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  font-size: clamp(20px, 3.2dvw, 32px);
}
.mobilenav__foldout-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.mobilenav__foldout__item__link,
.mobilenav__foldout__item .mobilenav__foldout-toggle > * {
  padding: 15px 0;
  display: block;
}
.mobilenav__foldout__item__link,
.mobilenav__foldout__item .mobilenav__foldout-toggle > .mobilenav__item__title {
  border-top: 1px solid rgba(19, 69, 231, 0.2);
}
.mobilenav__foldout__item .mobilenav__item__title {
  transition: color 300ms;
}
.mobilenav__foldout__item:focus-within > * > .mobilenav__item__title, .mobilenav__foldout__item:hover > * > .mobilenav__item__title, .mobilenav__foldout__item--focus > * > .mobilenav__item__title {
  color: #4cf3ff;
  text-shadow: 0 0 1px #4cf3ff;
}
.mobilenav__foldout__itemlist .mobilenav__foldout__item:focus-within > * > .mobilenav__item__title, .mobilenav__foldout__itemlist .mobilenav__foldout__item:hover > * > .mobilenav__item__title, .mobilenav__foldout__itemlist .mobilenav__foldout__item--focus > * > .mobilenav__item__title {
  color: var(--color-blue-dark);
  text-shadow: 0 0 1px var(--color-blue-dark);
}
.mobilenav__foldout__item:first-child > .mobilenav__foldout__item__link, .mobilenav__foldout__item:first-child > .mobilenav__foldout-toggle > .mobilenav__item__title, .mobilenav__foldout__item--separator + .mobilenav__foldout__item .mobilenav__foldout__item__link, .mobilenav__foldout__item--label + .mobilenav__foldout__item .mobilenav__item__title {
  border-top: 0 none !important;
}
.mobilenav__foldout__item--label {
  background-color: var(--color-blue-lighter);
  margin-left: -40px;
  margin-right: -40px;
  font-weight: 900;
  font-size: 14px;
  font-family: var(--font-family-heading);
  padding: 5px 40px;
  border-top: 0 none !important;
}
.mobilenav__foldout__item + .mobilenav__foldout__item--label {
  margin-top: 10px;
}
.mobilenav__foldout__moreitem {
  margin: 30px 0;
}
.mobilenav__foldout__moreitem__link {
  display: inline-flex;
  align-items: center;
}
.mobilenav__foldout__moreitem__title {
  font-size: 12px;
  font-weight: 600;
}
.mobilenav__foldout__moreitem__link:hover .mobilenav__foldout__moreitem__title {
  text-decoration: underline;
  text-underline-position: under;
}
.mobilenav__foldout__moreitem__icon {
  font-size: 15px;
  flex: none;
  margin-left: 10px;
}
.mobilenav__foldout .mobilenav__foldout {
  background-color: #fff;
  color: var(--color-blue);
}
.mobilenav__foldout .mobilenav__foldout .mobilenav__foldout__item {
  border-color: rgba(19, 69, 231, 0.2);
}
.mobilenav__foldout .mobilenav__foldout a {
  color: inherit;
}
.mobilenav__item__link {
  display: block;
}
.mobilenav__item__title {
  transition: color 300ms;
}
.mobilenav__item--main {
  margin-top: 10px;
}
.mobilenav__item--main > * > .mobilenav__item__title {
  font-family: var(--font-family-heading);
  font-weight: 900;
  font-size: 24px;
  line-height: 165%;
  font-size: clamp(20px, 2dvw, 24px);
}
.mobilenav__item:focus-within > * > .mobilenav__item__title, .mobilenav__item:hover > * > .mobilenav__item__title, .mobilenav__item--focus > * > .mobilenav__item__title {
  color: #4cf3ff;
}
.mobilenav__foldout__itemlist .mobilenav__item:focus-within > * > .mobilenav__item__title, .mobilenav__foldout__itemlist .mobilenav__item:hover > * > .mobilenav__item__title, .mobilenav__foldout__itemlist .mobilenav__item--focus > * > .mobilenav__item__title {
  color: var(--color-blue-dark);
  text-shadow: 0 0 1px var(--color-blue-dark);
}
.mobilenav__foldout-toggle .mobilenav__item__title {
  flex: 1 1 auto;
}
.mobilenav__foldout-toggle .mobilenav__item__icon {
  text-align: right;
  flex: none;
  width: 25px;
}
.mobilenav .mobilenav__foldout-toggle:focus + .mobilenav__foldout,
.mobilenav .mobilenav__foldout:focus-within,
.mobilenav .mobilenav__item--focus > .mobilenav__foldout {
  width: 480px;
  opacity: 1;
  overflow: initial;
  z-index: 1;
}
@media (max-width: 1000px) {
  .mobilenav .mobilenav__panel {
    max-width: 100%;
    padding: 24px 32px;
  }
  .mobilenav .mobilenav__foldout__nav {
    display: flex;
  }
  .mobilenav .header-mobile__menutoggle {
    margin-right: 0;
  }
  .mobilenav .mobilenav__foldout {
    max-width: 100%;
    left: 0;
  }
  .mobilenav .mobilenav__foldout__heading {
    margin-top: 15px;
  }
  .mobilenav .mobilenav__foldout__inner {
    max-width: 100%;
    padding: 32px;
  }
}