/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.matchmaker {
  --progress: 0%;
  --page-width-max: 1140px;
  --page-padding: max(env(safe-area-inset-left), env(safe-area-inset-right), 24px);
  --page-grid-columns: [page-left margin-left] minmax(var(--page-padding), 1fr) [text] minmax(auto, var(--page-width-max)) [margin-right] minmax(var(--page-padding), 1fr) [page-right];
  --header-height: 55px;
  --progress-height: 4px;
  --logo-size: 35px;
  --page-transition-time: .25s;
  --drag-transition-time: .15s;
  --autodrag-transition-time: .4s;
  --results-transition-time: 1s;
  --toggle-transition-time: .3s;
  --content-padding: max((100vw - var(--page-width-max)) / 2, var(--page-padding));
  --content-width: min(var(--page-width-max), 100vw - 2 * var(--page-padding));
  --finish-content-width: min(400px, 100vw - 2 * var(--page-padding));
  --z-index-popup: 4;
  --z-index-progress: 3;
  --z-index-header: 2;
  --z-index-pages: 1;
  background: var(--color-blue);
  color: #ffffff;
  display: grid;
  grid-template-areas: ". header   ." ". progress ." ". pages    .";
  grid-template-columns: var(--page-grid-columns);
  grid-template-rows: var(--header-height) var(--progress-height) 1fr;
  height: 100%;
  opacity: 0;
  overflow: hidden auto;
  transition: opacity var(--page-transition-time);
  width: 100%;
  z-index: 0;
}
@media (min-width: 700px) {
  .matchmaker {
    --autodrag-transition-time: .65s;
    --header-height: 60px;
    --logo-size: 50px;
  }
}
@media (min-width: 700px) {
  .matchmaker {
    grid-template-areas: ".        header   ." "progress progress progress" ".        pages    .";
  }
}

.matchmaker__container {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1000;
}

.matchmaker__header {
  align-items: center;
  display: grid;
  grid-area: header;
  grid-template-columns: [logo] var(--header-height) [title] 1fr [close] var(--header-height);
  position: sticky;
  top: 0;
  z-index: var(--z-index-header);
}
.matchmaker__header::after {
  background: var(--color-blue);
  bottom: 0;
  content: "";
  display: block;
  position: absolute;
  left: calc((100% - 100vw) / 2);
  top: 0;
  width: 100vw;
  z-index: -1;
}

.matchmaker__logo {
  grid-column: logo;
  height: var(--logo-size);
  justify-self: start;
  width: var(--logo-size);
}

.matchmaker__title {
  align-items: center;
  display: flex;
  font-family: var(--font-family-heading);
  font-size: 18px;
  font-weight: 900;
  grid-column: title;
  justify-self: center;
}
@media (min-width: 700px) {
  .matchmaker__title {
    font-size: 24px;
  }
}

.matchmaker__close {
  font-size: 20px;
  grid-column: close;
  height: var(--header-height);
  justify-self: end;
  line-height: var(--header-height);
  text-align: center;
  width: var(--header-height);
}
.matchmaker__close a {
  color: #ffffff;
  display: block;
}

.matchmaker__progressbar {
  background: var(--color-blue-lighter);
  border-radius: var(--header-height);
  grid-area: progress;
  height: 100%;
  opacity: 1;
  position: sticky;
  top: var(--header-height);
  transition: opacity var(--page-transition-time);
  width: 100%;
  z-index: var(--z-index-progress);
}
@media (min-width: 700px) {
  .matchmaker__progressbar {
    border-radius: 0;
  }
}
.matchmaker--page-intro .matchmaker__progressbar {
  opacity: 0;
}

.matchmaker__progress {
  background-color: var(--color-green);
  border-radius: var(--header-height);
  display: block;
  height: 100%;
  transition: width var(--page-transition-time);
  width: var(--progress);
}
@media (min-width: 700px) {
  .matchmaker__progress {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.matchmaker__pages {
  grid-area: pages;
  position: relative;
  z-index: var(--z-index-pages);
}

.matchmaker__page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  transition: opacity var(--page-transition-time) ease-in-out;
}
.matchmaker__page--curpage {
  opacity: 1;
}
.matchmaker__page--nextpage {
  opacity: 1;
  z-index: -1;
}
.matchmaker__page--nextpage .matchmaker__buttons {
  opacity: 0;
}
.matchmaker__page--intro {
  display: grid;
  grid-template-rows: [image] 170px [text] 1fr;
  justify-content: start;
  margin-left: calc(0px - var(--page-padding));
  margin-right: calc(0px - var(--page-padding));
}
@media (min-width: 700px) {
  .matchmaker__page--intro {
    grid-template-rows: [image] 250px [text] 1fr;
  }
}
@media (min-width: 1024px) {
  .matchmaker__page--intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }
}
.matchmaker__page--finish {
  justify-content: start;
}
.matchmaker__page--finish::after {
  --finish-bg-scale: 1;
  --finish-bg-size: 1000px;
  --finish-pos-size: var(--finish-bg-scale) * var(--finish-bg-size);
  --finish-pos-offset: calc((var(--finish-pos-size) - var(--finish-bg-size)) / 2);
  background-image: url("../../web/img/o.svg");
  background-size: contain;
  content: "";
  height: var(--finish-bg-size);
  position: absolute;
  top: 0;
  left: 0;
  transition: transform var(--results-transition-time);
  width: var(--finish-bg-size);
  z-index: -1;
}
.matchmaker--page-finish .matchmaker__page--finish::after {
  --finish-bg-scale: 1.123;
  transform: translate(calc(var(--finish-pos-offset) - (var(--page-padding) - var(--finish-pos-size) / 2) + 35vw), calc(var(--finish-pos-offset) + 300px)) scale(var(--finish-bg-scale));
}
@media (min-width: 700px) {
  .matchmaker--page-finish .matchmaker__page--finish::after {
    --finish-bg-scale: 1.393;
    transform: translate(calc(var(--finish-pos-offset) - var(--finish-pos-size) + var(--content-width) / 2), calc(var(--finish-pos-offset) + 350px)) scale(var(--finish-bg-scale));
  }
}
.matchmaker--page-results .matchmaker__page--finish::after {
  --finish-bg-scale: .724;
  transform: translate(calc(var(--finish-pos-offset) + var(--content-width) / 2.6), -50%) scale(var(--finish-bg-scale));
}
@media (min-width: 700px) {
  .matchmaker--page-results .matchmaker__page--finish::after {
    --finish-bg-scale: 1.393;
    transform: translate(calc(var(--finish-pos-offset) + var(--content-width) / 3), -60%) scale(var(--finish-bg-scale));
  }
}
.matchmaker__page--popup {
  background: rgba(0, 0, 0, 0.5);
  z-index: var(--z-index-popup);
}

.matchmaker__intro,
.matchmaker__question,
.matchmaker__answer,
.matchmaker__insert,
.matchmaker__popup {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 10px;
  position: relative;
  text-align: center;
  width: 100%;
}
@media (min-width: 700px) {
  .matchmaker__intro,
  .matchmaker__question,
  .matchmaker__answer,
  .matchmaker__insert,
  .matchmaker__popup {
    height: 500px;
    margin-bottom: 50px;
    margin-top: 0;
    width: 500px;
  }
}
.matchmaker__intro::after,
.matchmaker__question::after,
.matchmaker__answer::after,
.matchmaker__insert::after,
.matchmaker__popup::after {
  background: #132d87;
  border-radius: 0 0 12px 12px;
  bottom: -11px;
  content: "";
  height: 11px;
  left: 14px;
  position: absolute;
  right: 14px;
  z-index: -1;
}
@media (min-width: 700px) {
  .matchmaker__intro::after,
  .matchmaker__question::after,
  .matchmaker__answer::after,
  .matchmaker__insert::after,
  .matchmaker__popup::after {
    border-radius: 0 0 24px 24px;
    bottom: -16px;
    height: 16px;
    left: 30px;
    right: 30px;
  }
}
.matchmaker__intro + .matchmaker__buttons,
.matchmaker__question + .matchmaker__buttons,
.matchmaker__answer + .matchmaker__buttons,
.matchmaker__insert + .matchmaker__buttons,
.matchmaker__popup + .matchmaker__buttons {
  padding-bottom: calc(30px + env(safe-area-inset-bottom));
}

.matchmaker__intro {
  align-items: start;
  align-self: start;
  background: #ffffff;
  color: var(--color-black);
  grid-row: text;
  height: auto;
  justify-content: start;
  margin-bottom: 0;
  margin-top: 0;
  min-height: 100%;
  padding: 40px 30px;
  text-align: left;
  width: 100vw;
}
.matchmaker__intro::after {
  display: none;
}
@media (max-width: 1023px) {
  .matchmaker__intro {
    border-radius: 0;
  }
}
@media (min-width: 1024px) {
  .matchmaker__intro {
    align-self: revert;
    height: 450px;
    min-height: 0;
    width: 450px;
  }
}
.matchmaker__intro .matchmaker__buttons {
  align-self: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  margin-top: 20px;
  text-align: center;
}
@media (min-width: 700px) {
  .matchmaker__intro .matchmaker__buttons {
    justify-content: end;
  }
}
.matchmaker__intro .matchmaker__buttons .matchmaker__button {
  background: var(--color-orange);
  border: 2px solid var(--color-orange);
  color: #ffffff;
  transition: background-color var(--toggle-transition-time), color var(--toggle-transition-time);
}
.matchmaker__intro .matchmaker__buttons .matchmaker__button:hover {
  background: #ffffff;
  color: var(--color-orange);
}
.matchmaker__intro h2 {
  font-size: 24px;
  line-height: 1.2em;
  margin-bottom: 0;
}
@media (min-width: 700px) {
  .matchmaker__intro h2 {
    font-size: 32px;
  }
}
.matchmaker__intro p {
  font-size: 16px;
  line-height: 24px;
  margin: 16px 0 0;
}
.matchmaker__intro p.intro {
  font-size: 18px;
}
.matchmaker__intro p.intro b {
  font-weight: 600;
}
@media (min-width: 700px) {
  .matchmaker__intro p.intro {
    font-size: 20px;
  }
}

.matchmaker__intro-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 250px;
  grid-row: image;
  height: 100%;
  width: 100%;
}
@media (min-width: 700px) {
  .matchmaker__intro-image {
    background-size: 360px;
  }
}
@media (min-width: 1024px) {
  .matchmaker__intro-image {
    background-size: contain;
    height: 600px;
    width: 500px;
  }
}

.matchmaker__question {
  background: var(--color-green);
  color: var(--color-black);
  flex: 1;
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-weight: 900;
  line-height: 1.3em;
  max-width: 400px;
  padding: 50px;
}
@media (min-width: 700px) {
  .matchmaker__question {
    flex: none;
    font-size: 35px;
    line-height: 48px;
    max-width: none;
  }
}

.matchmaker__answer {
  background-position: center;
  background-size: cover;
  flex: 1;
  justify-content: end;
  line-height: 40px;
  max-width: 400px;
  padding: 40px;
}
@media (min-width: 700px) {
  .matchmaker__answer {
    flex: none;
    max-width: none;
  }
}
.matchmaker__answer::before {
  background: linear-gradient(rgba(18, 19, 33, 0), #121321);
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  content: "";
  height: 40%;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}
.matchmaker__answer + .matchmaker__buttons {
  display: flex;
  justify-content: center;
  width: 100%;
}
@media (min-width: 700px) {
  .matchmaker__answer + .matchmaker__buttons {
    justify-content: stretch;
    width: 500px;
  }
}
.matchmaker__answer + .matchmaker__buttons .matchmaker__button {
  flex: none;
  font-size: 24px;
}
@media (min-width: 700px) {
  .matchmaker__answer + .matchmaker__buttons .matchmaker__button {
    flex: 1;
  }
}
.matchmaker__answer + .matchmaker__buttons .matchmaker__button:not(:first-child) {
  margin-left: 20px;
}

.matchmaker__answer-prefix,
.matchmaker__answer-text {
  color: #ffffff;
  z-index: 2;
}

.matchmaker__answer-prefix {
  font-size: 16px;
  font-weight: 600;
  line-height: 1em;
}
@media (min-width: 700px) {
  .matchmaker__answer-prefix {
    font-size: 20px;
  }
}

.matchmaker__answer-text {
  font-family: var(--font-family-heading);
  font-size: 28px;
  font-weight: 900;
  line-height: 1.3em;
}
@media (min-width: 700px) {
  .matchmaker__answer-text {
    font-size: 35px;
    line-height: 48px;
  }
}

.matchmaker__insert {
  background: #2a57e9;
  color: #ffffff;
  flex: 1;
  max-width: 400px;
  padding: 50px;
}
@media (min-width: 700px) {
  .matchmaker__insert {
    flex: none;
    max-width: none;
  }
}

.matchmaker__insert-image {
  height: 100px;
  width: 100px;
}
.matchmaker__insert-image svg {
  fill: #ffffff;
}

.matchmaker__insert-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4em;
  margin-top: 20px;
}
@media (min-width: 700px) {
  .matchmaker__insert-text {
    font-size: 22px;
  }
}

.matchmaker__popup {
  background: var(--color-green);
  color: var(--color-black);
  height: 250px;
  justify-content: start;
  margin: 0;
  max-width: calc(100% - 2 * var(--page-padding));
  padding: 20px;
  width: 450px;
}
@media (min-width: 700px) {
  .matchmaker__popup {
    padding: 40px 30px;
  }
}
.matchmaker__popup::after {
  display: none;
}
.matchmaker__popup .matchmaker__buttons {
  align-self: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  justify-content: end;
  text-align: center;
}
.matchmaker__popup .matchmaker__buttons .matchmaker__button {
  background: var(--color-orange);
  border: 2px solid var(--color-orange);
  color: #ffffff;
  transition: background-color var(--toggle-transition-time), color var(--toggle-transition-time);
}
.matchmaker__popup .matchmaker__buttons .matchmaker__button:hover {
  background: #ffffff;
  color: var(--color-orange);
}

.matchmaker__popup-text {
  align-self: start;
}

.matchmaker__finish {
  color: #ffffff;
  flex: none;
  padding-bottom: 60px;
  padding-top: 60px;
  position: relative;
  transition: padding var(--results-transition-time), width var(--results-transition-time);
  width: var(--finish-content-width);
}
@media (min-width: 700px) {
  .matchmaker__finish {
    padding-top: 65px;
  }
}
.matchmaker--page-results .matchmaker__finish {
  padding-top: 65px;
  width: 100%;
}
@media (min-width: 700px) {
  .matchmaker--page-results .matchmaker__finish {
    padding-top: 80px;
  }
}

.matchmaker__finish-percentage-prefix {
  color: var(--color-blue-disabled);
  font-family: var(--font-family-heading);
  font-size: 24px;
  line-height: 28px;
  transition: font-size var(--results-transition-time), line-height var(--results-transition-time);
}
@media (min-width: 700px) {
  .matchmaker__finish-percentage-prefix {
    font-size: 26px;
    line-height: 32px;
  }
}
.matchmaker--page-results .matchmaker__finish-percentage-prefix {
  font-size: 12px;
  line-height: 15px;
}
@media (min-width: 700px) {
  .matchmaker--page-results .matchmaker__finish-percentage-prefix {
    font-size: 24px;
    line-height: 32px;
  }
}

.matchmaker__finish-percentage {
  font-family: var(--font-family-heading);
  font-size: 120px;
  line-height: 128px;
  transition: font-size var(--results-transition-time), line-height var(--results-transition-time);
}
@media (min-width: 700px) {
  .matchmaker__finish-percentage {
    font-size: 160px;
    line-height: 150px;
  }
}
.matchmaker--page-results .matchmaker__finish-percentage {
  font-size: 48px;
  line-height: 56px;
}
@media (min-width: 700px) {
  .matchmaker--page-results .matchmaker__finish-percentage {
    font-size: 80px;
    line-height: 96px;
  }
}

.matchmaker__finish-percentage-suffix {
  font-family: var(--font-family-heading);
  font-size: 32px;
  line-height: 38px;
  transition: font-size var(--results-transition-time), line-height var(--results-transition-time);
}
@media (min-width: 700px) {
  .matchmaker__finish-percentage-suffix {
    font-size: 48px;
    line-break: 56px;
  }
}
.matchmaker--page-results .matchmaker__finish-percentage-suffix {
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 700px) {
  .matchmaker--page-results .matchmaker__finish-percentage-suffix {
    font-size: 32px;
    line-height: 40px;
  }
}

.matchmaker .wh-form {
  flex: none;
  opacity: 1;
  transition: opacity var(--results-transition-time);
  width: var(--finish-content-width);
}

.matchmaker--page-results .wh-form {
  opacity: 0;
}

.matchmaker .wh-form__fieldgroup,
.matchmaker .wh-form__buttongroup {
  margin: 0;
  padding: 0;
}

.matchmaker .wh-form__fieldgroup--error {
  background: rgba(255, 255, 255, 0.5);
}

.matchmaker .wh-form__fieldgroup--richtext {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 20px 0 20px;
}
@media (min-width: 700px) {
  .matchmaker .wh-form__fieldgroup--richtext {
    color: #ffffff;
  }
}

.matchmaker .wh-form__fieldgroup--checkbox.wh-form__fieldgroup--nolabel {
  padding: 0;
}
.matchmaker .wh-form__fieldgroup--checkbox.wh-form__fieldgroup--required .wh-form__optionlabel::after {
  display: none;
}
.matchmaker .wh-form__fieldgroup--checkbox .wh-form__optionlabel p {
  color: var(--color-black);
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4em;
  margin: 0;
}
@media (min-width: 700px) {
  .matchmaker .wh-form__fieldgroup--checkbox .wh-form__optionlabel p {
    color: #ffffff;
  }
}
.matchmaker .wh-form__fieldgroup--checkbox .wh-form__optionlabel a {
  color: var(--color-black);
}
@media (min-width: 700px) {
  .matchmaker .wh-form__fieldgroup--checkbox .wh-form__optionlabel a {
    color: #ffffff;
  }
}
.matchmaker .wh-form__fieldgroup--checkbox .wh-form__error {
  margin: 0;
}

.matchmaker .wh-form__fieldgroup--textedit {
  display: flex;
  margin: 20px 0;
}
.matchmaker .wh-form__fieldgroup--textedit input {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 4px;
  color: var(--color-black);
  flex: 1;
  font-family: inherit;
  font-size: 16px;
  padding: 13px 10px;
}

.matchmaker .wh-form__buttongroup {
  margin: 20px 0;
}
.matchmaker .wh-form__buttongroup button {
  background: var(--color-orange);
  border: 2px solid var(--color-orange);
  color: #ffffff;
  justify-content: center;
  margin-left: 0;
  transition: background-color var(--toggle-transition-time), color var(--toggle-transition-time);
  width: 100%;
}
.matchmaker .wh-form__buttongroup button:hover {
  background: #ffffff;
  color: var(--color-orange);
}

.matchmaker__results {
  background: #ffffff;
  color: var(--color-black);
  display: grid;
  flex: none;
  grid-template-columns: var(--page-grid-columns);
  opacity: 1;
  padding: 27px 0;
  position: absolute;
  top: 0;
  transform: translateY(200px);
  transition: transform var(--results-transition-time), opacity var(--results-transition-time);
  width: 100vw;
}
@media (min-width: 700px) {
  .matchmaker__results {
    padding: 40px 0;
    transform: translateY(330px);
  }
}
.matchmaker--page-finish .matchmaker__results {
  opacity: 0;
  transform: translateY(100%);
}

.matchmaker__finish-percentage::after,
.matchmaker__other-percentage::after {
  content: "%";
}

.matchmaker__match {
  display: grid;
  grid-column: text;
  grid-template-areas: "title" "text" "link" "image";
  grid-template-columns: 1fr;
  grid-template-rows: auto;
}
@media (min-width: 700px) {
  .matchmaker__match {
    grid-template-areas: "title image" "text  image" "link  image";
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto 1fr;
  }
}

.matchmaker__match-title {
  color: var(--color-blue);
  font-family: var(--font-family-heading);
  font-size: 32px;
  grid-area: title;
  line-height: 1em;
}
@media (min-width: 700px) {
  .matchmaker__match-title {
    font-size: 48px;
  }
}

.matchmaker__match-text {
  grid-area: text;
  margin-top: 12px;
}
.matchmaker__match-text > :first-child {
  margin-top: 0;
}
.matchmaker__match-text p {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 12px 0 0 0;
}
@media (min-width: 700px) {
  .matchmaker__match-text p {
    font-size: 24px;
    line-height: 32px;
    margin-top: 16px;
  }
}

.matchmaker__match-link {
  justify-self: start;
  margin: 24px 0;
  grid-area: link;
}
@media (min-width: 700px) {
  .matchmaker__match-link {
    margin-bottom: 0;
  }
}
.matchmaker__match .matchmaker__match-link {
  background: #ffffff;
  border-color: var(--color-blue);
  color: var(--color-blue);
}
.matchmaker__match .matchmaker__match-link:hover {
  background: var(--color-blue);
  color: #ffffff;
}

.matchmaker__match-image {
  background-size: cover;
  background-position: center;
  grid-area: image;
  height: 220px;
  width: 100%;
}
@media (min-width: 700px) {
  .matchmaker__match-image {
    height: 300px;
    margin-left: 100px;
    width: 400px;
  }
}

.matchmaker__others {
  grid-column: text;
  margin: 24px 0;
  padding: 32px 0;
  position: relative;
}
@media (min-width: 700px) {
  .matchmaker__others {
    margin: 40px 0 32px;
    padding: 64px 0;
  }
}
.matchmaker__others::after {
  background-color: var(--color-blue-lighter);
  bottom: 0;
  content: "";
  display: block;
  left: calc(0px - var(--content-padding));
  position: absolute;
  right: calc(0px - var(--content-padding));
  top: 0;
  z-index: -1;
}

.matchmaker__others-header {
  color: var(--color-blue);
  font-family: var(--font-family-heading);
  font-size: 28px;
  line-height: 40px;
}
@media (min-width: 700px) {
  .matchmaker__others-header {
    font-size: 48px;
  }
}

.matchmaker__others-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
}
@media (min-width: 700px) {
  .matchmaker__others-container {
    align-items: stretch;
    flex-direction: row;
    gap: 24px;
    justify-content: stretch;
    margin-top: 32px;
  }
}

.matchmaker__other {
  background: #ffffff;
  flex: 1;
  z-index: 0;
}
@media (min-width: 700px) {
  .matchmaker__other {
    display: flex;
    flex-direction: column;
  }
}

.matchmaker__other-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 16px;
}
@media (min-width: 700px) {
  .matchmaker__other-header {
    flex: none;
    padding: 32px;
  }
}

.matchmaker__other-percentage {
  --line-width: 3px;
  --line-percentage: 0%;
  align-items: center;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  flex: none;
  font-size: 0.8em;
  height: 40px;
  justify-content: center;
  margin: var(--line-width);
  position: relative;
  width: 40px;
}
.matchmaker__other-percentage::before {
  background: conic-gradient(var(--color-blue) var(--line-percentage), var(--color-blue-light) var(--line-percentage));
  border-radius: 50%;
  bottom: calc(0px - var(--line-width));
  content: "";
  left: calc(0px - var(--line-width));
  position: absolute;
  right: calc(0px - var(--line-width));
  top: calc(0px - var(--line-width));
  z-index: -1;
}

.matchmaker__other-title {
  font-weight: 600;
  flex: 1;
  margin-left: 12px;
}
@media (min-width: 700px) {
  .matchmaker__other-title {
    color: var(--color-blue);
    font-family: var(--font-family-heading);
  }
}

.matchmaker__other-toggle {
  cursor: pointer;
  flex: none;
}
.matchmaker__other-toggle .fas {
  transition: transform var(--toggle-transition-time);
}
.matchmaker__other--active .matchmaker__other-toggle .fas {
  transform: rotate(-180deg);
}
@media (min-width: 700px) {
  .matchmaker__other-toggle {
    display: none;
  }
}

.matchmaker__other-contents {
  display: flex;
  flex-direction: column;
  height: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0 24px 0 16px;
  pointer-events: none;
  transition: height var(--toggle-transition-time), padding var(--toggle-transition-time), opacity var(--toggle-transition-time);
}
.matchmaker__other-contents--measure {
  height: auto;
  padding-bottom: 27px;
  padding-top: 6px;
}
.matchmaker__other--active .matchmaker__other-contents {
  height: auto;
  opacity: 1;
  padding-bottom: 27px;
  padding-top: 6px;
  pointer-events: auto;
}
@media (min-width: 700px) {
  .matchmaker__other-contents {
    flex: 1;
    opacity: 1;
    padding: 0 32px 32px;
    pointer-events: auto;
  }
}
.matchmaker__other-contents p {
  flex: none;
  margin: 2px 0 0;
}

.matchmaker__other-link {
  align-items: end;
  color: var(--color-blue);
  display: flex;
  flex: 1;
  font-weight: 600;
  justify-content: end;
  margin-top: 32px;
}
.matchmaker__other-link a {
  text-decoration: none;
}
.matchmaker__other-link a .fas {
  margin-left: 12px;
}

.matchmaker__ctas {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 12px;
  grid-column: text;
  justify-content: stretch;
}
@media (min-width: 700px) {
  .matchmaker__ctas {
    flex-direction: row;
    gap: 0;
  }
}

.matchmaker__cta {
  --cta-arrow-size: 32px;
  align-items: start;
  background: var(--color-green);
  border-radius: 12px;
  color: var(--color-black);
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  padding: 24px calc(48px + var(--cta-arrow-size)) 24px 24px;
  position: relative;
  text-decoration: none;
}
@media (min-width: 700px) {
  .matchmaker__cta {
    border-radius: 0;
    padding: 30px calc(80px + var(--cta-arrow-size)) 30px 40px;
  }
  .matchmaker__cta:first-child {
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
  }
  .matchmaker__cta:last-child {
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
  }
  .matchmaker__cta:not(:first-child)::after {
    background: var(--color-blue);
    bottom: 24px;
    content: "";
    display: block;
    left: -1px;
    opacity: 0.1;
    position: absolute;
    top: 24px;
    width: 2px;
  }
}

.matchmaker__cta-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
@media (min-width: 700px) {
  .matchmaker__cta-title {
    font-size: 24px;
    line-height: 28px;
  }
}

.matchmaker__cta-subtitle {
  font-size: 16px;
  line-height: 24px;
}

.matchmaker__cta-arrow {
  align-items: center;
  border: 2px solid var(--color-black);
  border-radius: 50%;
  display: flex;
  height: var(--cta-arrow-size);
  justify-content: center;
  position: absolute;
  right: 40px;
  top: calc(50% - var(--cta-arrow-size) / 2);
  transition: background-color var(--toggle-transition-time), color var(--toggle-transition-time);
  width: var(--cta-arrow-size);
}
@media (min-width: 700px) {
  .matchmaker__cta-arrow {
    --cta-arrow-size: 48px;
  }
}
.matchmaker__cta:hover .matchmaker__cta-arrow {
  background: var(--color-black);
  color: #ffffff;
}

.matchmaker__buttonhint {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.01em;
  margin-bottom: 4px;
  opacity: 0.4;
}

.matchmaker__button {
  align-items: center;
  background: var(--color-blue);
  border: 2px solid #ffffff;
  border-radius: 24px;
  color: #ffffff;
  display: inline-flex;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  padding: 0 40px;
  text-align: center;
  text-decoration: none;
  transition: background-color var(--toggle-transition-time), color var(--toggle-transition-time);
}
@media (min-width: 700px) {
  .matchmaker__button {
    font-size: 16px;
  }
}
.matchmaker__button--hover, .matchmaker__button:hover {
  background: #ffffff;
  color: var(--color-blue);
}
.matchmaker__button i.fal, .matchmaker__button i.fas {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  height: 30px;
  width: 30px;
}