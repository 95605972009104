/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-opendays {
  color: #fff;
}
.widget-opendays h2 {
  color: inherit;
  margin-bottom: var(--spacing-sm);
}
.widget-opendays h3 {
  color: inherit;
  margin-bottom: 0;
}
.widget-opendays h4 {
  color: inherit;
  margin-top: var(--spacing-sm);
  margin-bottom: var(--spacing-xxxs);
}
.widget-opendays__inner {
  display: flex;
  align-items: flex-start;
  background-color: var(--color-blue);
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
  position: relative;
  z-index: 0;
}
.widget-opendays__tiocircle {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 438px;
  max-width: 50%;
  height: auto;
}
.widget-opendays__mainlink {
  flex: none;
}
.widget-opendays__locations {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.widget-opendays__locationlist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  flex: none;
  width: 224px;
  margin-right: var(--spacing-lg);
}
.widget-opendays__locationlist > li {
  margin-bottom: var(--spacing-xxxs);
}
.widget-opendays__locationbtn {
  width: 100%;
}
.widget-opendays__panels {
  flex: 1;
}
.widget-opendays__locationdates {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.widget-opendays__locationdates li {
  border-top: 1px solid #fff;
  width: 47.5%;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  margin-top: var(--spacing-sm);
}
.widget-opendays__locationdates h4, .widget-opendays__locationdates .button {
  flex: none;
}
.widget-opendays__locationdates p {
  flex: 1;
  font-size: clamp(14px, 1.6dvw, 16px);
  line-height: 170%;
}
.widget-opendays__locationdates .button {
  margin-top: var(--spacing-sm);
}
@media (max-width: 850px) {
  .widget-opendays__locations {
    display: block;
  }
  .widget-opendays__locationlist {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    margin-right: 0;
    margin-bottom: var(--spacing-sm);
    margin-left: -8px;
  }
  .widget-opendays__locationlist > li {
    flex: none;
    width: calc(33.33333% - 8px);
    margin-left: var(--spacing-xxxs);
  }
}
@media (max-width: 650px) {
  .widget-opendays__locationlist > li {
    width: calc(50% - 8px);
  }
}
@media (max-width: 600px) {
  .widget-opendays__locationdates {
    display: block;
  }
  .widget-opendays__locationdates > li {
    width: auto;
  }
}