/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-iframe--hidden {
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
  min-height: 0;
}