/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-foldablecontent__toggle {
  display: block;
  position: relative;
  color: var(--color-blue);
  font-weight: 700;
}
.widget-foldablecontent__toggle::before {
  content: "";
  display: block;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 50px;
  position: absolute;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgb(255, 255, 255) 95%, rgb(255, 255, 255) 100%);
}
.widget-foldablecontent__toggle i {
  display: inline-block;
  padding-right: 10px;
}
.widget-foldablecontent__toggle__more {
  display: block;
}
.widget-foldablecontent__toggle__less {
  display: none;
}
.wh-details--open .widget-foldablecontent__toggle__less {
  display: block;
}
.wh-details--open .widget-foldablecontent__toggle__more {
  display: none;
}
.widget-foldablecontent--hidetoggle .widget-foldablecontent__toggle {
  display: none;
}