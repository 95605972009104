/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-locationsmap {
  background-color: var(--color-blue);
  color: var(--color-green);
  padding: 50px 0;
}
.widget-locationsmap h2 {
  color: inherit;
}
.widget-locationsmap__inner {
  position: relative;
}
.widget-locationsmap__title {
  position: absolute;
  margin-bottom: 24px;
}
.widget-locationsmap__map {
  width: 460px;
  height: 520px;
  margin: 0 auto;
  max-width: 100%;
}
.widget-locationsmap__map img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}
@media (max-width: 600px) {
  .widget-locationsmap__title {
    position: static;
  }
}