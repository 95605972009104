/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.opendaysrightblock {
  background-color: var(--color-blue);
  color: #fff;
  float: right;
  padding: 32px 32px 20px;
  width: 458px;
  margin-left: 32px;
  margin-bottom: 32px;
  margin-right: calc(50vw - 573px);
  position: relative;
  z-index: 1;
}
@media (max-width: 1208px) {
  .opendaysrightblock {
    margin-right: 32px;
  }
}
.opendaysrightblock a, .opendaysrightblock h4 {
  color: inherit;
}
.opendaysrightblock a {
  text-underline-position: under;
}
.opendaysrightblock h4 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
.opendaysrightblock__button {
  text-align: center;
  margin-bottom: 32px;
}
.opendaysrightblock__opendays {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: -24px;
}
.opendaysrightblock__openday {
  flex: 1 1 50%;
  max-width: 50%;
  padding-left: 24px;
  margin-bottom: 24px;
}
.opendaysrightblock__openday > * {
  display: block;
}
.opendaysrightblock__openday__time {
  display: flex;
  align-items: flex-start;
  margin-top: 7px;
}
.opendaysrightblock__openday__time i {
  flex: none;
  position: relative;
  top: 1px;
  font-size: 20px;
  display: inline-block;
  padding-right: 5px;
}
.opendaysrightblock__footnote {
  text-align: right;
  font-size: 90%;
}
.opendaysrightblock__footnote i {
  font-size: 16px;
  display: inline-block;
  padding-right: 5px;
}
@media (max-width: 880px) {
  .opendaysrightblock {
    float: none;
    width: auto;
  }
}
.widget-twocols__columns .opendaysrightblock {
  float: none;
  margin-left: auto;
  margin-right: auto;
  width: auto;
}