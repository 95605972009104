/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-studysubjects {
  position: relative;
  z-index: 0;
}
.widget-studysubjects__dialogs {
  display: none;
}
.widget-studysubjects__table {
  border-collapse: collapse;
  padding: 0;
  margin: 0;
  width: 100%;
}
.widget-studysubjects th, .widget-studysubjects__compactblock__header {
  background-color: var(--color-blue);
  color: #fff;
  font-weight: bold;
  padding: 8px 15px;
}
.widget-studysubjects__compactblock__header {
  border-radius: 3px;
  margin-top: 30px;
}
.widget-studysubjects th, .widget-studysubjects td {
  width: 50%;
  vertical-align: top;
  text-align: left;
}
.widget-studysubjects th:first-child {
  border-radius: 3px 0 0 3px;
}
.widget-studysubjects th:last-child {
  border-radius: 0 3px 3px 0;
}
.widget-studysubjects tr:nth-child(2n+1) > td,
.widget-studysubjects .widget-studysubjects__compactblock .subjectblock__line:nth-child(2n+1) {
  background-color: var(--color-blue-lighter);
}
.widget-studysubjects tr:last-child > td,
.widget-studysubjects .widget-studysubjects__compactblock .subjectblock__line:last-child {
  border-bottom: 2px solid var(--color-blue-lighter);
}
.widget-studysubjects .subjectblock__line {
  position: relative;
  padding: 8px 130px 8px 15px;
  cursor: pointer;
  outline: 0 none;
}
.widget-studysubjects .subjectblock__line::after {
  content: "i";
  width: 28px;
  height: 28px;
  background-color: var(--color-green);
  color: var(--color-black);
  display: block;
  position: absolute;
  top: 6px;
  right: 23px;
  text-align: center;
  border-radius: 100%;
  line-height: 25px;
  font-weight: bold;
  border: 2px solid #fff;
  transition: all 0.2s;
}
.widget-studysubjects .subjectblock__line > .credits {
  display: inline-block;
  position: absolute;
  right: 90px;
  top: 8px;
}
.widget-studysubjects .subjectblock__line:hover::after {
  border-color: var(--color-green) !important;
}
.widget-studysubjects tr:nth-child(2n+1) > td .subjectblock__line::after,
.widget-studysubjects .widget-studysubjects__compactblock .subjectblock__line:nth-child(2n+1)::after {
  border-color: var(--color-blue-lighter);
}
.widget-studysubjects--unit_ec .credits:after {
  content: " EC";
}
.widget-studysubjects--unit_gl .credits:after {
  content: " GL";
}
.widget-studysubjects .widget-studysubjects__compactblock {
  display: none;
}
@media (max-width: 600px) {
  .widget-studysubjects__table {
    display: none;
  }
  .widget-studysubjects .widget-studysubjects__compactblock {
    display: block;
  }
}
@media (max-width: 400px) {
  .widget-studysubjects .subjectblock__line {
    padding-left: 10px;
    padding-right: 90px;
  }
  .widget-studysubjects .subjectblock__line::after {
    right: 10px;
  }
  .widget-studysubjects .subjectblock__line > .credits {
    right: 48px;
  }
}