/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.rpc__busyoverlay {
  display: none;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;
}
html.dompack--busymodal .rpc__busyoverlay {
  display: flex;
}

.busyindicator {
  flex: none;
  font-size: 70px;
  width: 70px;
  height: 70px;
  position: relative;
}

.tio-dialog {
  min-height: 100px;
  max-height: calc(100vh - 90px);
  overflow: hidden;
  overflow-y: auto;
  padding: 32px;
  max-width: 936px;
  /*
    pagepopup for loading iframe content and places close btn above content frame
  */
}
.tio-dialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tio-dialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.tio-dialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}
.tio-dialog__modalbg {
  background-color: rgba(0, 0, 0, 0.75);
}
.tio-dialog__content > *:last-child {
  margin-bottom: 0;
}
.tio-dialog .tio-dialog__content__scrollarea {
  overflow: hidden;
  overflow-y: auto;
  max-height: calc(100vh - 340px);
}
.tio-dialog .wh-form__prefillarea {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
}
.tio-dialog .wh-form__prefillarea:hover {
  opacity: 1;
}
.tio-dialog .wh-form__page > *:first-child {
  padding-right: 50px;
}
@media (max-width: 900px) {
  .tio-dialog {
    max-height: calc(100vh - 10vw);
    max-width: 90vw;
  }
}
.tio-dialog__holder {
  border-radius: 6px;
  overflow: visible;
  background: #ffffff;
  box-shadow: 0 15px 30px 0 rgba(24, 31, 75, 0.2);
  transition: transform 300ms, opacity 300ms;
  opacity: 0;
  transform: scale(0.01, 0.01);
}
.tio-dialog__holder--aftershow {
  opacity: 1;
  transform: scale(1, 1);
}
@media (max-width: 630px) {
  .tio-dialog {
    max-height: 100vh;
    max-width: 100vw;
  }
  .tio-dialog__holder {
    border-radius: 0;
  }
}
.tio-dialog__modalbg {
  z-index: 1001;
}
.tio-dialog__close {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 5px;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}
.tio-dialog--videopopup .tio-dialog__close {
  color: #fff;
  top: -40px;
  right: -10px;
}
.tio-dialog--videopopup .tio-dialog {
  background-color: transparent;
  width: calc(100vw - 60px);
  padding: 0;
}
.tio-dialog--videopopup .tio-dialog .wh-video img, .tio-dialog--videopopup .tio-dialog .wh-video iframe {
  border-radius: 0 !important;
}
@media (max-width: 750px) {
  .tio-dialog--videopopup .tio-dialog {
    width: 92vw;
  }
}
.tio-dialog--pagepopup.tio-dialog__modalbg {
  background-color: rgba(0, 0, 0, 0.75);
}
.tio-dialog--pagepopup .tio-dialog__close {
  color: #fff;
  top: -40px;
  right: -10px;
}
.tio-dialog--pagepopup .tio-dialog {
  background-color: transparent;
  width: calc(100vw - 60px);
  height: calc(100vh - 100px);
  padding: 0;
}
@media (max-width: 750px) {
  .tio-dialog--pagepopup .tio-dialog {
    width: 92vw;
  }
}