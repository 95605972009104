/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-ctabanner__inner {
  color: #fff;
  background-color: var(--color-blue);
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.widget-ctabanner__tiocircle {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 204px;
  height: 215px;
}
.widget-ctabanner__content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
}
.widget-ctabanner__title {
  flex: 1;
  color: inherit;
  margin-right: 32px;
}
@media (max-width: 600px) {
  .widget-ctabanner__content {
    flex-flow: column;
  }
  .widget-ctabanner__title {
    margin-right: 0;
    margin-bottom: 24px;
  }
}