/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.wh-form__activepagetitle {
  color: var(--color-blue);
  font-size: 28px;
  font-weight: 700;
  padding-top: 10px;
}
@media (max-width: 600px) {
  .wh-form__activepagetitle {
    font-size: 18px;
  }
}

.wh-form__activepagetitle.finished {
  text-align: right;
}

.wh-form__pagination {
  list-style-type: none;
  margin: 0;
  padding: 20px 0;
  display: flex;
  flex-flow: row;
  color: var(--color-blue);
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  position: relative;
  justify-content: flex-start;
}
.wh-form__pagination::before {
  content: "";
  border-top: 2px solid var(--color-blue);
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.wh-form__pagination::after {
  content: "";
  display: block;
  background-color: var(--color-blue);
  position: absolute;
  top: 50%;
  margin-top: -3px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  right: 0;
  z-index: -1;
}
.wh-form__pagination > li.active {
  flex-grow: 100;
}
.wh-form__pagination > li {
  position: relative;
  display: flex;
  transition: all 0.5s;
  flex: 0 0 auto;
  cursor: default;
}
.wh-form__pagination > li + li {
  margin-left: 15px;
}
.wh-form__pagination > li > span {
  display: flex;
  border-radius: 50%;
  min-width: 46px;
  height: 46px;
  width: 46px;
  border: 2px solid var(--color-blue);
  justify-content: center;
  align-items: center;
  background-color: #fff;
  transition: color 0.3s, background-color 0.3s;
  position: relative;
}
.wh-form__pagination > li.active + li {
  cursor: pointer;
}
.wh-form__pagination > li.previous > span {
  background-color: #E5F3FA;
  cursor: pointer;
}
.wh-form__pagination > li.previous > span::after {
  background-color: inherit;
  content: "\f00c";
  display: block;
  position: absolute;
  text-align: center;
  font-size: 16px;
  background-color: #E5F3FA;
  padding-top: 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  font-family: "Font Awesome 5 Pro";
}
.wh-form__pagination > li.active > span {
  background-color: var(--color-blue);
  color: #fff;
}
.wh-form__pagination > li:last-child {
  justify-content: flex-end;
}
.wh-form__pagination.finished > li.previous > span {
  border-color: var(--color-green);
  color: var(--color-green);
}
.wh-form__pagination.finished > li.previous > span::after {
  background-color: #fff;
}
.wh-form__pagination.finished > li.active > span {
  border-color: var(--color-green);
  background-color: var(--color-green);
  cursor: default;
}
.wh-form__pagination.finished > li.active > span:not(.fa)::after {
  background-color: inherit;
  content: "\f00c";
  display: block;
  position: absolute;
  text-align: center;
  font-size: 16px;
  background-color: var(--color-green);
  padding-top: 6px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  font-family: "Font Awesome 5 Pro";
}
.wh-form__pagination.finished > li.previous > span {
  cursor: default;
}
@media (max-width: 750px) {
  .wh-form__pagination {
    font-size: 16px;
    line-height: 20px;
    padding: 5px 0;
  }
  .wh-form__pagination > li + li {
    margin-left: 10px;
  }
  .wh-form__pagination > li > span {
    min-width: 34px;
    height: 34px;
    width: 34px;
  }
  .wh-form__pagination > li.previous > span::after {
    font-size: 14px;
    padding-top: 6px;
  }
}