/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.slideshow {
  outline: none;
}
.slideshow__container {
  max-width: 100%;
  overflow: hidden;
}
.slideshow__slides {
  position: relative;
  display: flex;
  justify-content: flex-start;
  transition: transform 300ms;
}
.slideshow__slide {
  flex: none;
  width: 100%;
}
.slideshow__jumpbuttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-md) 0 0;
}
.slideshow__jumpbutton, .slideshow__previous, .slideshow__next {
  padding: 0;
  display: inline-block;
  outline: none;
  height: 16px;
  width: 16px;
  cursor: pointer;
  border: 0 none;
  background-color: transparent;
  color: var(--color-blue);
  font-size: 16px;
  margin: 0 5px;
}
.slideshow__previous {
  margin-right: 32px;
}
.slideshow__previous:hover, .slideshow__previous:focus {
  text-shadow: 0 0 1px var(--color-blue);
}
.slideshow__next {
  margin-left: 32px;
}
.slideshow__next:hover, .slideshow__next:focus {
  text-shadow: 0 0 1px var(--color-blue);
}
.slideshow--firstslide .slideshow__previous, .slideshow--lastslide .slideshow__next {
  opacity: 0.4;
  cursor: default;
}
.slideshow__jumpbutton {
  border-radius: 50%;
  border: 2px solid var(--color-grey-lines);
  transition: border-color 300ms;
}
.slideshow__jumpbutton:hover, .slideshow__jumpbutton:focus, .slideshow__jumpbutton--active {
  border-width: 5px;
  border-color: var(--color-blue);
}