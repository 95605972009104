/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-foldableusps + .widget-foldableusps {
  margin-top: -32px;
  padding-top: 0;
}
h2 + .widget-foldableusps__description {
  margin-top: -10px;
}
h2 + .widget-foldableusps__description > *:last-child {
  margin-bottom: clamp(4px, 4.5dvw, 45px);
}
.widget-foldableusps__usps {
  display: block;
  margin-left: -24px;
}
.widget-foldableusps__block {
  padding: 24px;
  background-color: #fff;
  margin-left: 24px;
  background-color: var(--color-blue-lighter);
}
.widget-foldableusps__item ~ .widget-foldableusps__item {
  margin-top: 8px;
  border-top: 1px solid var(--color-blue-lighter-dark);
}
.widget-foldableusps__item__icon {
  flex: none;
  width: 32px;
  fill: var(--color-blue);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--color-blue);
}
.widget-foldableusps__item__icon > svg {
  max-width: 90%;
  height: auto;
}
.widget-foldableusps .widget-foldableusps__item--withicon {
  position: relative;
  min-height: 40px;
}
.widget-foldableusps .widget-foldableusps__item--withicon > .widget-foldableusps__item__icon {
  position: absolute;
  left: 0;
  top: 12px;
}
.widget-foldableusps .widget-foldableusps__item--withicon > .widget-foldableusps__item__title, .widget-foldableusps .widget-foldableusps__item--withicon > .widget-foldableusps__item__description {
  padding-left: 47px;
}
.widget-foldableusps__item .wh-details__summary {
  display: flex;
  align-items: center;
}
.widget-foldableusps__item .wh-details__summary .widget-foldableusps__item__icon {
  margin-top: 5px;
  margin-right: 15px;
}
.widget-foldableusps__item--withicon .wh-details__container__inner {
  padding-left: 47px;
}
.widget-foldableusps__item__title {
  font-weight: 600;
  position: relative;
  padding: 10px 45px 5px 0;
}
.widget-foldableusps__item .wh-details__summary::before {
  position: absolute;
  display: block !important;
  right: 0;
  top: calc(50% + 4px);
  transform: translateY(-50%);
  font-size: 16px;
  font-family: "Font Awesome 5 Pro" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  color: var(--color-blue);
  content: "\f078";
}
.widget-foldableusps__item__description {
  font-size: clamp(14px, 1.6dvw, 16px);
  font-weight: 400;
  line-height: 150%;
  padding-bottom: 0;
}
.widget-foldableusps__item.wh-details--open > .wh-details__summary::before {
  content: "\f077";
}
@media (max-width: 950px) {
  .widget-foldableusps--multiple {
    padding-bottom: 0;
  }
  .widget-foldableusps__block {
    position: relative;
    margin-bottom: 4px;
    min-height: 0 !important;
  }
  .widget-foldableusps__block__title {
    padding-right: 30px;
    margin-bottom: 0;
  }
  .widget-foldableusps__block__title::before {
    position: absolute;
    display: block !important;
    right: 0;
    top: 50%;
    font-weight: 700;
    transform: translateY(-50%);
    font-size: 24px;
    font-family: "Font Awesome 5 Pro" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    color: var(--color-blue);
    content: "\f055";
  }
  .widget-foldableusps__block .wh-details__container__inner {
    padding-top: 10px;
  }
  .wh-details--open > .widget-foldableusps__block__title::before {
    content: "\f056";
  }
  .widget-foldableusps__block + .widget-foldableusps__block::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 24px;
    right: 24px;
    border-top: 1px solid var(--color-blue-lighter);
  }
  .widget-foldableusps--multiple {
    padding-bottom: 0;
  }
  .widget-foldableusps--multiple .widget-foldableusps__block:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .widget-foldableusps + .widget-foldableusps .widget-foldableusps__block:first-child {
    border-top: 1px solid var(--color-blue-lighter);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
@media (min-width: 951px) {
  .widget-foldableusps__usps {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .widget-foldableusps__block {
    flex: 1 1 auto;
    flex-basis: calc(33.3333% - 14px);
    max-width: calc(33.3333% - 14px);
  }
  .widget-foldableusps__block__mobilefoldcontainer {
    overflow: visible;
    height: auto;
  }
}