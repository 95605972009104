/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-admissionwizard__container {
  color: #fff;
  position: relative;
  z-index: 0;
}
.widget-admissionwizard .line {
  margin-left: -15px;
  display: none;
  overflow: hidden;
  transition: height 0.4s, opacity 0.4s;
}
.widget-admissionwizard .line.active, .widget-admissionwizard .line.firstline {
  display: block;
}
.widget-admissionwizard .line.firstline {
  font-weight: 700;
  font-size: 18px;
}
.widget-admissionwizard .line.alignright {
  text-align: right;
}
.widget-admissionwizard .line::after {
  clear: both;
  content: "";
  display: block;
}
.widget-admissionwizard .block {
  font-weight: bold;
  margin-bottom: 15px;
  vertical-align: top;
  display: inline-block;
  padding-left: 15px;
  min-height: 100%;
}
.widget-admissionwizard .block .inner {
  display: flex;
  align-items: center;
  background-color: var(--color-blue);
  padding: 10px 20px;
  text-align: left;
  min-height: 86px;
  line-height: 22px;
  position: relative;
  border-radius: 3px;
  transition: color 0.3s;
}
.widget-admissionwizard .block .inner a {
  color: inherit;
  text-decoration: underline;
  display: inline;
}
.widget-admissionwizard .block .inner > .arrow {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -6px;
}
.widget-admissionwizard .block a.inner {
  color: inherit;
  text-decoration: none;
}
.widget-admissionwizard .block.centertext > .inner {
  justify-content: center;
}
.widget-admissionwizard .block[data-next] > .inner {
  cursor: pointer;
}
.widget-admissionwizard .block .arrow-below {
  color: var(--color-blue);
  text-align: center;
  display: none;
  width: 100%;
  position: relative;
  top: 10px;
}
.widget-admissionwizard .block.active > .arrow-below {
  display: block;
}
.widget-admissionwizard .block.width20 {
  width: 20%;
}
.widget-admissionwizard .block.width25 {
  width: 25%;
}
.widget-admissionwizard .block.width30 {
  width: 30%;
}
.widget-admissionwizard .block.width40 {
  width: 40%;
}
.widget-admissionwizard .block.width60 {
  width: 60%;
}
.widget-admissionwizard .block.width50 {
  width: 50%;
}
.widget-admissionwizard .block.width100 {
  width: 100%;
}
.widget-admissionwizard .green > .inner {
  background-color: var(--color-green);
  padding: 20px;
  color: var(--color-black) !important;
}
.widget-admissionwizard .selected .block[data-next]:not(.active) > .inner {
  background-color: var(--color-blue-disabled);
}
@media (max-width: 750px) {
  .widget-admissionwizard .block {
    padding-left: 10px;
    margin-bottom: 10px;
  }
  .widget-admissionwizard .block > .inner {
    padding: 10px;
    font-size: 15px;
  }
  .widget-admissionwizard .line {
    margin-left: -10px;
  }
  .widget-admissionwizard .firstline .block > .inner {
    padding-right: 30px;
  }
  .widget-admissionwizard .inner > .arrow {
    right: 10px;
  }
}
@media (max-width: 500px) {
  .widget-admissionwizard {
    display: none;
  }
}