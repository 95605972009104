/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-innumbers__numbers {
  display: flex;
  justify-content: space-between;
  margin-left: -24px;
}
.widget-innumbers__number {
  margin-left: 24px;
  text-align: center;
  flex: 1 1 33.333%;
  max-width: calc(33.333% - 16px);
}
.widget-innumbers__number__title {
  color: var(--color-blue);
}
.widget-innumbers__number__description {
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  max-width: 210px;
}
@media (max-width: 750px) {
  .widget-innumbers__numbers {
    margin-left: 0;
    flex-flow: column;
  }
  .widget-innumbers__number {
    margin-left: 0;
    text-align: left;
  }
  .widget-innumbers__number + .widget-innumbers__number {
    margin-top: 24px;
  }
}