/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.header-media {
  position: relative;
  z-index: 6;
  border-bottom: clamp(28px, 4.8dvw, 48px) solid var(--color-blue);
}
.header-media.headerwithinset {
  border-bottom: 0 none;
}
@media (max-width: 1150px) {
  .header-media:has(> .header-mobilectabar) {
    border-bottom: 0 none;
  }
}
.header-media__inner {
  position: relative;
  overflow: hidden;
}
.header-media > .header-media__images::before {
  content: "";
  position: absolute;
  top: -70px;
  left: 0;
  right: 0;
  z-index: -1;
  background-color: var(--color-blue);
  height: calc(100% + 70px);
}
.header-media__contents {
  position: relative;
  z-index: 0;
  max-width: 100%;
  color: #fff;
  max-height: 60dvh;
  min-height: 400px;
  height: 56.25dvw;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  overflow: hidden;
}
@media (max-width: 850px) {
  .header-media__contents {
    min-height: 478px;
  }
}
@media (max-width: 478px) {
  .header-media__contents {
    min-height: 100dvw;
  }
}
@media (max-width: 750px) {
  .header-media.headerwithinset .header-media__contents {
    flex-flow: column;
    max-height: none;
    height: auto;
  }
}
.header-media__background {
  background-color: var(--color-blue);
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
html.corporatehome .header-media__background, html.corporatehome .header-media__contents {
  max-height: 620px;
}
@media (max-width: 950px) {
  .header-media-wrapper.headerwithinset .header-media__contents {
    flex-flow: column;
    width: 100%;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .header-media-wrapper.headerwithinset .header-media__contents .header-media__text {
    align-items: flex-start;
  }
}
.header-media__mask {
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: clamp(16px, 2.8dvw, 32px);
}
.header-media__mask > svg {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
@media (max-width: 1150px) {
  .header-media__mask {
    display: block;
  }
}
@media (max-width: 1150px) {
  .header-media__background, .header-media__contents {
    max-height: none;
  }
  .header-media.withctas .header-media__contents {
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.header-media__images, .header-media__image, .header-media__video {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.header-media__awardimage {
  position: absolute;
  right: 110px;
  bottom: 0;
}
@media (max-width: 1150px) {
  .header-media__awardimage {
    right: 20px;
    bottom: auto;
    top: 20px;
    max-width: 20dvw;
    height: auto;
  }
}
.header-media__imageinset {
  flex: none;
  position: relative;
  left: clamp(-125px, -9dvw, -50px);
  height: 100%;
  display: flex;
  z-index: -1;
  align-items: flex-end;
  justify-content: center;
  width: clamp(400px, 42dvw, 530px);
  max-width: 100%;
}
.header-media__imageinset img {
  max-height: 100%;
  width: auto;
  max-width: 100%;
  object-position: center bottom;
  object-fit: contain;
}
@media (max-width: 750px) {
  .header-media__imageinset {
    left: 0;
    align-self: center;
    height: auto;
    width: 100%;
  }
  .header-media__imageinset img {
    max-height: 75dvw;
  }
}
.header-media__imageinset::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  background-color: var(--color-green);
  width: 1000px;
  max-width: 200dvw;
  aspect-ratio: 1/1;
  border-radius: 50%;
  top: 100%;
  margin-top: clamp(-75px, -7.5dvw, -40px);
}
.header-media.withctas .header-media__awardimage {
  right: 455px;
}
@media (max-width: 1150px) {
  .header-media.withctas .header-media__awardimage {
    right: 20px;
  }
}
@media (min-width: 1151px) {
  .header-media__images, .header-media__video {
    width: calc(100% - 110px);
  }
  .header-media.withctas .header-media__images, .header-media.withctas .header-media__video {
    width: calc(100% - 455px);
  }
  .header-media.headerwithinset .header-media__images, .header-media.headerwithinset .header-media__video {
    width: 100%;
  }
}
.header-media__video--playing + .header-media__images {
  display: none;
}
.header-media__video {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-media__video .wh-video {
  flex: none;
  aspect-ratio: 16/9;
  min-width: 100%;
  min-height: 100%;
  pointer-events: none;
}
.header-media__video .wh-video iframe {
  border-radius: 0;
}
.header-media__image {
  transition: opacity 3s;
  opacity: 0;
  z-index: 1;
}
.header-media__image--active {
  opacity: 1;
}
.header-media__tags {
  margin: 5px 0 10px;
  margin-left: -10px;
  font-size: clamp(8px, 1vw, 10px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.header-media__tags span {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
  color: var(--color-blue);
  font-weight: 600;
  border-radius: 32px;
  padding: 1px 15px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #FFF;
}
.header-media--ctabarbelow .header-media__tags {
  margin-bottom: 30px;
}
.header-media__images::after, .header-media__video::after {
  display: block;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 936px;
  height: 100%;
  z-index: 2;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
}
.header-media__rightmask {
  position: absolute;
  right: -3px;
  top: -2px;
  height: calc(100% + 4px);
  width: auto;
  z-index: 3;
}
@media (max-width: 1150px) {
  .header-media__rightmask {
    display: none;
  }
}
.header-media__images--stop .header-media__image:not(.header-media__image--active) {
  display: none;
}
.header-media__text {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}
.header-media__text__inner {
  padding: 32px clamp(24px, 5dvw, 42px);
}
@media (min-width: 601px) {
  .header-media__text__inner {
    padding-left: 93px;
  }
}
.header-media__text__meta {
  margin-left: -10px;
}
.header-media__text__meta button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
}
.header-media__text__meta button, .header-media__text__meta span {
  display: inline-block;
  line-height: 32px;
  margin-bottom: 15px;
  margin-left: 10px;
}
.header-media__text__meta button {
  display: inline-block;
  font-style: normal;
  border-radius: 16px;
  padding: 0 12px;
  background-color: var(--color-blue-dark);
}
@media (max-width: 750px) {
  .header-media__text__meta button {
    color: #fff;
  }
}
.header-media__text__inner {
  max-width: 552px;
}
.header-media.headerwithinset .header-media__text {
  max-width: 100%;
}
.header-media.headerwithinset .header-media__text__inner {
  max-width: calc(50% - 12px);
}
@media (max-width: 750px) {
  .header-media.headerwithinset .header-media__text {
    flex-flow: column;
  }
  .header-media.headerwithinset .header-media__text .header-media__text__inner {
    flex: 1;
    max-width: 100%;
  }
}
.header-media__description > *:last-child {
  margin-bottom: 0;
}
.header-media h1 {
  font-size: clamp(32px, 5.6dvw, 56px);
  color: inherit;
}
.header-media__buttons {
  margin-top: 32px;
  transition: opacity 1000ms;
  opacity: 1;
}
.header-media__buttons--hidden {
  opacity: 0;
}
.header-media__videobutton {
  pointer-events: none;
}
.header-media__buttons:not(.header-media__buttons--hidden) .header-media__videobutton {
  pointer-events: initial;
}

.header-ctabar {
  background-color: transparent;
  z-index: 1;
  flex: none;
  width: 368px;
  margin-right: 32px;
  align-self: center;
  padding-bottom: clamp(16px, 1.6dvw, 24px);
}
.header-ctabar__inner {
  display: flex;
  justify-content: center;
  flex-flow: column;
}
.header-ctabar__link {
  background-color: var(--color-green);
  color: var(--color-black);
  display: flex;
  padding: clamp(16px, 1.6dvw, 24px);
  margin-top: 16px;
  align-items: center;
}
@media (max-width: 650px) {
  .header-ctabar__link--hidesmallscreen {
    display: none;
  }
}
.header-ctabar__link__title {
  font-family: var(--font-family-heading);
  hyphens: auto;
  font-weight: 900;
  font-size: 24px;
  line-height: 115%;
  font-size: clamp(14px, 2.4dvw, 24px);
}
.header-ctabar__link__description {
  font-size: 16px;
  margin-top: 5px;
}
.header-ctabar__link__text {
  flex: 1 1 auto;
  padding-right: clamp(10px, 1.6dvw, 16px);
}
.header-ctabar__link__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: clamp(26px, 4.4dvw, 64px);
  height: clamp(26px, 4.4dvw, 64px);
  align-self: center;
  flex: none;
  transition: background-color 300ms, color 300ms;
}
.header-ctabar__link__icon svg {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  fill: var(--color-black);
}
.header-ctabar__link--blue {
  background-color: var(--color-blue-dark);
  color: #fff;
}
.header-ctabar__link--blue svg {
  fill: #fff;
}
@media (max-width: 900px) {
  .header-ctabar__link__description {
    display: none;
  }
}
@media (max-width: 1150px) {
  .header-ctabar {
    display: none;
  }
}

.header-mobilectabar {
  position: relative;
}
.header-mobilectabar[hidden] {
  display: none;
}
.header-mobilectabar__inner {
  display: flex;
  padding: 20px clamp(24px, 5dvw, 42px);
  align-items: stretch;
  justify-content: center;
  margin-left: -16px;
}
.header-mobilectabar .header-ctabar__link {
  flex: none;
  margin-top: 0;
  width: calc(50% - 16px);
  max-width: 284px;
}
@media (min-width: 651px) {
  .header-mobilectabar div[data-count="3"] > .header-ctabar__link {
    width: calc(33.333% - 8px);
  }
}
.header-mobilectabar .header-ctabar__link {
  margin-left: 16px;
}
@media (max-width: 1150px) {
  .header-mobilectabar[hidden] {
    display: block;
  }
}

.header-pagenav + .header-titlebar {
  margin-top: clamp(4px, 6dvw, 60px);
}

.header-titlebar h1 {
  color: #fff;
  margin-bottom: 0 !important;
}
.header-titlebar__tags {
  margin: 5px 0 10px;
  margin-left: -10px;
  font-size: clamp(8px, 1vw, 10px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.header-titlebar__tags span {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
  color: var(--color-blue);
  font-weight: 600;
  border-radius: 32px;
  padding: 1px 15px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #F3F6FE;
}
.header-titlebar__inner {
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-titlebar__title {
  flex: 1 1 auto;
}
.header-titlebar__filters {
  flex: none;
  color: var(--color-black);
}
.header-titlebar .contentlistfilters {
  display: flex;
}
.header-titlebar .contentlistfilters .wh-form__pulldown-styled > select {
  border-radius: 27px;
  min-height: 54px;
  padding: 11px 45px 11px 32px !important;
}
.header-titlebar .contentlistfilters .arrow {
  right: 15px;
}
.header-titlebar .contentlistfilters label {
  display: none;
}
.header-titlebar .contentlistfilters > * {
  margin-left: 24px;
  max-width: 264px;
}
@media (max-width: 1024px) {
  .header-titlebar__inner {
    display: block;
  }
  .header-titlebar__filters {
    margin-top: 24px;
    margin-left: -24px;
  }
  .header-titlebar .contentlistfilters {
    display: flex;
  }
  .header-titlebar .contentlistfilters > * {
    max-width: 33.3333%;
  }
}
@media (max-width: 650px) {
  .header-titlebar .contentlistfilters {
    display: block;
  }
  .header-titlebar .contentlistfilters > * {
    max-width: 100%;
    margin-top: 10px;
  }
}

.header-pagenav + .header-titlebar {
  background-color: transparent !important;
}
.header-pagenav + .header-titlebar h1 {
  color: var(--color-blue);
}
.header-pagenav + .header-titlebar .header-titlebar__inner {
  padding-bottom: 0;
  max-width: 1000px;
}