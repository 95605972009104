/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.carousel__container {
  transform: translateZ(0);
  position: relative;
  max-width: 100%;
  overflow: hidden;
  overflow-x: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.carousel__container.dragging {
  scroll-behavior: auto;
  cursor: grab;
}
.carousel__container.dragging .carousel__item {
  cursor: grab;
}
.carousel__container::-webkit-scrollbar {
  display: none;
}
.carousel__navigation {
  display: flex;
  align-items: center;
  color: #fff;
}
.carousel__navigation button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  display: inline-block;
  background-color: var(--color-blue);
  font-size: inherit;
}
.carousel__previous, .carousel__next {
  flex: none;
  width: 48px;
  height: 48px;
  padding: 0;
  margin-left: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue);
  color: #fff;
  transition: color 300ms, background-color 300ms;
}
.carousel__container.scrollx-at-start ~ .widget-programmes__footer .carousel__previous, .carousel__container.scrollx-at-end ~ .widget-programmes__footer .carousel__next {
  background-color: var(--color-blue-lighter-dark);
  cursor: default;
}
.carousel__items {
  display: inline-flex;
  justify-content: flex-start;
  -webkit-user-select: none;
  user-select: none;
}
.carousel__item {
  margin-right: 24px;
}
.carousel__item:last-child, .carousel__item.lastitem {
  margin-right: 0;
}

.widget-programmes {
  position: relative;
  z-index: 1;
  /* List */
  /* Filters */
  /* Carrousel: */
}
.widget-programmes__inner {
  background-color: var(--color-blue-lighter);
  padding-top: clamp(35px, 6dvw, 60px);
  padding-bottom: clamp(35px, 6dvw, 60px);
}
.widget-programmes__feedback {
  font-weight: 600;
  line-height: 135%;
  font-size: clamp(16px, 3dvw, 24px);
}
.widget-programmes__feedback__content {
  text-align: center;
  max-width: 460px;
}
.widget-programmes__feedback__content .button {
  margin-top: 25px;
}
.widget-programmes__feedback--list .widget-programmes__feedback__inner {
  padding: 32px;
  min-height: 211px;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
}
@media (max-width: 750px) {
  .widget-programmes__feedback--list .widget-programmes__feedback__inner {
    min-height: 90px;
  }
}
.widget-programmes__feedback--carrousel {
  margin-top: -48px;
  margin-bottom: 30px;
  font-weight: 600;
  height: 480px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
}
@media (max-width: 375px) {
  .widget-programmes__feedback--carrousel {
    height: 109vw;
  }
}
@media (max-width: 364px) {
  .widget-programmes__feedback--carrousel {
    height: 106vw;
  }
}
.widget-programmes__feedback--carrousel::before {
  position: absolute;
  z-index: -1;
  display: block;
  content: "";
  left: calc(50% - 565px);
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent url(./programmeplaceholder.png) repeat-x;
  background-size: contain;
}
@media (max-width: 1176px) {
  .widget-programmes__feedback--carrousel::before {
    left: 32px;
  }
}
.widget-programmes__feedback[hidden] {
  display: none;
}
.widget-programmes__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-programmes__list .widget-programmes__programme {
  margin-bottom: 15px;
}
.widget-programmes__list .widget-programmes__programme__link {
  color: inherit;
  text-decoration: none;
  display: flex;
  background-color: #fff;
}
.widget-programmes__list .widget-programmes__programme__image {
  width: clamp(70px, 19.4dvw, 194px);
  flex: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.widget-programmes__list .widget-programmes__programme__text {
  flex: 1 1 auto;
  padding: 20px 0 20px clamp(20px, 7dvw, 70px);
}
.widget-programmes__list .widget-programmes__programme__title {
  font-weight: 600;
  font-size: clamp(16px, 2.4dvw, 24px);
}
.widget-programmes__list .widget-programmes__programme__icon {
  flex: none;
  width: 70px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--color-blue);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.widget-programmes__list .widget-programmes__programme__tags {
  margin: 5px 0 10px;
  margin-left: -10px;
  font-size: clamp(8px, 1dvw, 10px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.widget-programmes__list .widget-programmes__programme__tags span {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
  color: #000;
  background-color: #FFF;
  font-weight: 600;
  border-radius: 32px;
  padding: 1px 15px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 700px) {
  .widget-programmes__list .widget-programmes__programme__description, .widget-programmes__list .widget-programmes__programme__icon {
    display: none;
  }
  .widget-programmes__list .widget-programmes__programme__text {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
  }
  .widget-programmes__list .widget-programmes__programme__tags {
    margin-bottom: 0;
    margin-top: 5px;
  }
  .widget-programmes__list .widget-programmes__programme__tags span {
    margin-top: 5px;
  }
}
.widget-programmes__text__inner {
  max-width: 840px;
  margin-bottom: clamp(4px, 6dvw, 60px);
}
.widget-programmes__text__inner > *:last-child {
  margin-bottom: 0;
}
.widget-programmes__filters {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1;
  position: relative;
}
.widget-programmes__filter {
  margin-bottom: clamp(25px, 3.2dvw, 32px);
  margin-right: 25px;
  flex: none;
  width: calc(33.3333% - 17px);
  position: relative;
}
.widget-programmes__filter__summary {
  height: clamp(50px, 6.4dvw, 64px);
  border-radius: clamp(25px, 3.2dvw, 32px);
  border: 1px solid var(--color-grey-lines);
  font-size: 12px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  padding: 0 30px;
  background-color: #fff;
  position: relative;
}
.widget-programmes__filter__label, .widget-programmes__filter__selectedvalue {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.widget-programmes__filter__label {
  color: var(--color-blue);
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  top: 0;
  left: 26px;
  transform: translateY(-50%);
  background-color: #fff;
  padding: 0 4px;
  z-index: 0;
}
.widget-programmes__filter__selectedvalue {
  font-size: 18px;
  color: #000;
}
.widget-programmes__filter__selectedvalue--active {
  color: var(--color-blue);
}
.widget-programmes__filter__arrow {
  position: absolute;
  right: 25px;
  top: 50%;
  color: var(--color-blue);
  font-size: 18px;
  transform-origin: center;
  transform: translateY(-50%);
}
.widget-programmes__filter.wh-details--open .widget-programmes__filter__arrow {
  transform: translateY(-50%) scale(1, -1);
}
.widget-programmes__filter__foldout {
  margin-top: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.24);
  border-radius: 32px;
}
.widget-programmes__filter__foldout > div {
  background-color: #fff;
  padding: 20px 32px;
  border-radius: 32px;
}
.widget-programmes__filter__button {
  background-color: #fff;
  display: flex;
  font-size: inherit;
  cursor: pointer;
  padding: 8px 0;
  align-items: center;
}
.widget-programmes__filter__button input {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.widget-programmes__filter__button__arrow {
  color: var(--color-blue);
  flex: none;
  font-size: 20px;
  margin-right: 15px;
}
.widget-programmes__filter__button:hover .widget-programmes__filter__button__arrow, input:checked ~ .widget-programmes__filter__button__arrow {
  color: var(--color-blue-dark);
}
.widget-programmes__filter__button:hover .widget-programmes__filter__button__title, input:checked ~ .widget-programmes__filter__button__title {
  text-shadow: 0 0 1px #000;
}
.widget-programmes--list .widget-programmes__programme__tags > span {
  background-color: var(--color-blue-lighter-dark);
}
.widget-programmes--list .widget-programmes__filter__label::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--color-blue-lighter);
  height: 50%;
  bottom: 50%;
  z-index: -1;
}
@media (max-width: 750px) {
  .widget-programmes__filters {
    display: block;
  }
  .widget-programmes__filter {
    width: 100%;
    margin-bottom: 5px;
  }
  .widget-programmes__filter:last-child {
    margin-bottom: 30px;
  }
  .widget-programmes__filter__summary {
    margin-top: 20px;
  }
  .widget-programmes__filter__foldout {
    margin-top: 0;
    position: relative;
    top: auto;
    box-shadow: none;
  }
  .widget-programmes__filter__foldout > div {
    padding: 10px 32px 0;
    margin-bottom: 10px;
  }
}
.widget-programmes .carousel__container {
  margin-bottom: var(--spacing-md);
}
.widget-programmes .carousel__items {
  padding-left: calc(50% - 556px);
  padding-right: 32px;
}
@media (max-width: 1176px) {
  .widget-programmes .carousel__items {
    padding-left: 32px;
  }
}
.carousel__items .widget-programmes__programme {
  position: relative;
  text-decoration: none;
  z-index: 0;
  color: #fff;
  padding: 24px;
  flex: none;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  width: 360px;
  min-height: 480px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  overflow: hidden;
  margin-bottom: 0;
}
.carousel__items .widget-programmes__programme__title {
  line-height: 135%;
  font-weight: 700;
  font-size: clamp(20px, 2.4dvw, 24px);
}
.carousel__items .widget-programmes__programme__tags {
  margin-left: -10px;
  font-size: clamp(8px, 1dvw, 10px);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  min-height: 80px;
}
.carousel__items .widget-programmes__programme__tags span {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
  color: #000;
  background-color: #FFF;
  font-weight: 600;
  border-radius: 32px;
  padding: 1px 15px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.carousel__items .widget-programmes__programme[hidden] {
  display: none;
}
@media (max-width: 514px) {
  .carousel__items .widget-programmes__programme {
    min-height: 93.3333vw;
  }
}
@media (max-width: 450px) {
  .carousel__items .widget-programmes__programme {
    width: 80vw;
  }
}
@media (max-width: 375px) {
  .carousel__items .widget-programmes__programme {
    width: 300px;
  }
}
@media (max-width: 364px) {
  .carousel__items .widget-programmes__programme {
    width: calc(100vw - 64px);
  }
}
.carousel__items .widget-programmes__programme::before {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: linear-gradient(360deg, #000000 15.01%, rgba(0, 0, 0, 0) 50.01%);
  opacity: 0.3;
}
.carousel__items .widget-programmes__programme--alllink {
  background-color: var(--color-blue);
  color: var(--color-green);
  display: flex;
  align-items: center;
  text-align: center;
}
.carousel__items .widget-programmes__programme--alllink::before {
  display: none;
}
.widget-programmes__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 500px) {
  .widget-programmes__footer {
    flex-flow: column;
  }
  .widget-programmes__footer__allprogrammes {
    order: 10;
    margin-top: var(--spacing-md);
  }
}
.widget-programmes__feedback:not([hidden]) + .widget-programmes__footer {
  margin-top: 30px;
}