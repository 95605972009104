/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.wh-video {
  position: relative;
  z-index: 0;
}
.wh-video__title {
  color: #fff;
  line-height: 150%;
  position: absolute;
  font-family: var(--font-family-heading);
  font-weight: 900;
  font-size: clamp(24px, 2.8dvw, 28px);
  left: 0;
  bottom: 0;
  width: 100%;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
  padding: 15px 24px;
}

.wh-video__playbutton {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 65px;
  height: 65px;
  border: 0 none;
  background-color: var(--color-blue-dark);
  border-radius: 50%;
  z-index: 1;
}
.wh-video__playbutton::after {
  content: "";
  margin-left: 3px;
  color: #fff;
  display: inline-block;
  border-top: solid 12px transparent;
  border-bottom: solid 12px transparent;
  border-left: 16px solid var(--color-cyan);
}
@media (max-width: 580px) {
  .wh-video__playbutton {
    width: 11.2vw;
    height: 11.2vw;
  }
  .wh-video__playbutton::after {
    border-top-width: 2.1vw;
    border-bottom-width: 2.1vw;
    border-left-width: 2.76vw;
  }
}
@media (max-width: 375px) {
  .wh-video__playbutton {
    width: 42px;
    height: 42px;
  }
  .wh-video__playbutton::after {
    border-top-width: 8px;
    border-bottom-width: 8px;
    border-left-width: 10px;
  }
}