/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-twocolctablocks__container {
  display: flex;
  margin-left: -24px;
  flex-wrap: wrap;
}
.widget-twocolctablocks__cta {
  flex: 1 1 50%;
  max-width: 50%;
  padding-left: 24px;
  padding-bottom: 24px;
}
.widget-twocolctablocks__cta__icon {
  flex: none;
  width: 80px;
}
.widget-twocolctablocks__cta__icon > svg {
  max-width: 100%;
  height: auto;
}
.widget-twocolctablocks__cta__text {
  padding-left: 24px;
}
.widget-twocolctablocks__link {
  color: #fff;
  display: block;
  padding: 32px;
  text-decoration: none;
  background-color: var(--color-blue);
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 750px) {
  .widget-twocolctablocks__container {
    display: block;
  }
  .widget-twocolctablocks__cta {
    max-width: 100%;
  }
}
@media (max-width: 471px) {
  .widget-twocolctablocks__cta__icon {
    max-width: 17vw;
  }
}
@media (max-width: 744px) {
  .widget-twocolctablocks__link {
    padding-left: 4.3vw;
    padding-right: 4.3vw;
  }
}
@media (max-width: 500px) {
  .widget-twocolctablocks__link {
    padding: 6.4vw 4.3vw;
  }
}
@media (max-width: 375px) {
  .widget-twocolctablocks__cta__icon {
    max-width: 64px;
  }
  .widget-twocolctablocks__link {
    padding: 24px 16px;
  }
}