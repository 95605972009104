/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-videos {
  color: var(--color-blue);
}
.widget-videos h2 {
  color: inherit;
}
.widget-videos__inner {
  padding-top: var(--spacing-xl);
  padding-bottom: var(--spacing-xl);
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.widget-videos__tiocircle {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  width: 502px;
  max-width: 60%;
  height: auto;
}
.bg-green-lighter > .widget-videos__tiocircle path {
  fill: var(--color-green-lighter-dark);
}
.widget-videos__container {
  display: flex;
}
h2 + .widget-videos__container {
  margin-top: 24px;
}
.widget-videos__activevideo {
  flex: 1 1 auto;
  background-color: var(--color-black);
  position: relative;
}
.widget-videos__activevideo::before {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.widget-videos__activevideo__wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.widget-videos__playlist {
  flex: none;
  width: 232px;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
}
.widget-videos__playlist__scrollcontainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (min-width: 751px) {
  .widget-videos__playlist__scrollcontainer {
    -webkit-mask-image: linear-gradient(to bottom, transparent 0%, transparent 7%, #000 15%, #000 85%, transparent 93%, transparent 100%);
    mask-image: linear-gradient(to bottom, transparent 0%, transparent 7%, #000 15%, #000 85%, transparent 93%, transparent 100%);
  }
  .widget-videos__playlist__scrollcontainer.scrolly-at-start {
    -webkit-mask-image: linear-gradient(to bottom, #000 0%, #000 85%, transparent 93%, transparent 100%);
    mask-image: linear-gradient(to bottom, #000 0%, #000 85%, transparent 93%, transparent 100%);
  }
  .widget-videos__playlist__scrollcontainer.scrolly-at-end {
    -webkit-mask-image: linear-gradient(to bottom, transparent 0%, transparent 7%, #000 15%, #000 100%);
    mask-image: linear-gradient(to bottom, transparent 0%, transparent 7%, #000 15%, #000 100%);
  }
  .widget-videos__playlist__scrollcontainer.scrolly-at-start.scrolly-at-end {
    -webkit-mask-image: none;
    mask-image: none;
  }
}
@media (max-width: 750px) {
  .widget-videos__playlist__scrollcontainer {
    -webkit-mask-image: linear-gradient(to right, transparent 0%, transparent 5%, #000 10%, #000 90%, transparent 95%, transparent 100%);
    mask-image: linear-gradient(to right, transparent 0%, transparent 5%, #000 10%, #000 90%, transparent 95%, transparent 100%);
  }
  .widget-videos__playlist__scrollcontainer.scrollx-at-start {
    -webkit-mask-image: linear-gradient(to right, #000 0%, #000 90%, transparent 95%, transparent 100%);
    mask-image: linear-gradient(to right, #000 0%, #000 90%, transparent 95%, transparent 100%);
  }
  .widget-videos__playlist__scrollcontainer.scrollx-at-end {
    -webkit-mask-image: linear-gradient(to right, transparent 0%, transparent 5%, #000 10%, #000 100%);
    mask-image: linear-gradient(to right, transparent 0%, transparent 5%, #000 10%, #000 100%);
  }
  .widget-videos__playlist__scrollcontainer.scrollx-at-start.scrollx-at-end {
    -webkit-mask-image: none;
    mask-image: none;
  }
}
.widget-videos__playlist__scrollcontainer.dragging {
  scroll-behavior: auto;
  cursor: grab;
}
.widget-videos__playlist__scrollcontainer.dragging .widget-videos__playlist__item {
  cursor: grab;
}
.widget-videos__playlist__scrollcontainer::-webkit-scrollbar {
  display: none;
}
.widget-videos__playlist__previous, .widget-videos__playlist__next {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  cursor: pointer;
}
.widget-videos__playlist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-videos__playlist__item {
  padding-left: 24px;
  cursor: pointer;
  transition: background-color 300ms;
  outline: 0 none;
}
.widget-videos__playlist__item + li {
  margin-top: 24px;
}
@media (max-width: 750px) {
  .widget-videos__playlist__item {
    padding-top: 24px;
    padding-left: 0;
  }
  .widget-videos__playlist__item + li {
    margin-top: 0;
    margin-left: 24px;
  }
}
.widget-videos__playlist__item__image {
  width: 208px;
  height: auto;
  vertical-align: bottom;
}
.widget-videos__playlist__item__noimage {
  background-color: var(--color-black);
  padding-top: 56.25%;
}
.widget-videos__playlist__item__title {
  margin-top: 10px;
  max-height: 45px;
  line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.widget-videos__playlist__item:hover .widget-videos__playlist__item__title, .widget-videos__playlist__item:focus .widget-videos__playlist__item__title, .widget-videos__playlist__item--active .widget-videos__playlist__item__title {
  color: #000;
}
@media (min-width: 751px) {
  .widget-videos__playlist__previous {
    top: 0;
  }
  .widget-videos__playlist__next {
    bottom: 0;
  }
  .widget-videos__playlist__scrollcontainer.scrolly-at-start ~ .widget-videos__playlist__previous, .widget-videos__playlist__scrollcontainer.scrolly-at-end ~ .widget-videos__playlist__next {
    display: none;
  }
}
@media (max-width: 750px) {
  .widget-videos__container {
    flex-flow: column;
  }
  .widget-videos__playlist {
    height: 220px;
    width: auto;
  }
  .widget-videos__playlist__scrollcontainer {
    overflow-y: hidden;
    overflow-x: auto;
  }
  .widget-videos__playlist ul {
    display: inline-flex;
  }
  .widget-videos__playlist__next, .widget-videos__playlist__previous {
    top: 0;
    width: 32px;
    height: 100%;
  }
  .widget-videos__playlist__next i, .widget-videos__playlist__previous i {
    transform: rotate(-90deg);
  }
  .widget-videos__playlist__previous {
    right: auto;
  }
  .widget-videos__playlist__next {
    left: auto;
  }
  .widget-videos__playlist__scrollcontainer.scrollx-at-start ~ .widget-videos__playlist__previous, .widget-videos__playlist__scrollcontainer.scrollx-at-end ~ .widget-videos__playlist__next {
    display: none;
  }
}