/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-photoalbum__container {
  position: relative;
  z-index: 0;
}
.widget-photoalbum__container::after {
  content: "";
  display: block;
  padding-top: 50%;
}
.widget-photoalbum__pluscount {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  font-size: 48px;
  color: #fff;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: clamp(24px, 4.8dvw, 48px);
}
.widget-photoalbum__preview {
  position: absolute;
  cursor: pointer;
  overflow: hidden;
}
.widget-photoalbum__preview img {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.widget-photoalbum__preview[data-seqnr="0"] {
  top: 0;
  left: 0;
  width: 65%;
  height: 100%;
}
.widget-photoalbum__preview[data-seqnr="1"] {
  top: 0;
  right: 0;
  width: 35%;
  height: 65%;
  border-left: 5px solid #fff;
  border-bottom: 5px solid #fff;
}
.widget-photoalbum__preview[data-seqnr="2"] {
  bottom: 0;
  right: 17.5%;
  width: 17.5%;
  height: 35%;
  z-index: 1;
  border-left: 5px solid #fff;
}
.widget-photoalbum__preview[data-seqnr="3"] {
  bottom: 0;
  right: 0;
  width: 17.5%;
  height: 35%;
  border-left: 5px solid #fff;
}
.widget-photoalbum__container[data-count="1"] .widget-photoalbum__preview[data-seqnr="0"] {
  width: 100%;
  height: 100%;
}
.widget-photoalbum__container[data-count="2"] .widget-photoalbum__preview[data-seqnr="1"] {
  top: auto;
  bottom: 0;
  left: 65%;
  width: 35%;
  height: 100%;
  border-left: 5px solid #fff;
  border-bottom: 0 none;
}
.widget-photoalbum__container[data-count="3"] .widget-photoalbum__preview[data-seqnr="2"] {
  width: 35%;
}
@media (max-width: 750px) {
  .widget-photoalbum__container::after {
    padding-top: 111%;
  }
  .widget-photoalbum__preview[data-seqnr="0"] {
    top: 0;
    left: 0;
    width: 100%;
    height: 70%;
    border-bottom: 5px solid #fff;
  }
  .widget-photoalbum__preview[data-seqnr="1"] {
    top: auto;
    bottom: 0;
    left: 0;
    right: auto;
    width: 33.333%;
    height: 30%;
    border: 0 none;
  }
  .widget-photoalbum__preview[data-seqnr="2"] {
    left: 33.333%;
    width: 33.333%;
    height: 30%;
  }
  .widget-photoalbum__preview[data-seqnr="3"] {
    width: 33.333%;
    height: 30%;
  }
  .widget-photoalbum__container[data-count="3"] .widget-photoalbum__preview[data-seqnr="1"] {
    width: 50%;
  }
  .widget-photoalbum__container[data-count="3"] .widget-photoalbum__preview[data-seqnr="2"] {
    width: 50%;
    left: auto;
    right: 0;
  }
  .widget-photoalbum__container[data-count="2"]::after {
    padding-top: 50%;
  }
  .widget-photoalbum__container[data-count="2"] .widget-photoalbum__preview[data-seqnr="0"] {
    width: 50%;
    height: 100%;
  }
  .widget-photoalbum__container[data-count="2"] .widget-photoalbum__preview[data-seqnr="1"] {
    left: auto;
    right: 0;
    width: 50%;
  }
  .widget-photoalbum__container[data-count="1"] .widget-photoalbum__preview[data-seqnr="0"] {
    height: 100%;
    border: 0 none;
  }
}