/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-countdown__holder {
  height: 74px;
  color: #fff;
  background-color: var(--color-blue);
  border-radius: 37px;
  padding: 15px 30px;
  display: inline-block;
  white-space: nowrap;
}
.widget-countdown__item {
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  padding: 0 7px;
  vertical-align: top;
}
.widget-countdown__item__count, .widget-countdown__item__label {
  display: block;
  width: 60px;
  white-space: nowrap;
}
.widget-countdown__item__count {
  font-size: 24px;
  line-height: 120%;
  font-weight: 600;
}
.widget-countdown__item__label {
  font-size: 12px;
  line-height: 200%;
}
@media (max-width: 500px) {
  .widget-countdown__holder {
    padding: 15px 20px;
  }
  .widget-countdown__item {
    padding: 0 4px;
  }
  .widget-countdown__item__label {
    font-size: 10px;
  }
  .widget-countdown__item__count, .widget-countdown__item__label {
    width: 52px;
  }
}