/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-quote__inner {
  border-left: 4px solid var(--color-blue);
  padding-left: 40px;
  color: var(--color-blue);
}
@media (max-width: 750px) {
  .widget-quote__inner {
    padding-left: 5.33vw;
  }
}
@media (max-width: 375px) {
  .widget-quote__inner {
    padding-left: 20px;
  }
}
.widget-quote__name {
  margin-top: 32px;
}