/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-testimonial {
  max-width: 100%;
}
html.wh-widgetpreview .widget-testimonial {
  display: inline-block;
  padding: 32px;
  background-color: var(--color-green-lighter);
  max-width: 760px;
}
.widget-testimonial__wrapper {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}
.widget-testimonial__image {
  flex: none;
  width: 175px;
  height: auto;
  align-self: flex-start;
  margin-right: var(--spacing-md);
}
.widget-testimonial__image > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  border-radius: 50%;
}
.widget-testimonial__startquote, .widget-testimonial__endquote {
  width: 64px;
  height: auto;
  flex: none;
}
.widget-testimonial__rating {
  display: flex;
  margin-top: 5px;
}
.widget-testimonial__rating svg {
  width: 26px;
  height: 26px;
}
.widget-testimonial__description {
  font-size: clamp(20px, 2.4dvw, 24px);
  line-height: 140%;
  font-weight: 600;
  margin: 16px 0;
}
.widget-testimonial__footer {
  display: flex;
}
.widget-testimonial__title {
  flex: 1;
  position: relative;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  margin-right: 24px;
}
@media (max-width: 600px) {
  .widget-testimonial__wrapper {
    flex-flow: column;
    align-items: center;
  }
  .widget-testimonial__text {
    padding-left: 0;
  }
  .widget-testimonial__image {
    margin: 0 auto 24px;
    width: 120px !important;
  }
}