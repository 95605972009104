/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-testimonials {
  max-width: 100%;
  overflow: hidden;
}
.widget-testimonials.fullwidth, .widget-testimonials__inner {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: var(--color-green-lighter);
}
.widget-testimonials__inner {
  padding: var(--spacing-xl) 0;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.widget-testimonials__tiocircle {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 448px;
  max-width: 75%;
  z-index: -1;
}
.widget-testimonials h2 {
  margin-bottom: var(--spacing-md);
}
.widget-testimonials__slides {
  display: grid;
  column-gap: 0;
  grid-row-gap: 0;
  grid-template-columns: [buttonprev-start] 32px [buttonprev-end] var(--spacing-md) [slides-start] 1fr [slides-end] var(--spacing-md) [buttonnext-start] 32px [buttonnext-end];
  align-items: center;
}
.widget-testimonials__slides__previous, .widget-testimonials__slides__next {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  width: 32px;
  font-size: 32px;
  color: var(--color-blue);
}
.widget-testimonials__slides__jmpbuttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-md);
  grid-area: auto/buttonprev-start/auto/buttonnext-end;
}
.widget-testimonials__slides__jmpbuttons__button {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 0 none;
  padding: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: block;
  flex: none;
  margin: 4px;
  width: 16px;
  height: 16px;
  transition: border 300ms;
}
.widget-testimonials__slides__jmpbuttons__button.active, .widget-testimonials__slides__jmpbuttons__button:hover {
  border: 5px solid var(--color-blue);
}
.widget-testimonials__slides__previous {
  grid-area: auto/buttonprev-start/auto/buttonprev-end;
}
.widget-testimonials__slides__next {
  grid-area: auto/buttonnext-start/auto/buttonnext-end;
}
@media (max-width: 500px) {
  .widget-testimonials__slides {
    display: block;
  }
  .widget-testimonials__slides__previous, .widget-testimonials__slides__next {
    display: none;
  }
}
.widget-testimonials__slides__itemlist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  grid-area: auto/slides-start/auto/slides-end;
  display: flex;
  position: relative;
  z-index: 0;
  overflow: hidden;
}
.widget-testimonials__slides__item {
  flex: none;
  position: relative;
  z-index: 1;
  opacity: 1;
  transition: opacity 400ms;
  width: 100%;
}
.widget-testimonials__slides__item[hidden] {
  display: block;
  z-index: 0;
  opacity: 0;
}
.widget-testimonials__slides__item + .widget-testimonials__slides__item {
  margin-left: -100%;
}
@media (max-width: 1176px) {
  .widget-testimonials .widget-testimonial__image {
    width: 14.7vw;
  }
}
@media (max-width: 950px) {
  .widget-testimonials .widget-testimonial__image {
    width: 18.4vw;
  }
}