/*

Redesign 2024

H1 56px - 40px
H2 40px - 32px;
H3 28px - 24px;
H4 18px - 16px;

.intro 24px - 20px;
p 18px - 16px;
p.small 16px - 14px;

*/
.widget-imageslideshow__container {
  position: relative;
  z-index: 0;
}
.widget-imageslideshow__container::after {
  content: "";
  display: block;
  padding-top: 56.25%;
}
.widget-imageslideshow__image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1.5s;
}
.widget-imageslideshow__image--active {
  z-index: 1;
  opacity: 1;
}
.widget-imageslideshow__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}